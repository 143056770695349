import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import CheckMark from '../../../../assets/img/checkmark.svg';

const Confirm = ({ className, message, close, open }) => {
  return (
    <Modal className={className} footer={false} visible={open} onCancel={close}>
      <div className="content">
        <img src={CheckMark} alt="Check mark icon" />
        <h3>{message}</h3>
        <div className="buttons">
          <button size="small" appearance="blue" onClick={close}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default styled(Confirm)`
  width: 650px !important;
  height: 400px !important;
  .ant-modal-content {
    height: 400px !important;

    padding: 27px 40px !important;
  }
  .content {
    margin: auto;
    text-align: center;
  }

  img {
    width: 150px;
  }
  h3 {
    font-family: var(--font-family);
    font-size: 18px;
    color: #2e384d;
    margin-top: 33px;
    text-transform: capitalize;
  }
  .buttons {
    text-align: center;
    margin-top: 3rem;
  }
  button {
    height: 40px;
    padding: 0 1.5rem;
    background: #0c5bab;
    color: #fff;
    margin-top: ;
    border: none;
  }
`;
