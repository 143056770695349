import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoutes from './pages/auth/routes';
import ProtectedRoute from './protectedRoute';
import SignInPage from './pages/auth/SignIn';
import MainPage from './pages/index';

export const GlobalRoutes = () => (
  <Switch>
    {AuthRoutes}
    <ProtectedRoute key="home" path="/" component={MainPage} />
    <Route key="generic" path="/*" component={SignInPage} />
    <Route key="generic" path="*" component={SignInPage} />
  </Switch>
);
