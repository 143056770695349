import React, { useState, useEffect } from 'react';
import { Form, Checkbox, Row, Col, Switch, message } from 'antd';
import LoadingContent from 'components/common/LoadingContent';
import { getNotifications, disableNotification, enableNotification } from '../../../helpers/api';
import { openNotification } from '../../../helpers/notification';
import './style.css';

const NotificationForm = () => {
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    refreshNotifications();
  }, []);
  useEffect(() => {
    if (reload) {
      refreshNotifications();
    }
  }, [reload]);
  const handleUpdateNotification = (values) => {
    console.log(values);
  };
  //   const onFinish = (values) => {
  //     console.log('Received values of form: ', values);
  //   };

  const refreshNotifications = () => {
    setLoading(true);
    getNotifications()
      .then((res) => {
        setNotifications(res.result.data);
        setReload(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Something went wrong',
          message: error,
        });
      });
  };

  const reloadNotifications = () => {
    setReload(true);
  };

  const handleDisableNotification = (ev, c, name) => {
    disableNotification(name)
      .then((res) => {
        // message.success('Updated');
        console.log('updated');
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
        reloadNotifications();
      });
  };

  const handleEnableNotification = (ev, c, name) => {
    enableNotification(name)
      .then((res) => {
        // message.success('Updated');
        console.log('updated');
        // reloadNotifications();
      })
      .catch((error) => {
        message.error(error);
        reloadNotifications();
      });
  };

  const displayNotification = () => {
    if (notifications && notifications !== null) {
      return notifications.map((notif) => {
        return (
          <div className="notification__container">
            <div className="notification__heading mt-2rem">
              <h3 className="sub-heading bold">{notif.title}</h3>
              <p className="text-gray mb-15 sub-heading">{notif.subTitle}</p>
            </div>
            {notif.content.map((content) => {
              if (content.settingsValue === 'true') {
                return (
                  <>
                    <Row className="mt-15">
                      <Col lg={24}>
                        <Switch
                          size="small"
                          defaultChecked={content.settingsValue === 'true' ? true : false}
                          className="config__switch mr-10"
                          onChange={(checked, e, settingsName) =>
                            handleDisableNotification(e, 'check', content.settingsName)
                          }
                        />
                        {content.settingDesc}
                      </Col>
                    </Row>
                  </>
                );
              } else {
                return (
                  <>
                    <Row className="mt-15">
                      <Col lg={24}>
                        <Switch
                          size="small"
                          className="config__switch mr-10"
                          defaultChecked={content.settingsValue === 'true' ? true : false}
                          onChange={(checked, e, settingsName) =>
                            handleEnableNotification(e, 'check', content.settingsName)
                          }
                        />
                        <span className="switch__text">{content.settingDesc}</span>
                      </Col>
                    </Row>
                  </>
                );
              }
            })}
          </div>
        );
      });
    } else {
      return <LoadingContent pageLoading={true} />;
    }
  };
  return (
    <div className="notification__form mb-2rem">
      {loading ? <LoadingContent pageLoading={true} /> : displayNotification()}
    </div>
  );
};

export default NotificationForm;
