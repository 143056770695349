import React from 'react';
import { Modal } from 'antd';
import EditZoneForm from './EditZoneForm';
import './Zone.css';

const EditZone = ({ close, open, name, zoneId, reloadZone }) => {
  return (
    <Modal
      title="Edit Zone"
      className="edit__zone__modal"
      footer={false}
      visible={open}
      onCancel={close}
    >
      <EditZoneForm zoneName={name} closeModal={close} id={zoneId} reload={reloadZone} />
    </Modal>
  );
};

export default EditZone;
