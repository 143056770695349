import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Tabs, Menu, DatePicker, Divider } from 'antd';
import moment from 'moment';
import GeneralReportIcon from 'assets/img/general_reports.svg';
// import { DownOutlined } from '@ant-design/icons';
// import Select from 'components/common/Select';
// import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import { adminReportMenu } from 'helpers/api';
import { generateReport } from 'helpers/utils';
import './style.css';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const currentDay = moment(new Date()).format(dateFormat);

const endOfYear = moment()
  .endOf('year')
  .format(dateFormat);

const initialDatePicker = [moment(currentDay), moment(endOfYear)];

const menu = (
  <Menu>
    <Menu.Item>
      <a>Filter 1</a>
    </Menu.Item>
    <Menu.Item>
      <a>Filter 2</a>
    </Menu.Item>
    <Menu.Item>
      <a>Filter 3</a>
    </Menu.Item>
  </Menu>
);

const getUnixTime = (time) => {
  let returnTime = [];
  time && time.length && time.forEach((tm) => returnTime.push(new Date(tm).valueOf()));
  return returnTime;
};

const getMomentTime = (unixT) => {
  let returnTime = [];
  unixT &&
    unixT.length &&
    unixT.forEach((tm) => returnTime.push(moment(moment(new Date(tm)).format(dateFormat))));
  return returnTime;
};

const AdminReports = () => {
  const dateRef = useRef(null);
  const [selectedTime, setTabSelectedTime] = useState(getUnixTime(initialDatePicker));
  const tabPanes = [
    {
      tabName: 'Audit Log Report',
      tabClass: 'compliance',
      tabKey: 'audit',
      tabId: 'compliance__report',
      tabReportName: 'AuditTrail.trdp',
      tabParameter: {
        starttime: Math.round(selectedTime[0] / 1000),
        endtime: Math.round(selectedTime[1] / 1000),
      },
    },
    {
      tabName: 'Sample Point Report',
      tabClass: 'compliance',
      tabKey: 'sample',
      tabId: 'compliance__report',
      tabReportName: 'samplepoint.trdp',
      tabParameter: {
        starttime: Math.round(selectedTime[0] / 1000),
        endtime: Math.round(selectedTime[1] / 1000),
      },
    },
    {
      tabName: 'Compliance Report',
      tabClass: 'compliance',
      tabKey: 'compliance',
      tabId: 'compliance__report',
      tabReportName: 'Compliance.trdp',
      tabParameter: {
        starttime: Math.round(selectedTime[0] / 1000),
        endtime: Math.round(selectedTime[1] / 1000),
      },
    },
    {
      tabName: 'Non Compliance Report',
      tabClass: 'compliance',
      tabKey: 'noncompliance',
      tabId: 'compliance__report',
      tabReportName: 'NonCompliance.trdp',
      tabParameter: {
        starttime: Math.round(selectedTime[0] / 1000),
        endtime: Math.round(selectedTime[1] / 1000),
      },
    },
    {
      tabName: 'Emission Stats',
      tabClass: 'compliance',
      tabKey: 'emission',
      tabId: 'compliance__report',
      tabReportName: 'EmissionStats.trdp',
      tabParameter: {
        starttime: Math.round(selectedTime[0] / 1000),
        endtime: Math.round(selectedTime[1] / 1000),
      },
    },
    {
      tabName: 'Density Grid',
      tabClass: 'compliance',
      tabKey: 'density',
      tabId: 'compliance__report',
      tabReportName: 'DensityGrid.trdp',
      tabParameter: {
        starttime: Math.round(selectedTime[0] / 1000),
        endtime: Math.round(selectedTime[1] / 1000),
      },
    },
  ];

  const [tabSelected, setTabSelected] = useState(tabPanes[0]);
  const [generated, setGenerated] = useState({});

  useEffect(() => {
    refreshReportMenu();

    if (generated && generated[tabSelected['tabKey']]) {
      // console.log('generating report for', tabSelected.tabParameter);
      // console.log('but selected time is', selectedTime);
      // console.table(
      //   'converting date time',
      //   new Date(tabSelected.tabParameter.starttime * 1000),
      //   new Date(tabSelected.tabParameter.endtime * 1000),
      //   new Date(Math.round(selectedTime[0])),
      //   new Date(Math.round(selectedTime[1]))
      // );

      const currentSelected = { ...tabSelected };
      const currentTabParameter = { ...tabSelected['tabParameter'] };
      currentTabParameter['starttime'] = Math.round(selectedTime[0] / 1000);
      currentTabParameter['endtime'] = Math.round(selectedTime[1] / 1000);
      currentSelected['tabParameter'] = currentTabParameter;

      // console.log('current selected', currentSelected);
      // console.log('current pam', tabSelected);

      generateReport(
        tabSelected.tabReportName,
        currentSelected.tabParameter,
        `#${tabSelected.tabKey}-${tabSelected.tabClass}__report`
      );
    }

    return () => {};
  }, [tabSelected, generated]);

  const refreshReportMenu = () => {
    adminReportMenu()
      .then((res) => {})
      .catch((error) => {
        // console.log(error);
      });
  };

  const changeReportTab = (selectdPane) => {
    const selTab = tabPanes.filter((tbPane) => tbPane.tabKey === selectdPane);
    if (selTab && selTab[0]) {
      setTabSelected(selTab[0]);
    }
  };

  const chooseTimePeriod = (_, timeP) => {
    setTabSelectedTime(getUnixTime(timeP));
  };

  function genReport() {
    const formerGenerated = { ...generateReport };
    const formerTabParameter = { ...tabSelected['tabParameter'] };
    formerTabParameter['starttime'] = Math.round(selectedTime[0] / 1000);
    formerTabParameter['endtime'] = Math.round(selectedTime[1] / 1000);
    formerGenerated[tabSelected['tabKey']] = formerTabParameter;
    formerGenerated['tabParameter'] = formerTabParameter;

    setGenerated(formerGenerated);
  }

  const isNewGenDate = () => {
    // dateRef.current.props.defaultValue
    let disable = true;
    if (selectedTime && generated && generated[tabSelected['tabKey']]) {
      const selTab = generated[tabSelected['tabKey']];
      const genStartTime = selTab.starttime;
      const genEndTime = selTab.endtime;
      if (
        Math.round(selectedTime[0] / 1000) !== genStartTime ||
        Math.round(selectedTime[1] / 1000) !== genEndTime
      ) {
        disable = false;
      }
    }

    return disable;
  };

  return (
    <div
      className={`admin__reports ${
        !generated || !generated[tabSelected['tabKey']] ? 'generated' : ''
      }`}
    >
      <Tabs tabPosition="left" className="audit__tabs" onChange={changeReportTab}>
        {tabPanes.map((tbPane) => (
          <TabPane
            tab={tbPane.tabName}
            key={tbPane.tabKey}
            className={`${!generated || !generated[tabSelected['tabKey']] ? 'text-center' : ''}`}
          >
            {!generated ||
              (!generated[tabSelected['tabKey']] && (
                <>
                  <img src={GeneralReportIcon} alt="general report icon" />
                  <div className="mt-20">
                    <p className="sub-heading mb-1rem">{`Specify the date range to generate ${
                      tabSelected.tabName
                    }`}</p>
                    <Row type="flex" className="mb-1rem">
                      <Col lg={24} style={{ textAlign: 'center' }}>
                        <RangePicker
                          className={`date__filter`}
                          // defaultValue={initialDatePicker}
                          onChange={chooseTimePeriod}
                          format={dateFormat}
                        />
                      </Col>
                    </Row>
                    <Row type="flex" className="mb-1rem">
                      <Col lg={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" appearance="default" onClick={genReport}>
                          Generate Report
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              ))}
            {generated && generated[tabSelected['tabKey']] && (
              <div className={`${tbPane.tabClass}__container`}>
                <Row justify="space-between">
                  <Col lg={10}>
                    <Row>
                      <Col lg={24}>
                        <RangePicker
                          className={`date__filter`}
                          defaultValue={getMomentTime(selectedTime)}
                          onChange={chooseTimePeriod}
                          format={dateFormat}
                          ref={dateRef}
                        />
                      </Col>
                      <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Divider plain type="vertical" className={`${tbPane.tabClass}__divider`} />
                      </Col>
                      {/* <Col lg={12}>
                      <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                          Filter By: <DownOutlined />
                        </a>
                      </Dropdown>
                    </Col> */}
                    </Row>
                  </Col>
                  <Col lg={8} style={{ textAlign: 'right', marginRight: '35px' }}>
                    <Button
                      type="primary"
                      appearance="default"
                      onClick={genReport}
                      disabled={isNewGenDate()}
                    >
                      Generate Report
                    </Button>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col lg={23} className="pr-2rem">
                    <div className={`${tbPane.tabClass}__report__content`}>
                      <div className="audit__summary mt-2rem">
                        <div className="audit__log__data">
                          <div id={`${tbPane.tabKey}-${tbPane.tabClass}__report`} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default AdminReports;
