import React, { useState, useContext, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import styled from 'styled-components';
import ModalHeader from './ModalHeader';
import Button from 'components/common/Button';
import Select from 'components/common/Select';
import ConfirmUserInvite from './ConfirmUserInvite';
import UserRoles from './UserRoles';
import { UserContext } from 'store/UserContext';
import { withRouter } from 'react-router';
import { inviteUsers } from 'helpers/api';
import { openNotification } from 'helpers/notification';
import { properCase } from 'helpers/utils';
import EmailInput from './EmailInput';
import UserCategory from './UserCategory';
import RegulatorInput from './RegulatorInput';
import Facilities from './Facilities';

const CreateUsersModal = withRouter(({ className, close, open, refetch, closeCreateUserModal }) => {
  const { users, selectedRole, Category, State, Zones, SelectedFacility, LGA } = useContext(
    UserContext
  );
  const [invite, setInvite] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  
  const { Option } = Select;

  const handleInvite = () => {
    setLoading(true);
    if (users.length == 0) {
      return openNotification({
        title: 'Something went wrong!',
        message: 'No email was provided',
        type: 'error',
        duration: 5,
      });
    } else if (Category == '') {
      return openNotification({
        title: 'Something went wrong!',
        message: 'Category was not provided',
        type: 'error',
        duration: 5,
      });
    } else {
      sendUserInvite();
    }
  };

  const sendUserInvite = () => {
    const payload = {
      email: users,
      roleId: selectedRole,
      userCategory: Category,
      facilityId: SelectedFacility,
      zoneId: Zones,
      stateId: State,
      lgaId: LGA,
    };
    inviteUsers(payload)
      .then((res) => {
        if (res.result.status.code === '00') {
          setLoading(false);
          setVisible(true);
        }
      })
      .catch((error) => {
        let errorMsg = 'Error sending invite';
        if (error === undefined) {
          setLoading(false);
          return openNotification({
            title: 'Something went wrong!',
            type: 'error',
            message: `${properCase('An invitation has already been sent to this user')}`,
            duration: 10,
          });
        } else {
          setLoading(false);
          if (
            error.result &&
            error.result.status &&
            error.result.status.desc &&
            typeof error.result.status.desc === 'string'
          ) {
            errorMsg = error.result.status.desc;
            return openNotification({
              title: 'Something went wrong!',
              type: 'error',
              message: `${properCase(errorMsg)}`,
              duration: 10,
            });
          } else {
            return openNotification({
              title: 'Something went wrong!',
              type: 'error',
              message: `${properCase(error)}`,
              duration: 10,
            });
          }
        }
      });
  };

  const closeModal = () => {
    setVisible(false);
    closeCreateUserModal();
    refetch();
    // window.location.reload();
  };

  return (
    <Modal
      className={className}
      visible={open}
      onCancel={close}
      title={<ModalHeader invite={invite} closeModal={close} />}
      footer={null}
    >
      <EmailInput className="mb-20" />
      <UserCategory className="regulator__input" />
      <Col lg={7} />
      {Number(Category) === 1 ? <RegulatorInput /> : Number(Category) === 3 ? <Facilities /> : null}

      {Category === '' ? null : <UserRoles invite={invite} />}

      <ConfirmUserInvite openConfirmationModal={visible} closeConfirmationModal={closeModal} />
      <div className="modal-footer">
        <p>
          {users.length > 0 &&
            `The Invite will be sent to ${users.length} new ${
              users.length > 1 ? 'email addresses' : 'email address'
            }`}
        </p>
        <div>
          <Button type="secondary" onClick={close} disabled={loading}>
            Cancel
          </Button>
          <Button
            appearance="default"
            type="primary"
            className="invite-btn"
            onClick={handleInvite}
            loading={loading}
          >
            Invite
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default styled(CreateUsersModal)`
  width: 950px !important;
  height: auto !important;
  border-radius: 5px;
  margin-bottom: 2rem;
  .ant-modal-content {
    width: inherit !important;
    position: sticky !important;
    height: inherit;
  }
  .ant-modal-header {
    padding: 27px 40px 20px !important;
    border-bottom: none;
  }
  .ant-modal-body {
    background-color: #fff !important;
    max-height: 700px;
    padding: 0 40px !important;
    margin-bottom: 3rem;
  }
  .location-select {
    width: 100%;
  }

  .ant-modal-body,
  .ant-collapse {
    background-color: #fff;
  }
  .modal-footer {
    display: flex;
    padding: 27px 6px;
    margin-top: 1.5rem;
    font-family: var(--font-family);
  }
  .modal-footer > p {
    color: #0c5bab;
    font-size: 14px;
  }
  .modal-footer > div {
    flex: 1;
    text-align: right;
  }
  .invite-btn {
    margin-left: 10px;
  }
  .ant-select-selection-item-remove {
    padding-right: 4px;
    transform: translateY(2px);
  }
  .ant-select-selection-item-content {
    font-family: var(--font-family);
    padding: 3px 5px;
  }
`;
