import React, { useState } from 'react';
import { Form, message } from 'antd';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import { updateSector } from '../../../../../helpers/api';

const EditSectorForm = ({ closeModal, reloadSector, name, id }) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateSector = (values) => {
    setLoading(true);
    const payload = {
      sectorId: id,
      sectorName: values.sectorName.toLowerCase(),
    };
    updateSector(payload)
      .then((res) => {
        message.success(
          `Sector ${name.toUpperCase()} has been updated to ${values.sectorName.toUpperCase()} successfully`
        );
        setLoading(false);
        reloadSector();
        closeModal();
      })
      .catch((error) => {
        setLoading(false);
        message.error(`Something went wrong, ${error}`);
      });
  };
  return (
    <Form
      layout="vertical"
      hideRequiredMark={true}
      initialValues={{ sectorName: name }}
      onFinish={handleUpdateSector}
    >
      <Form.Item
        name="sectorName"
        label="Sector Name"
        rules={[{ required: true, message: 'please enter a sector name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          appearance="default"
          className="mr-10"
          loading={loading}
        >
          Save
        </Button>
        <Button htmlType="button" type="secondary" onClick={closeModal} loading={loading}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditSectorForm;
