import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Menu, Dropdown, message } from 'antd';
import { Link } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { DownOutlined } from '@ant-design/icons/lib/icons';
import { AppContext } from 'store/AppContext';
import { ReactComponent as FacilitiesIcon } from 'assets/img/facilities.svg';
import EmptyContent from 'components/common/EmptyContent';
import Button from 'components/common/Button';
import LoadingContent from 'components/common/LoadingContent';
import PageContent from 'components/common/PageContent';
import {
  SummaryCard,
  LimitViolations,
  LimitViolators,
  PollutionViolations,
  MapComponent,
  ViolationTrend,
} from './components';
import { getDashboardData, getDashboardStat, getUser, getStates, getSectors } from 'helpers/api';
import { properCase, toFixed } from 'helpers/utils';

import '../../../shared.css';
import './index.css';

const initialDataCamp = {
  summaryStat: true,
  limitViolations: true,
  regViolator: true,
  pollutionViolations: true,
  violationTrend: true,
  dashboardMap: true,
};

const initialErrorCamp = {
  summaryStat: false,
  limitViolations: false,
  regViolator: false,
  pollutionViolations: false,
  violationTrend: false,
  dashboardMap: false,
};

export default function Dashboard({ props }) {
  const { state } = useContext(AppContext);
  const { userCategory, userId } = state.auth.result.data;

  const isEDM = userCategory.toLowerCase() === 'edm';
  const isRegulator = userCategory.toLowerCase() === 'regulator';

  const initialState = isEDM ? { stateName: 'all', stateId: '', id: '' } : '';
  const [dataLoading, setDataLoading] = useState(initialDataCamp);
  const [page, setPage] = useState('');
  const [errorLoading, setErrorLoading] = useState(initialErrorCamp);
  const [dashboardStat, setDashboardStat] = useState({});
  const [stateLists, setStateLists] = useState();
  const [currState, setCurrState] = useState(initialState);
  const [limitViolations, setLimitViolations] = useState();
  const [limitViolators, setLimitViolators] = useState();
  const [violationTrend, setViolationTrend] = useState();
  const [dashboardMap, setDashboardMap] = useState();
  const [pollutionViolations, setPollutionViolations] = useState();
  const [loadState, triggerReload] = useState(false);

  const updateLoadingState = (whichLoadingState, newState) => {
    if (whichLoadingState) {
      const currState = { ...dataLoading };
      setDataLoading({ ...currState, [whichLoadingState]: Boolean(newState) });
    }
  };

  const getAllStates = async () => {
    const sortStateBy = 'stateName';
    const theStates = await getStates('/LookUp/State');
    if (theStates && theStates.result) {
      const result = theStates.result;
      let sLists = [];
      result.data &&
        result.data.length > 0 &&
        result.data.forEach((res) =>
          sLists.push({
            stateId: res.stateId,
            name: res.stateName,
            stateName: res.stateName,
            id: res.stateId,
            text: res.stateName,
          })
        );
      sLists = sLists
        .filter(
          (value, index, self) =>
            self.map((x) => x[sortStateBy]).indexOf(value[sortStateBy]) == index
        )
        .sort(
          (a, b) => a[sortStateBy] && b[sortStateBy] && a[sortStateBy].localeCompare(b[sortStateBy])
        );
      return sLists;
    }
  };

  const extractStates = async (forThisZone, which) => {
    let withWhat = 'zoneId';
    if (which) withWhat = which;
    const theStates = await getStates('/LookUp/State');
    if (theStates && theStates.result) {
      const result = theStates.result;
      const sLists = [];
      result.data &&
        result.data.length > 0 &&
        result.data.forEach(
          (res) =>
            res[withWhat] === forThisZone &&
            sLists.push({
              stateId: res.stateId,
              name: res.stateName,
              stateName: res.stateName,
              id: res.stateId,
              text: res.stateName,
            })
        );
      return sLists;
    }
  };

  const getRegulatorAreas = async (id) => {
    let areas = {};
    let states = [];
    let zones = [];
    // use which to sort unique list items
    let sortStateBy = 'stateName';
    let sortZoneBy = 'zoneName';
    const user = await getUser(id);
    if (user && user.result && user.result.data && user.result.data.userAreaPermissions) {
      const userArea = user.result.data.userAreaPermissions;
      areas['zone'] = userArea.zonePermission;
      areas['state'] = userArea.statePermission;
      areas['lga'] = userArea.lgaPermission;
    }

    if (areas['state'] && areas['state'].length > 0) {
      // states = [...areas['state']];
      areas['state'].forEach((st) => {
        states.push({
          stateId: st.stateId,
          id: st.stateId,
          name: st.stateName,
          stateName: st.stateName,
          text: st.stateName,
        });
      });
    }
    if (areas['zone'] && areas['zone'].length > 0) {
      for (const [idx, ar] of areas['zone'].entries()) {
        zones.push({
          zoneId: ar.zoneId,
          id: ar.zoneId,
          name: ar.zoneName,
          zoneName: ar.zoneName,
          text: ar.zoneName,
        });
        const theState = await extractStates(ar.zoneName, 'zoneName');
        states = [...states, ...theState];
      }
    }

    states = states
      .filter(
        (value, index, self) => self.map((x) => x[sortStateBy]).indexOf(value[sortStateBy]) == index
      )
      .sort(
        (a, b) => a[sortStateBy] && b[sortStateBy] && a[sortStateBy].localeCompare(b[sortStateBy])
      );
    zones = zones
      .filter(
        (value, index, self) => self.map((x) => x[sortZoneBy]).indexOf(value[sortZoneBy]) == index
      )
      .sort((a, b) => a[sortZoneBy] && b[sortZoneBy] && a[sortZoneBy].localeCompare(b[sortZoneBy]));
    return { state: states, zone: zones };
  };

  const getDashboardStats = async ({ state, newState, dashboardStat, reload = true }) => {
    let ret = [];
    if (reload) {
      updateLoadingState('summaryStat', true);
      let param = "?StateId=''";
      // get dashboard for current state
      // or for a new state
      // if first time, get from the first item in lists of states
      let currStateStat = currState;
      if (!currState && state && state.length > 0) currStateStat = state[0];
      if (newState) currStateStat = newState;
      // if (newState && newState.stateName && newState.stateName.toLowerCase() === 'all' && isEDM)
      //   currStateStat = '';
      // set state list first time only
      if (!stateLists) {
        if (state) setStateLists(state);
      }
      if ((currState && currState.stateName !== currStateStat.stateName) || !currState)
        setCurrState(currStateStat);

      if (currStateStat) {
        param = `?StateId=${currStateStat.stateId}`;
        // if (currStateStat.stateName === 'all') param = '';
      }
      // if (currStateStat) param = `${currStateStat.stateId}`;

      if (!dashboardStat || (dashboardStat && Object.keys(dashboardStat).length === 0)) {
        const res = await getDashboardStat(param);
        if (res && res.result && res.result.data) {
          const data = res.result.data;
          const newStat = {
            facilityCount: data.facilityCount || data.facilityCount == 0 ? data.facilityCount : '-',
            activeFacility:
              data.activeFacilityPercentage || data.activeFacilityPercentage == 0
                ? `${toFixed(data.activeFacilityPercentage, 2)}%`
                : '-',
            qaqcComplianceToFmenv:
              data.qaqcComplianceToFmenv || data.qaqcComplianceToFmenv == 0
                ? `${toFixed(data.qaqcComplianceToFmenv, 2)}%`
                : '-',
            qaqcComplianceToWorldBank:
              data.qaqcComplianceToWorldBank || data.qaqcComplianceToWorldBank == 0
                ? `${toFixed(data.qaqcComplianceToWorldBank, 2)}%`
                : '-',
            qaqcRatio:
              data.qaqcRatio || data.qaqcRatio == 0 ? `${toFixed(data.qaqcRatio, 2)}%` : '-',
          };
          ret = newStat;
          if (!isEqual(dashboardStat, newStat)) {
            setDashboardStat(newStat);
          }
        }
      }

      updateLoadingState('summaryStat', false);
    } else {
      let param = "?StateId=''";
      if (newState) {
        param = `?StateId=${newState.stateId}`;
      }

      const res = await getDashboardStat(param);

      if (res && res.result && res.result.data) {
        const data = res.result.data;
        const newStat = {
          facilityCount: data.facilityCount || dashboardStat.facilityCount,
          activeFacility: data.activeFacilityPercentage
            ? `${toFixed(data.activeFacilityPercentage, 2)}%`
            : dashboardStat.activeFacilityPercentage,
          facilityCount: data.facilityCount || 0,
          qaqcComplianceToFmenv: data.qaqcComplianceToFmenv
            ? `${toFixed(data.qaqcComplianceToFmenv, 2)}%`
            : dashboardStat.qaqcComplianceToFmenv,
          qaqcComplianceToWorldBank: data.qaqcComplianceToWorldBank
            ? `${toFixed(data.qaqcComplianceToWorldBank, 2)}%`
            : dashboardStat.qaqcComplianceToWorldBank,
          qaqcRatio: data.qaqcRatio ? `${toFixed(data.qaqcRatio, 2)}%` : dashboardStat.qaqcRatio,
        };
        ret = dashboardStat;

        if (!isEqual(dashboardStat, newStat)) {
          setDashboardStat(newStat);
          message.info('New Records! Reload to update charts.');
          ret = newStat;
        }
      }
    }
    return ret;
  };

  const refetchData = (states, xCurrState, dashboardStat) => {
    setPage('');
    const reloadQueue = ['stateLists', 'dashboards'];
    let tossCoin = Math.ceil(Math.random() * 7) % 2;
    switch (tossCoin) {
      case reloadQueue.findIndex((x) => x === 'stateLists'):
        if (isRegulator) {
          getRegulatorAreas(userId).then((areas) => {
            if (areas) {
              if (!stateLists) setStateLists(areas.state);
              if (!isEqual(stateLists, areas.state)) {
                setStateLists(areas.state);
                if (!areas.state.some((x) => x.stateName === currState.stateName)) {
                  message.info('Reloading stats based on your updated profile');
                  setCurrState(areas.state[0]);
                  triggerReload(Math.random() * 12);
                }
              }
            }
          });
        }
        break;
      case reloadQueue.findIndex((x) => x === 'dashboards'):
        if (states && !xCurrState) {
          getDashboardStats({
            state: states,
            reload: false,
            dashboardStat,
          });
        } else if (xCurrState) {
          getDashboardStats({
            state: '',
            newState: xCurrState,
            reload: false,
            dashboardStat,
          });
        }

      default:
        break;
    }
  };

  useEffect(() => {
    // let timer, intraval;
    try {
      if (userCategory) {
        setPage('loading');
        if (stateLists) {
          getDashboardStats({ state: '', newState: currState }).catch(() => setPage('error'));
          // .then((dSt) => {
          //   timer = setTimeout(() => {
          //     intraval = setInterval(() => refetchData('', currState, dSt), 10000);
          //   }, 10000);
          // });

          // setPage('');
        } else if (isEDM) {
          getAllStates()
            .then((states) => {
              getDashboardStats({ state: states });
              // .then((dSt) => {
              //   timer = setTimeout(() => {
              //     intraval = setInterval(() => refetchData(states, currState, dSt), 10000);
              //   }, 100000);
              // });

              // setPage('');
            })
            .catch(() => setPage('error'));
        } else if (isRegulator) {
          getRegulatorAreas(userId)
            .then((areas) => {
              getDashboardStats(areas);
              // .then((dSt) => {
              //   timer = setTimeout(() => {
              //     intraval = setInterval(() => refetchData(areas.state, currState, dSt), 10000);
              //   }, 10000);
              // });

              // setPage('');
            })
            .catch(() => setPage('error'));
        }

        setPage('');
      } else return null;

      return () => {
        // clearTimeout(timer);
        // clearInterval(intraval);
      };
    } catch (error) {
      console.log('error', error);
      setErrorLoading(initialErrorCamp);
      setPage('error');
    }
  }, [page, loadState, currState]);

  const showDetails = (data) => console.log('showing details for', data);

  const chartMargin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
  };

  const summarySource = [
    {
      stats:
        dashboardStat && (dashboardStat.facilityCount || dashboardStat.facilityCount == 0)
          ? `${dashboardStat.facilityCount}`
          : '-',
      text: 'Total No of Facilities',
      label: 'facilities',
      detailsAvailable: false,
      condition: true,
    },
    {
      stats:
        dashboardStat && (dashboardStat.activeFacility || dashboardStat.activeFacility == 0)
          ? `${dashboardStat.activeFacility}`
          : '-',
      text: 'Percentage of Active Facilities',
      label: 'active',
      detailsAvailable: false,
      condition: true,
    },
    {
      stats:
        dashboardStat && (dashboardStat.qaqcRatio || dashboardStat.qaqcRatio == 0)
          ? `${dashboardStat.qaqcRatio}`
          : '-',
      text: 'QA/QC Completion Ratio',
      label: 'qaqc',
      condition: true,
    },
    {
      stats:
        dashboardStat &&
        (dashboardStat.qaqcComplianceToFmenv || dashboardStat.qaqcComplianceToFmenv == 0)
          ? `${dashboardStat.qaqcComplianceToFmenv}`
          : '-',
      text: 'QA Report Compliance',
      label: 'report',
      condition: true,
    },
    {
      stats:
        dashboardStat && dashboardStat.qaqcComplianceToWorldBank
          ? `${dashboardStat.qaqcComplianceToWorldBank}`
          : '-',
      text: 'World Compliance Index',
      label: 'compliance',
      condition: true,
    },
  ];

  const refresh = () => {
    window.location.reload();
  };

  const contentSource = [
    {
      type: 'limit-violations',
      title: 'Limit Violations (by Measurement types)',
      component: LimitViolations,
      data: limitViolations,
      condition: true,
      width: '450px',
      height: '350px',
      margin: chartMargin,
      error: errorLoading['limitViolations'],
      loading: dataLoading['limitViolations'],
      className: 'dashboard-limit__violations',
      fetch: getDashboardData,
      url:
        currState && currState.stateId
          ? `/LimitViolationByMeasurement?stateId=${currState.stateId}`
          : isEDM
          ? `/LimitViolationByMeasurement`
          : '',
    },
    {
      type: 'top-violators',
      title: 'Top 5 Regulatory Limit Violator',
      component: LimitViolators,
      data: limitViolators,
      condition: true,
      width: '450px',
      height: '350px',
      margin: chartMargin,
      error: errorLoading['limitViolations'],
      loading: dataLoading['limitViolations'],
      className: 'dashboard-limit__violators',
      fetch: getDashboardData,
      url:
        currState && currState.stateId
          ? `/RegulatoryLimitViolator?stateId=${currState.stateId}`
          : isEDM
          ? `/RegulatoryLimitViolator`
          : '',
    },
    {
      type: 'pollution-violations',
      title: 'Pollution violation by sectors',
      component: PollutionViolations,
      data: pollutionViolations,
      condition: true,
      width: '450px',
      height: '350px',
      margin: chartMargin,
      error: errorLoading['pollutionViolations'],
      loading: dataLoading['pollutionViolations'],
      className: 'dashboard-pollution__violations',
      fetch: getDashboardData,
      url:
        currState && currState.stateId
          ? `/SectorPollution?stateId=${currState.stateId}`
          : isEDM
          ? `/SectorPollution`
          : '',
    },
    {
      type: 'map-componenent',
      title: '',
      component: MapComponent,
      condition: true,
      data: dashboardMap,
      className: 'dashboard-map__component',
      width: '450px',
      height: '600px',
      margin: chartMargin,
      error: errorLoading['dashboardMap'],
      loading: dataLoading['dashboardMap'],
      loading: dataLoading['dashboardMap'],
      fetch: getDashboardData,
      currState: currState,
      url: currState && currState.stateId ? `/Realtime?stateId=${101}` : isEDM ? `/Realtime` : '',
    },
    {
      type: 'violation-trend',
      title: 'Pollutant Quarterly Violation Trend',
      component: ViolationTrend,
      data: violationTrend,
      condition: true,
      className: 'dashboard-violations__trend',
      width: '450px',
      height: '250px',
      margin: chartMargin,
      error: errorLoading['violationTrend'],
      loading: dataLoading['violationTrend'],
      fetch: getDashboardData,
      url:
        currState && currState.stateId
          ? `/QuaterlyViolation?stateId=${currState.stateId}`
          : isEDM
          ? `/QuaterlyViolation`
          : '',
    },
  ];

  if (page === 'loading')
    return (
      <div className="spin-fullpage">
        <LoadingContent pageLoading={true} />
      </div>
    );

  if (page === 'error')
    return (
      <PageContent>
        <div className="spin-fullpage">
          <Row type="flex" justify="center" align="middle" className="text-center mt-2rem">
            <Col
              xs={{
                span: 16,
              }}
              lg={{
                span: 12,
              }}
            >
              <FacilitiesIcon className="icon" />
            </Col>
          </Row>

          <div>
            <Row type="flex" justify="center" align="middle" className="text-center">
              <Col
                xs={{
                  span: 12,
                }}
                lg={{
                  span: 8,
                }}
              >
                <EmptyContent className="no-content">
                  <h1 className="no-content-header">Some problems occurred!</h1>
                  <p className="no-content-text">
                    Please check your internet connections or contact your administrator.
                  </p>
                  <div className="buttons">
                    <Button type="secondary" onClick={refresh}>
                      TRY AGAIN
                    </Button>
                  </div>
                </EmptyContent>
              </Col>
            </Row>
          </div>
        </div>
      </PageContent>
    );

  const stateMenu = (
    <Menu className="dashbaord-dropdown-menu">
      {isEDM && (
        <Menu.Item>
          <Link to="/" onClick={() => setCurrState({ stateName: 'all', stateId: '' })}>
            All States
          </Link>
        </Menu.Item>
      )}
      {stateLists &&
        stateLists.length > 0 &&
        stateLists.map(
          (st) =>
            st &&
            st.stateName && (
              <Menu.Item key={st.stateName}>
                <Link to="/" onClick={() => setCurrState(st)}>
                  {properCase(st.stateName)}
                </Link>
              </Menu.Item>
            )
        )}
    </Menu>
  );

  return (
    <PageContent>
      <div className="dashboard-summary">
        <Row justify="space-between" type="flex">
          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {currState && (
              <Dropdown overlay={stateMenu} trigger={['hover', 'click']}>
                <a
                  className="ant-dropdown-link"
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  // style={{ marginTop: '-1rem' }}
                >
                  <span className="dashboard-current__state">
                    {currState.stateName
                      ? currState.stateName === 'all'
                        ? 'All States'
                        : properCase(currState.stateName)
                      : ''}
                  </span>
                  <DownOutlined
                    style={{
                      fontSize: '10px',
                      paddingLeft: '5px',
                      color: '#66788a',
                      verticalAlign: 'middle',
                    }}
                  />
                </a>
              </Dropdown>
            )}
          </Col>
        </Row>
        <Row justify="space-between">
          {summarySource &&
            summarySource.map(
              (smry) =>
                smry.condition && (
                  <Col md={6} lg={4} xl={3} className="dashboard__summary-col" key={smry.label}>
                    <SummaryCard data={smry} loading={dataLoading} showDetails={showDetails} />
                  </Col>
                )
            )}
        </Row>
      </div>
      <div className="dashboard-content">
        {contentSource &&
          contentSource.map(
            (cntt) => cntt.condition && <cntt.component {...cntt} key={cntt.type} />
          )}
      </div>
    </PageContent>
  );
}
