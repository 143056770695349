import React from 'react';
import PageContainer from 'components/common/PageContainer';
import Layout from 'components/common/Layout';
import Reports from 'components/screens/AdminReports';

export default (props) => {
  
  return (
    <Layout headerTitle={'Reports'}>
      <PageContainer>
        <Reports props={props} id="reports" />
      </PageContainer>
    </Layout>
  );
};
