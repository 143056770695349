// import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';

export default styled(Menu)`
  // marginTop: -0.4rem;
  // border-radius: '2px';
  font-family: var(--font-family) !important;
  color: var(--dark);
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title,
  span {
    font-family: var(--font-family) !important;
  }
`;
