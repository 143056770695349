import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import TweenOne from 'rc-tween-one';
import Select from '../../common/Select';
import EmptyContent from 'components/common/EmptyContent';
import LoadingContent from 'components/common/LoadingContent';
import PageContent from 'components/common/PageContent';
import { ReactComponent as FacilitiesIcon } from 'assets/img/facilities.svg';

import CalendarOpen from '../../../assets/img/calendar-18.svg';
import UnscheduledQuarter from './UnscheduledQuarter';
import FirstQuarter from './FirstQuarter';
import SecondQuarter from './SecondQuarter';
import ThirdQuarter from './ThirdQuarter';
import FourthQuarter from './FourthQuarter';


import { getScheduleMetrics, getStates } from '../../../helpers/api';
import './Schedule.css';

const { Option } = Select;

const Scheduler = (props) => {
  const [tabKey, setTabKey] = useState('first_quarter');
  const [quarterMetrics, setQuarterMetrics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [reload, setReload] = useState(false);
  const [displayCards, setDisplayCard] = useState(true);
  const [facilityFilter, setFacilityFilter] = useState('');

  const { userCategory } = props.props;
  const userCat = userCategory.trim().toLowerCase();

  const canViewSchedules = userCat === 'edm';

  useEffect(() => {
    getQuarterMetrics();
    refreshStates();
  }, []);

  useEffect(() => {
    if (reload) {
      getQuarterMetrics();
    }
  }, [reload]);

  const getQuarterMetrics = () => {
    setLoading(true);
    const year = 0
    getScheduleMetrics(year)
      .then((res) => {
        console.log(res)
        setQuarterMetrics(res.result.data);
        setLoading(false);
        setReload(false);
      })
      .catch((err) => {
        setLoading(false);
        setReload(false);
      });
  };
  const refreshStates = () => {
    getStates()
      .then((res) => {
        setStates(res.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const tabList = [
    {
      key: 'unscheduled',
      tab: 'Unscheduled',
    },
    {
      key: 'first_quarter',
      tab: '1st Qtr',
    },
    {
      key: 'second_quarter',
      tab: '2nd Qtr',
    },
    {
      key: 'third_quarter',
      tab: '3rd Qtr',
    },
    {
      key: 'fourth_quarter',
      tab: '4th Qtr',
    },
  ];

  const onTabChange = (key, type) => {
    
    setTabKey(key);
  };

  const reloadMetrics = () => {
    setReload(true);
  };

  const toggleCardsShow = () => setDisplayCard(!displayCards);

  const quarterContent = {
    unscheduled: (
      <UnscheduledQuarter
        quarter="unscheduledQuarter"
        currentView={tabKey}
        userCategory={userCat}
        metrics={quarterMetrics}
        reload={reloadMetrics}
        stateFilter={facilityFilter}
      />
    ),
    first_quarter: (
      <FirstQuarter
        quarter="firstQuarter"
        userCategory={userCat}
        currentView={tabKey}
        reload={reloadMetrics}
        stateFilter={facilityFilter}
      />
    ),
    second_quarter: (
      <SecondQuarter
        userCategory={userCat}
        quarter="secondQuarter"
        reload={reloadMetrics}
        stateFilter={facilityFilter}
      />
    ),
    third_quarter: (
      <ThirdQuarter
        userCategory={userCat}
        quarter="thirdQuarter"
        reload={reloadMetrics}
        stateFilter={facilityFilter}
      />
    ),
    fourth_quarter: (
      <FourthQuarter
        userCategory={userCat}
        quarter="fourthQuarter"
        reload={reloadMetrics}
        stateFilter={facilityFilter}
      />
    ),
  };

  function refreshPage() {
    window.location.reload();
  }

  const filterOnSearch = (val) => {
    console.log('search val', val);
  };

  const networkErrorContent = () => {
    return (
      <>
        <Row type="flex" justify="center" align="middle" className="text-center">
          <Col
            xs={{
              span: 16,
            }}
            lg={{
              span: 12,
            }}
          >
            <FacilitiesIcon className="icon" />
          </Col>
        </Row>

        <div>
          <Row type="flex" justify="center" align="middle" className="text-center">
            <Col
              xs={{
                span: 12,
              }}
              lg={{
                span: 8,
              }}
            >
              <EmptyContent className="no-content">
                <h1 className="no-content-header">OOPS, Something went wrong</h1>
                <p className="no-content-text">NETWORK ERROR</p>
              </EmptyContent>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const unauthorisedContent = () => {
    return (
      <>
        <Row type="flex" justify="center" align="middle" className="text-center">
          <Col
            xs={{
              span: 16,
            }}
            lg={{
              span: 12,
            }}
          >
            <FacilitiesIcon className="icon" />
          </Col>
        </Row>

        <div>
          <Row type="flex" justify="center" align="middle" className="text-center">
            <Col
              xs={{
                span: 12,
              }}
              lg={{
                span: 8,
              }}
            >
              <EmptyContent className="no-content">
                <h1 className="no-content-header">OOPS, Something went wrong</h1>
                <p className="no-content-text">You're not authorised to view this page</p>
              </EmptyContent>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const emptyContent = () => {
    return (
      <>
        <Row type="flex" justify="center" align="middle" className="text-center">
          <Col
            xs={{
              span: 16,
            }}
            lg={{
              span: 12,
            }}
          >
            <FacilitiesIcon className="icon" />
          </Col>
        </Row>

        <div>
          <Row type="flex" justify="center" align="middle" className="text-center">
            <Col
              xs={{
                span: 12,
              }}
              lg={{
                span: 8,
              }}
            >
              <EmptyContent className="no-content">
                <h1 className="no-content-header">No facilities created yet</h1>
                <p className="no-content-text">
                  There are currently no facilities on the IEMS platform.
                </p>
              </EmptyContent>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const handleSelectedState = (key, value) => {
    setFacilityFilter(value);
  };

  const scheduleFilterDisplay = () => {
    return (
      <>
        <Row className="schedule__filter" justify="end">
          <Col lg={5}>
            <h3>Filter By: </h3>
          </Col>
          <Col lg={10}>
            <Select
              className="state__filter"
              showSearch
              placeholder="Select a state"
              optionFilterProp="children"
              onChange={(key, id) => handleSelectedState(key, id)}
            >
              <Option value="all">ALL STATES</Option>
              {states.map((state) => {
                return (
                  <Option key={state.stateName} value={state.stateId}>
                    {state.stateName}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {canViewSchedules ? (
        <PageContent>
          <div className="schedule__container">
            {displayCards && (
              <>
                <Row justify="space-between">
                  <Col md={10} lg={6} xl={5}>
                    <div
                      className={
                        'schedule__card ' +
                        (tabKey === 'first_quarter' ? '' : 'schedule__card__inactive')
                      }
                      onClick={() => setTabKey('first_quarter')}
                    >
                      <div className="schedule__heading">
                        <Row justify="center">
                          <Col lg={6} className="text-right">
                            <img src={CalendarOpen} className="schedule__icon" />
                          </Col>
                          <Col lg={16} className="text-left">
                            <h3 className="bold mb-5">First Quarter</h3>
                            <p>Jan - Mar</p>
                          </Col>
                          <Col md={24} lg={24} className="text-center">
                            {loading ? (
                              <LoadingContent modal={true} />
                            ) : (
                              <span className="schedule__info__badge">
                                {quarterMetrics.firstQuarter === 0 ? (
                                  'No Facilities'
                                ) : quarterMetrics.firstQuarter === 1 ? (
                                  <>{quarterMetrics.firstQuarter} facility </>
                                ) : (
                                  <>{quarterMetrics.firstQuarter} facilities</>
                                )}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col md={10} lg={6} xl={5}>
                    <div
                      className={
                        'schedule__card ' +
                        (tabKey === 'second_quarter' ? '' : 'schedule__card__inactive')
                      }
                      onClick={() => setTabKey('second_quarter')}
                    >
                      <div className="schedule__heading">
                        <Row justify="center">
                          <Col lg={6} className="text-right">
                            <img src={CalendarOpen} className="schedule__icon" />
                          </Col>
                          <Col lg={16} className="text-left">
                            <h3>Second Quarter</h3>
                            <p>Apr - Jun</p>
                          </Col>
                          <Col md={24} lg={24} className="text-center">
                            {loading ? (
                              <LoadingContent modal={true} />
                            ) : (
                              <span className="schedule__info__badge">
                                {quarterMetrics.secondQuarter === 0 ? (
                                  'No Facilities'
                                ) : quarterMetrics.secondQuarter === 1 ? (
                                  <>{quarterMetrics.secondQuarter} facility </>
                                ) : (
                                  <>{quarterMetrics.secondQuarter} facilities</>
                                )}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col md={10} lg={6} xl={5}>
                    <div
                      className={
                        'schedule__card ' +
                        (tabKey === 'third_quarter' ? '' : 'schedule__card__inactive')
                      }
                      onClick={() => setTabKey('third_quarter')}
                    >
                      <div className="schedule__heading">
                        <Row justify="center">
                          <Col lg={6} className="text-right">
                            <img src={CalendarOpen} className="schedule__icon" />
                          </Col>
                          <Col lg={16} className="text-left">
                            <h3>Third Quarter</h3>
                            <p>Jul - Sep</p>
                          </Col>
                          <Col md={24} lg={24} className="text-center">
                            {loading ? (
                              <LoadingContent modal={true} />
                            ) : (
                              <span className="schedule__info__badge">
                                {quarterMetrics.thirdQuarter === 0 ? (
                                  'No Facilities'
                                ) : quarterMetrics.thirdQuarter === 1 ? (
                                  <>{quarterMetrics.thirdQuarter} facility </>
                                ) : (
                                  <>{quarterMetrics.thirdQuarter} facilities</>
                                )}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col md={10} lg={6} xl={5}>
                    <div
                      className={
                        'schedule__card ' +
                        (tabKey === 'fourth_quarter' ? '' : 'schedule__card__inactive')
                      }
                      onClick={() => setTabKey('fourth_quarter')}
                    >
                      <div className="schedule__heading">
                        <Row justify="center">
                          <Col lg={6} className="text-right">
                            <img src={CalendarOpen} className="schedule__icon" />
                          </Col>
                          <Col lg={16} className="text-left">
                            <h3>Fourth Quarter</h3>
                            <p>Oct - Dec</p>
                          </Col>
                          <Col md={24} lg={24} className="text-center">
                            {loading ? (
                              <LoadingContent modal={true} />
                            ) : (
                              <span className="schedule__info__badge">
                                {quarterMetrics.fourthQuarter === 0 ? (
                                  'No Facilities'
                                ) : quarterMetrics.fourthQuarter === 1 ? (
                                  <>{quarterMetrics.fourthQuarter} facility </>
                                ) : (
                                  <>{quarterMetrics.fourthQuarter} facilities</>
                                )}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="schedule-page up-outlined">
                    <TweenOne
                      animation={{
                        y: '-=20',
                        yoyo: true,
                        repeat: 10,
                        duration: 1000,
                      }}
                      className="up-outlined-icon"
                      key="icon"
                    >
                      <UpCircleOutlined onClick={toggleCardsShow} />
                    </TweenOne>
                  </div>
                </Row>
              </>
            )}
            {!displayCards && (
              <Row>
                <div className="schedule-page down-outlined">
                  <TweenOne
                    animation={{
                      y: '-=20',
                      yoyo: true,
                      repeat: -1,
                      duration: 1000,
                    }}
                    className="down-outlined-icon"
                    key="icon"
                  >
                    <DownCircleOutlined onClick={toggleCardsShow} />
                  </TweenOne>
                </div>
              </Row>
            )}
            <Row justify="space-between" className="schedule__content">
              <Col lg={8} />
              <Col lg={8} />
              <Col lg={24}>
                <Card
                  style={{ width: '100%' }}
                  tabList={tabList}
                  activeTabKey={tabKey}
                  tabBarExtraContent={scheduleFilterDisplay()}
                  onTabChange={(key) => {
                    onTabChange(key, 'tabKey');
                  }}
                >
                  {quarterContent[tabKey]}
                </Card>
              </Col>
            </Row>
          </div>
        </PageContent>
      ) : (
        unauthorisedContent()
      )}
    </>
  );
};
export default Scheduler;
