import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Switch, message } from 'antd';
import LoadingContent from 'components/common/LoadingContent';
import {
  fetchPreferenceSetting,
  disableUserPreference,
  enableUserPreference,
} from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';
import { UserContext } from '../../../../store/UserContext';
import '../style.css';

const ModifyPreferences = () => {
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const { userData, getUserData } = useContext(UserContext);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    refreshNotifications();
  }, [userData]);

  useEffect(() => {
    if (reload) {
      refreshNotifications();
    }
  }, [reload]);

  const handleUpdateNotification = (values) => {
    console.log(values);
  };

  const refreshNotifications = () => {
    setLoading(true);
    if (userData && userData !== null) {
      fetchPreferenceSetting(userData.userId)
        .then((res) => {
          console.log(res.result.data);
          setNotifications(res.result.data);
          setReload(false);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          return openNotification({
            type: 'error',
            title: 'Something went wrong',
            message: error,
          });
        });
    }
  };

  const reloadNotifications = () => {
    setReload(true);
  };

  const handleDisableNotification = (ev, c, name) => {
    if (userData && userData !== null) {
      disableUserPreference(userData.userId, name)
        .then((res) => {
          // message.success('Updated');
          console.log('updated');
        })
        .catch((error) => {
          console.log(error);
          message.error(error);
          reloadNotifications();
        });
    }
  };

  const handleEnableNotification = (ev, c, name) => {
    console.log(name, userData.userId);
    if (userData && userData !== null) {
      enableUserPreference(userData.userId, name)
        .then((res) => {
          // message.success('Updated');
          console.log('updated');
          // reloadNotifications();
        })
        .catch((error) => {
          console.log(error);
          message.error(error);
          reloadNotifications();
        });
    }
  };

  const displayNotification = () => {
    if (notifications && notifications !== null) {
      return notifications.map((notif) => {
        return (
          <div className="notification__container">
            <div className="notification__heading mt-2rem">
              <h3 className="sub-heading bold">{notif.title}</h3>
              <p className="text-gray mb-15 sub-heading">{notif.subTitle}</p>
            </div>
            {notif.content.map((content) => {
              if (content.preferenceValue === 'true') {
                return (
                  <>
                    <Row className="mt-15">
                      <Col lg={24}>
                        <Switch
                          size="small"
                          defaultChecked={content.preferenceValue === 'true' ? true : false}
                          disabled={content.isActive === 'FALSE' ? true : false}
                          className="config__switch mr-10"
                          onChange={(checked, e, preferenceName) =>
                            handleDisableNotification(e, 'check', content.preferenceName)
                          }
                        />
                        <span
                          className="switch__text"
                          title={
                            content.isActive === 'FALSE'
                              ? 'This preference has been diabled by man'
                              : null
                          }
                        >
                          {content.preferenceDesc}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              } else {
                return (
                  <>
                    <Row className="mt-15">
                      <Col lg={24}>
                        <Switch
                          size="small"
                          className="config__switch mr-10"
                          defaultChecked={content.preferenceValue === 'true' ? true : false}
                          onChange={(checked, e, preferenceName) =>
                            handleEnableNotification(e, 'check', content.preferenceName)
                          }
                        />
                        <span className="switch__text">{content.preferenceDesc}</span>
                      </Col>
                    </Row>
                  </>
                );
              }
            })}
          </div>
        );
      });
    } else {
      return <LoadingContent pageLoading={true} />;
    }
  };
  return (
    <div className="notification__form mb-2rem">
      {loading ? <LoadingContent pageLoading={true} /> : displayNotification()}
    </div>
  );
};

export default ModifyPreferences;
