import React from 'react';
import { loadState, saveState } from './localStorage';

const localState = loadState();

export const AppContext = React.createContext();

const initialState = localState
  ? { ...localState, sidebarOpen: true }
  : {
      auth: {
        signedIn: false,
        firstname: null,
        lastname: null,
        accountId: null,
      },
      sidebarOpen: true,
    };

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      saveState({ auth: { signedIn: true, ...action.payload } });
      return { ...state, auth: { signedIn: true, ...action.payload } };
    case 'LOGOUT':
      saveState({ auth: { signedIn: false } });
      return { ...state, auth: { signedIn: false } };
    case 'TOGGLE-SIDEBAR':
      const isSideOpen = state.sidebarOpen;
      return { ...state, sidebarOpen: !isSideOpen };
    default:
      return state;
  }
};

export default (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};
