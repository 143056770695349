import React, { useState } from 'react';
import { Form, message, Row, Col, InputNumber } from 'antd';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import { updateParameter } from '../../../../helpers/api';

const EditParamterForm = ({ closeModal, name, id, unit, worldBank, fmLimit, reload }) => {
  const [loading, setLoading] = useState(false);
  console.log(fmLimit, 'fm limit');
  const initValues = {
    unit: name,
    siUnit: unit,
    worldBank,
    fmEnv: fmLimit,
  };

  const handleEditParameter = (values) => {
    console.log(values);
    setLoading(true);
    updateParameter(id, values)
      .then((res) => {
        console.log(res);
        message.success(`${name} parameter updated successfully!`);
        setLoading(false);
        reload();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
        setLoading(false);
      });
  };
  return (
    <Form
      layout="vertical"
      hideRequiredMark={true}
      className="edit__parameter__form"
      initialValues={initValues}
      onFinish={handleEditParameter}
    >
      <Row gutter={12}>
        <Col lg={12}>
          <Form.Item
            name="unit"
            label="Parameter Name"
            rules={[{ required: true, message: 'Please enter Parameter name' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            name="siUnit"
            label="SI Unit"
            rules={[{ required: true, message: 'Please enter Parameter SI Unit' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            name="fmEnv"
            label="FMEnv Limit"
            rules={[{ required: true, message: 'Please enter Parameter fmenv limit' }]}
          >
            <InputNumber className="parameter__input" />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            name="worldBank"
            label="World Bank Limit"
            rules={[{ required: true, message: 'Please enter Parameter world bank limit' }]}
          >
            <InputNumber className="parameter__input" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item className="mt-20">
        <Button
          type="primary"
          appearance="default"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
        <Button type="secondary" onClick={closeModal} disabled={loading} className="ml-20">
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditParamterForm;
