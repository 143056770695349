import React, { useState, useEffect, useRef } from 'react';
import {
  select,
  arc,
  pie,
  scaleOrdinal,
  quantize,
  interpolateWarm,
  interpolate,
  selectAll,
  scaleLinear,
  scaleBand,
  axisLeft,
  axisBottom,
  max,
  nest,
  sum,
} from 'd3';
import { Tooltip, Radio } from 'antd';
import { SyncOutlined } from '@ant-design/icons/lib/icons';
import styled from 'styled-components';
import LoadingContent from 'components/common/LoadingContent';
import { toFixed, addUrlParam } from 'helpers/utils';

const avgOptions = ['fmEnv', 'worldBank'];
const chartOptions = ['bchart', 'pchart'];

const avgRadioOptions = [
  { label: 'Fed. Ministry', value: avgOptions[0], name: 'envs2', className: 'envinput' },
  { label: 'World Bank', value: avgOptions[1], name: 'envs2', className: 'envinput' },
];

const chartRadioOptions = [
  { label: 'Bar Chart', value: chartOptions[0], name: 'chart1' },
  { label: 'Pie Chart', value: chartOptions[1], name: 'chart1' },
];

const LimitViolators = (props) => {
  const {
    loading: initialLoading,
    className,
    error,
    title,
    width,
    height,
    fetch,
    url,
    margin,
  } = props;
  const SVGCanvas = useRef(null);
  const envRef = useRef(null);
  const [data, setData] = useState();
  const [localError, setLocalError] = useState(error);
  const [loading, setLoading] = useState(initialLoading);
  const [mounted, setMounted] = useState(false);
  const [chartType, setChartType] = useState(chartOptions[0]);
  const [avgEnv, setAvgEnv] = useState(avgOptions[0]);
  const [currUrl, setCurrUrl] = useState();

  let arcGenerator, pieGenerator;

  const names = {
    fmEnv: 'fmenvViolationPercentage',
    worldBank: 'worldBankViolationPercentage',
  };

  const labels = {
    fmEnv: 'fmenvViolation',
    worldBank: 'worldBankViolation',
  };

  let { [avgEnv]: value } = names;

  const isEmpty = (data, env) => {
    if (data && data.length > 0) {
      if (data.some((dt) => dt[names[env]])) return false;
    }
    return true;
  };

  const plotBarChart = (xdata, svg) => {
    const theKey = 'facilityName';
    const bounds = svg.node().getBoundingClientRect();
    const w = bounds.width;
    const h = bounds.height;

    const xValue = (d) => d['key'];
    const yValue = (d) => d['value'];
    // const xValue = (d) => d[labels[avgEnv]];
    // const yValue = (d) => d[names[avgEnv]];

    let data = xdata[avgEnv];

    const margin = {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    };

    const innerWidth = w - (margin.left + 20) - margin.right;
    const innerHeight = h - margin.top - margin.bottom - 20;

    let cleanData = data.filter((d) => d[labels[avgEnv]] > 0);

    // let envInput = envRef.current;
    selectAll('.limit-violators input').on('change', change);

    let gData = nest()
      .key(function(d) {
        return d[theKey];
      })
      .rollup(function(d) {
        return sum(d, function(g) {
          return g[labels[avgEnv]];
        });
      })
      .entries(cleanData);

    const xScale = scaleBand()
      // .domain(newData.map(xValue))
      .range([0, innerWidth])
      .padding(0.1);

    const yScale = scaleLinear()
      // .domain([0, max(newData, yValue)])
      .range([innerHeight, 0]);

    createBarChart(gData);

    function createBarChart(newData, speed = 0) {
      xScale.domain(newData.map(xValue));
      yScale.domain([0, max(newData, yValue)]);

      const yAxis = axisLeft(yScale).tickSize(-innerWidth);

      const xAxis = axisBottom(xScale);

      const barGroups = svg.selectAll('g').data(newData, (d) => d);
      barGroups
        .exit()
        .remove()
        .transition()
        .duration(750);

      const g = barGroups
        .join('g')
        .attr('transform', `translate(${margin.left + 20}, ${margin.top})`);

      const yAxisG = g.append('g').call(yAxis);

      yAxisG.select('.tick').remove();
      // .selectAll('.domain, .tick line')
      // .remove();

      yAxisG.selectAll('.domain').remove();

      yAxisG.selectAll('.tick line').attr('stroke-width', 0.3);
      yAxisG.selectAll('.tick text').attr('x', -8);

      const xAxisG = g.append('g').call(xAxis);

      xAxisG.selectAll('.domain').remove();
      xAxisG.selectAll('.tick line').remove();
      xAxisG.selectAll('.domain .tick line').remove();

      // append the rectangles for the bar chart
      g.selectAll('.bar')
        .data(newData)
        // .enter()
        .join('rect')
        .attr('class', 'bar')
        .attr('x', (d) => xScale(xValue(d)))
        .attr('width', xScale.bandwidth())
        .attr('y', (d) => yScale(yValue(d)))
        // .transition()
        // .duration(speed)
        .attr('height', (d) => innerHeight - yScale(yValue(d)));

      g.selectAll('.bar')
        .append('title')
        .text((d) => `${toFixed(d['value'], 2)}`);

      // add the x Axis
      xAxisG
        .attr('transform', 'translate(0,' + innerHeight + ')')
        .append('text')
        .style('text-anchor', 'end')
        .attr('dx', '.8em')
        .style('font-size', '15px')
        .attr('dy', '.75em')
        .attr('transform', 'rotate(-35)');

      g.append('text') // text label for the x axis
        .attr('x', 0)
        .attr('y', 1)
        .style('text-anchor', 'middle')
        .attr('transform', `translate(-30, ${innerHeight / 2}) rotate(-90)`)
        .text('Count of Violations');
    }

    function change() {
      var value = this.value;
      if (avgOptions.includes(value)) {
        cleanData = xdata[value].filter((d) => d[labels[value]] > 0);

        gData = nest()
          .key(function(d) {
            return d[theKey];
          })
          .rollup(function(d) {
            return sum(d, function(g) {
              return g[labels[value]];
            });
          })
          .entries(cleanData);

        createBarChart(gData, 750);
      }
    }
  };

  const plotPieChart = (xdata, svg) => {
    const theKey = 'facilityName';
    const bounds = svg.node().getBoundingClientRect();
    const w = bounds.width;
    const h = bounds.height;
    const innerW = w - margin.left - margin.right;
    const innerH = h - margin.top - margin.bottom;

    let data = xdata[avgEnv];

    // let data = data[avgEnv];

    let canvasOffset = 25;

    const legendRectSize = 12;

    const legendSpacing = (8 * 10) / data.length;

    const r = Math.min(innerH, innerW) / 2 - canvasOffset; // 100;
    const color = scaleOrdinal()
      .domain(data.map((d) => d[theKey]))
      .range(quantize((t) => interpolateWarm(t * 0.8 + 0.1), data.length).reverse());

    // var color = scaleOrdinal(schemeSet3);

    selectAll('.limit-violators input').on('change', change);

    // this will create <path> elements for us using arc data
    arcGenerator = arc()
      .innerRadius(0)
      .outerRadius((d, i) => r - (50 - d.data[value]));

    // create the arc (start and end angle) with the value of each element in our data array
    pieGenerator = pie()
      .sort((a, b) => b[value] - a[value])
      .value((d) => d[value]);

    // create canvas for the chart
    let canvas = svg
      .append('g')
      .classed('g-box', true)
      .attr('transform', `translate(${innerW / 2 - canvasOffset * 2 - 10}, ${innerH / 2})`)
      .selectAll('path')
      .data(pieGenerator(data))
      .join('path')
      .attr('d', arcGenerator)
      .attr('fill', (d) => color(d.data[theKey]))
      .attr('class', 'slice');

    canvas.append('title').text((d) => `${d.data[theKey]}: ${toFixed(d.data[value], 2)}%`);

    // Store the displayed angles in _current.
    // Then, interpolate from _current to the new angles.
    // During the transition, _current is updated in-place by d3.interpolate.
    function arcTween(a) {
      var i = interpolate(this._current, a);
      this._current = i(0);
      return function(t) {
        return arcGenerator(i(t));
      };
    }

    let canvasPath;

    const update = (value, canvas) => {
      canvasPath = canvas
        // .append('g') //create a group to hold each slice (we will have a <path> and a <text> element associated with each slice)
        // .data(arcData)
        // .join('path')
        // .attr('d', arcGenerator)
        .each(function(d) {
          this._current = d;
        }); // store the initial angles
    };

    update(value, canvas);

    function change() {
      var value = this.value;
      pieGenerator.value(function(d) {
        return d[names[value]];
      })(data); // change the value function

      arcGenerator = arc()
        .innerRadius(0)
        .outerRadius((d, i) => r - (50 - d.data[names[value]]));
      canvas = canvas.data(pieGenerator(data)); // compute the new angles
      canvas
        .selectAll('title')
        .join('title')
        .text((d) => `${d.data[theKey]}: ${toFixed(d.data[names[value]], 2)}%`);

      canvas
        .transition()
        .duration(750)
        .attrTween('d', arcTween); // redraw the arcs

      update(names[value], canvas);
      legend
        .selectAll('text')
        .join('text')
        .text((d) => `${d[theKey]}, ${toFixed(d[names[value]], 2)}%`);
      // updateLegend()
    }

    const legend = svg
      .append('g')
      .classed('g-legend', true)
      .attr(
        'transform',
        `translate(${innerW / 2 + r - canvasOffset}, ${innerH / 2 + canvasOffset})`
      )
      .selectAll('.legend')
      .data(data)
      .join('g')
      .attr('class', 'legend')
      .attr('transform', function(d, i) {
        const h1 = legendRectSize + legendSpacing;
        const offset = (h1 * color.domain().length) / 2;
        const horz = -2 * legendRectSize;
        const vert = i * h1 - offset;
        return 'translate(' + horz + ',' + vert + ')';
      });

    legend
      .append('circle')
      .attr('cx', legendRectSize - 20)
      .attr('cy', legendRectSize - legendSpacing)
      .attr('r', 4)
      .style('fill', (d) => color(d[theKey]))
      .style('stroke', (d) => color(d[theKey]));

    legend
      .append('text')
      .attr('x', legendRectSize + legendSpacing)
      .attr('y', legendRectSize - legendSpacing)
      .text((d) => `${d[theKey]}, ${toFixed(d[value], 2)}%`)
      .attr('dy', '4')
      .attr('dx', `-20`)
      .style('text-anchor', 'start');
  };

  const render = (data) => {
    const svg = select(SVGCanvas.current);
    if (svg.node()) {
      if (chartType && chartType === 'pchart') plotPieChart(data, svg);
      else plotBarChart(data, svg);
    }
  };

  const renderChart = (refresh) => {
    if (refresh) setLoading(true);
    if (!url && !refresh) setLoading(true);
    else if (data && data[avgEnv] && data[avgEnv].length > 0 && url === currUrl) render(data);
    else if (!localError)
      Promise.all([fetch(addUrlParam(url, `standard=1`)), fetch(addUrlParam(url, 'standard=2'))])
        .then(([result, result2]) => {
          const res = {};
          res[avgOptions[0]] = result.result.data;
          res[avgOptions[1]] = result2.result.data;
          setCurrUrl(url);
          if (res && res[avgOptions[0]] && res[avgOptions[0]].length > 0) {
            setData(res);
            render(res);
          } else {
            res[avgOptions[0]] = [];
            res[avgOptions[1]] = [];

            setData(res);
          }
          if (loading) setLoading(false);
        })
        .catch((err) => {
          console.log('error fetching', err);
          if (loading && mounted) setLoading(false);
          if (mounted) setLocalError(true);
        });
  };

  const refresh = () => {
    setLocalError(false);
    renderChart(true);
  };

  const changeAvgEnv = (e) => {
    setAvgEnv(e.target.value);
  };

  const changeChartType = (e) => {
    // setData('');
    setChartType(e.target.value);
    // setLoading(false);
  };

  useEffect(() => {
    try {
      // if (!mounted && !data) setLoading(true);
      if (!mounted) setMounted(true);
      if (mounted) renderChart();
      else {
        return;
      }
    } catch (err) {
      console.log('caught: ', err);
      setLocalError(true);
    }
    return () => {
      // setData('');
      const clearPlots = () => {
        const svg = select(SVGCanvas.current);
        if (svg.node()) {
          svg
            .selectAll('g')
            .data([], (d) => d)
            .exit()
            .remove()
            .transition()
            .duration(750);
        }
      };
      clearPlots();

      return setMounted(false);
    };
  }, [mounted, url, chartType, localError, loading]);

  return (
    <>
      <div className={`dashboard-content-box ${className}`}>
        <div className="box-item-title">
          <h3 className="subheading bold limit-violators-radio">
            {title}
            {data && (
              <Radio.Group
                options={chartRadioOptions}
                onChange={changeChartType}
                defaultValue={chartType}
                disabled={isEmpty(data[avgEnv], avgEnv)}
                style={{ float: 'right' }}
                className="envinput"
              />
            )}
          </h3>
        </div>
        <div className="box-item-canvas limit-violators">
          {loading && <LoadingContent modal={true} />}
          {!loading && !localError && data && data[avgEnv].length > 0 && (
            <>
              <Radio.Group
                options={avgRadioOptions}
                onChange={changeAvgEnv}
                defaultValue={avgEnv}
                style={{ float: 'right' }}
                ref={envRef}
              />
              {isEmpty(data[avgEnv], avgEnv) ? (
                <>
                  <span>Nothing to show!</span>
                  <Tooltip title="Refresh">
                    <span className="ml-1rem pointer" onClick={refresh}>
                      <SyncOutlined />
                    </span>
                  </Tooltip>
                </>
              ) : (
                <svg
                  className="limit-violators-canvas"
                  ref={SVGCanvas}
                  style={{ width: '100%', height }}
                />
              )}
            </>
          )}
          {!loading &&
            !localError &&
            ((data && data[avgEnv] && data[avgEnv].length === 0) ||
              !data ||
              (data && !data[avgEnv])) && (
              <>
                <span>Nothing to show!</span>
                <Tooltip title="Refresh">
                  <span className="ml-1rem pointer" onClick={refresh}>
                    <SyncOutlined />
                  </span>
                </Tooltip>
              </>
            )}
          {!loading && localError && (
            <>
              <span>Oops, we encountered some problems!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const generateSize = ({ width = '400px', height = '350px' }) => {
  return `width: ${width};height: ${height};`;
  // return `height: ${height};`;
};

export default styled(LimitViolators)`
  // ${(props) => generateSize(props)};
  box-shadow: -1px 2px 8px -2px rgba(67, 90, 111, 0.2);
  // box-shadow: 0px 8px 13px 4px rgba(67, 90, 111, 0.2);
  // box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: var(--font-family);
  border-radius: 5px;

  rect {
  fill: steelblue;
}
circle {
  fill: steelblue;
}

.tick text {
  fill: #635f5d;
}

.tick line {
  stroke: #c0c0bb;
}

.axis-label {
  font-size: 12px;
  fill: #8e8883;
}

.title {
  font-size: 1em;
  fill: #636363;
  text-anchor: middle;
}

.line-path {
  fill: none;
  stroke: steelblue;
  stroke-width: 2;
  stroke-linejoin: round;
}


text {
  text-anchor: middle;
  font-size: 12px;
  font-family: var(--font-family);
}

  .box-item-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    .subheading {
      padding-bottom: 14px;
      padding-top: 14px;
      padding-left: 14px;
      padding-right: 5px;

      font-size: 18px;
      font-family: var(--font-family-bold);

      .ant-radio-group {
        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #1c81ce;
            background-color: #1c81ce;
            ::after {
              background-color: white;
              width: 5.5px;
              left: 4px;
              top: 3.3px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .box-item-canvas {
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 5px;    
    margin-bottom: 14px;
    height: calc(100% - 30px);

    @media (max-width: 1200px) {
      {
        ${(props) =>
          props.error || props.loading
            ? `height: calc(100% - 30px);`
            : `height: ${props.height}`};      
      }
    }
    width: 100%;
    font-family: var(--font-family);

    svg {
      .g-box {
        transform: translate(9rem, 11rem);
      }
      .g-legend {
        transform: translate(22rem, 15rem);
      }
      .legend {
            font-size: 12px;
            font-family: var(--font-family);
        text {
          font-size: 12px;
        }
        rect {
          stroke-width: 2;
        }
      }
      @media (max-width: 2480px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
            text {
              font-size: 0.8em;
            }
          }
        }
      }
      @media (max-width: 1655px) {
        {
          .g-legend {
            transform: translate(65%, 15rem);
            text {
              font-size: 0.7em;
            }
          }
        }
      }
      @media (max-width: 1400px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }
      @media (max-width: 1240px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1200px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
            text {
              font-size: 0.9em;
            }
          }
        }
      }      
    }
  }
  
`;
