import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  Spin,
  Modal,
  Typography,
  Collapse,
  Carousel,
  Result,
  Form,
  Radio,
  Menu,
  Dropdown,
  DatePicker,
} from 'antd';
// import { CaretDownOutlined } from '@ant-design/icons';
import {
  TiArrowDownThick as CaretDownOutlined,
  TiArrowUpThick as CaretUpOutlined,
} from 'react-icons/ti';
import moment from 'moment';
import EmptyContent from 'components/common/EmptyContent';
import LoadingContent from 'components/common/LoadingContent';
import { ReactComponent as ReportsIcon } from 'assets/img/facilities.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus.svg';
import Button from 'components/common/Button';
// import CreateNewFacility from 'components/CreateNewFacility';
import PageHeader from 'components/common/PageHeader';
import PageContent from 'components/common/PageContent';
import SearchInput from 'components/common/SearchInput';
import Filter from 'components/common/Filter';
import CloseIcon from 'components/common/CloseIcon';
import { openNotification } from 'helpers/notification';
import { getReports, getSamplePointRecord, getComparativeReport } from 'helpers/api';
import ReportsTable from 'components/ReportTable';
import Select from 'components/common/Select';
import {
  properCase,
  formatDate,
  generateRadomColor,
  numeralize,
  datelize,
  isEmpty,
  extractCompareData,
  getBiggestSamplePoint,
} from 'helpers/utils';
import WindPowerIcon from 'assets/img/wind-power.svg';
import FacilityUploadTable from '../../FacilityUploadTable';
import ReportCompareTable from '../../ReportCompareTable';
import '../../../shared.css';
import '../../../scrollfix.css';
import './index.css';

const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
// const { SubMenu } = Menu;
const { RangePicker } = DatePicker;

const currentYear = new Date().getFullYear();

const initCompData = { qaqc: {}, report: {} };
const avgOptions = ['fmEnv', 'worldBank'];
const avgRadioOptions = [
  { label: 'FM Env', value: 'fmEnv' },
  { label: 'World Bank', value: 'worldBank' },
];

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 14 },
};

const initAvgCompData = {};
// avgOptions.map((avgOpt) => (avgCompData[avgOpt] = initCompData));

avgOptions.map((avgOpt) => (initAvgCompData[avgOpt] = initCompData));

const initialComparativeState = {
  results: '',
  allSamplePointRecordData: {},
  allSamplePointRecord: {},
  allParameters: {},
  comparativeReport: {},
  samplePoints: '',
  samplePointsLists: [],
  currentSamplePoint: {},
  currentSamplePointRecord: {},
  currentSamplePointRecordData: {},
  currentParameters: {},
  parameters: '',
  compareParameters: '',
  avgCompareResult: '',
  currCompareResult: [],
  avgLocalCompareResult: [],
  currLocalCompareResult: [],
  compareLocalParameters: [],
  facilityDetails: '',
};

const TrendDown = () => (
  <CaretDownOutlined style={{ color: '#ac000d', fontSize: '9px', display: 'inline' }} />
);
const TrendUp = () => (
  <CaretUpOutlined style={{ color: '#ac000d', fontSize: '9px', display: 'inline' }} />
);

export default function Facilities({ props }) {
  const initialPage = props.location.pathname === '/reports/create' ? 'create' : 'loading';
  const [page, setPage] = useState(initialPage);
  const [reports, setReports] = useState('');
  const [report, setReport] = useState('');
  const [currSamplePointReport, setCurrSamplePointReport] = useState('');
  const [compareFail, setCompareFail] = useState('');
  const [currCompareResult, setCurrCompareResult] = useState('');
  const [activeSamplePoint, setActiveSamplePoint] = useState('');
  const [parameters, setParameters] = useState('');
  const [comparativeState, setComparativeState] = useState(initialComparativeState);

  const [modal, setModal] = useState('');
  const [results, setResults] = useState('');
  const [avgEnv, setAvgEnv] = useState(avgOptions[0]);
  const [avgCompareData, setAvgCompareData] = useState(initAvgCompData);
  const [consultantFilter, setConsultantFilter] = useState('');
  const [quarterFilter, setQuarterFilter] = useState('');
  const [facilityFilter, setFacilityFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sectorFilter, setSectorFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [eiaFilter, setEiaFilter] = useState('');
  const [reportTypeFilter, setReportTypeFilter] = useState('');
  const [periodFilter, setPeriodFilter] = useState('');
  const [search, setSearch] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [periodText, setPeriodText] = useState('Year-to-Date');
  // const [modalLoading, setModalLoading] = useState(false);
  // const OPEN_KEYS = ['sub1'];
  // const [openKeys, setOpenKeys] = useState(OPEN_KEYS);

  const filterFormRef = useRef();

  const updateState = (newState, which, oldState) => {
    // console.log('compaaaaa', newState, oldState);
    const currState = oldState ? { ...oldState } : { ...comparativeState };
    let updates = {};
    if (which.includes('/')) {
      which = which.split('/');
      if (which.length === 2) {
        currState[which[0]][which[1]] = newState;
        updates = { [which[0]]: currState[which[0]] };
      }
      if (which.length === 3) {
        if (!currState[which[0]][which[1]])
          currState[which[0]][which[1]] = {
            [which[2]]: newState,
          };
        currState[which[0]][which[1]][which[2]] = newState;
        updates = { [which[0]]: currState[which[0]] };
      }
    } else {
      updates = { [which]: newState };
    }
    // console.log('updates', updates);
    // console.log('the old state', currState);
    // console.log('about to update', { ...currState, ...updates });
    if (oldState) return { ...currState, ...updates };
    setComparativeState({ ...currState, ...updates });
  };

  const getReportsLists = (urlParam) => {
    setIsSearching(true);
    getReports(urlParam)
      .then(({ result }) => {
        const rLists = [];
        result.data && result.data.length > 0 && result.data.forEach((res) => rLists.push(res));
        setReports(rLists);
      })
      .catch((err) => {
        setPage('error');
        // return openNotification({
        //   type: 'error',
        //   title: 'Failed to get reports',
        //   message: `${err}`,
        // });
      })
      .finally(() => setIsSearching(false));
  };

  const getAndSetSamplePointRecord = (samplePtId, reportId) => {
    getSamplePointRecord(samplePtId, reportId)
      .then(({ result }) => {
        const sptRecord = { data: null };
        if (result.data) sptRecord.data = result.data;
        setActiveSamplePoint(sptRecord);
        if (result.data && result.data.parameters) setParameters(result.data.parameters);
        if (result.data && result.data.results) {
          const formatResult = { ...result.data };
          const resultReturning = [];

          formatResult.results.map((fmRst) => {
            const objR = {};
            fmRst.data.map((fmRst2) => {
              const thePamata = result.data.parameters.filter(
                (pamata) => pamata.parameterId === fmRst2.parameterId
              );
              if (thePamata[0] && thePamata[0]['unit']) {
                objR[thePamata[0]['unit']] = fmRst2.resultValue;
                objR['entryDate'] = fmRst2.entryDate;
              }
            });
            // if (!objR['entryDate']) objR['entryDate'] = new Date();
            // resultReturning.push(objR);
            if (Object.keys(objR > 0)) {
              resultReturning.push(objR);
            }
          });

          setCurrSamplePointReport(resultReturning);
        }
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get record',
          message: `${err}`,
        });
      });
  };

  const getAndSetSamplePointRecord2 = async (
    samplePtId,
    reportId,
    quarter,
    tempComparativeState = { ...comparativeState }
  ) => {
    let { result } = await getSamplePointRecord(samplePtId, reportId);
    const sptRecord = { data: null };
    const resultReturning = [];
    if (result.data && result.data.results) {
      const formatResult = { ...result.data };

      formatResult.results.map((fmRst, index) => {
        const objR = {};
        fmRst.data.map((fmRst2) => {
          const thePamata = result.data.parameters.filter(
            (pamata) => pamata.parameterId === fmRst2.parameterId
          );
          if (thePamata[0] && thePamata[0]['unit']) {
            objR[thePamata[0]['unit']] = fmRst2.resultValue.toFixed(2);
            objR['entryDate'] = fmRst2.entryDate;
            objR['reportStatus'] = fmRst2.resultStatus;
          }
        });
        if (Object.keys(objR > 0)) {
          resultReturning.push(objR);
        }
      });
    }
    // if (result.data && result.data.parameters) {
    // }

    if (result.data) {
      // if (isEmpty(tempComparativeState.currentSamplePoint)) {
      //   if (isEmpty(tempComparativeState.currentSamplePoint))
      //     tempComparativeState = updateState(
      //       result.data,
      //       'currentSamplePoint',
      //       tempComparativeState
      //     );
      // }
      // if (isEmpty(tempComparativeState.currentParameters[`q${quarter}`]))
      //   tempComparativeState = updateState(
      //     result.data.parameters,
      //     `currentParameters/q${quarter}`,
      //     tempComparativeState
      //   );
      tempComparativeState = updateState(
        result.data.parameters,
        `allParameters/${samplePtId}/q${quarter}`,
        tempComparativeState
      );
    }
    tempComparativeState = updateState(
      resultReturning,
      `allSamplePointRecordData/${samplePtId}/q${quarter}`,
      tempComparativeState
    );

    return tempComparativeState;
  };

  const extractCompareData1 = (samplePtId, field = 'all') => {
    const fmtRst = results.filter((rsts) => rsts.samplePointId === samplePtId);
    if (fmtRst && fmtRst.length > 0) {
      const pmtRst = [];
      const resultReturning = [];
      const avgReturning = [];

      if (field === 'parameters' || field === 'all') {
        if (fmtRst[0].qaqc.length >= fmtRst[0].report.length) {
          fmtRst[0].qaqc.map((fmQ) =>
            pmtRst.push({
              measurementId: fmQ.measurementId,
              parameterId: fmQ.parameterId,
              unit: fmQ.unit,
            })
          );
        } else {
          fmtRst[0].report.map((fmQ) =>
            pmtRst.push({
              measurementId: fmQ.measurementId,
              parameterId: fmQ.parameterId,
              unit: fmQ.unit,
            })
          );
        }
        if (field === 'parameters') return { parameters: pmtRst };
      }
      if (field === 'tabledata' || field === 'all') {
        const objQaqc = {};
        const avgQCompData = { fmEnv: {}, worldBank: {} };

        fmtRst[0].qaqc.map((fmtR) => {
          objQaqc[fmtR.unit] = fmtR.average.toFixed(2);
          avgQCompData['fmEnv'][fmtR.unit] = fmtR['fmEnv'].toFixed(2);
          avgQCompData['worldBank'][fmtR.unit] = fmtR['worldBank'].toFixed(2);
        });
        resultReturning.push(objQaqc);
        avgReturning.push(avgQCompData);

        const objReport = {};
        const avgRCompData = { fmEnv: {}, worldBank: {} };
        fmtRst[0].report.map((fmtR) => {
          objReport[fmtR.unit] = fmtR.average.toFixed(2);
          avgRCompData['fmEnv'][fmtR.unit] = fmtR['fmEnv'].toFixed(2);
          avgRCompData['worldBank'][fmtR.unit] = fmtR['worldBank'].toFixed(2);
        });
        resultReturning.push(objReport);
        avgReturning.push(avgRCompData);

        if (field === 'tabledata') return { tabledata: resultReturning };
      }
      return { parameters: pmtRst, tabledata: resultReturning, avgData: avgReturning };
    } else return null;
  };

  const getNextCompareData = async (samplePtId) => {
    try {
      let result = extractCompareData(samplePtId, 'all');
      if (result && result.parameters) {
        setAvgCompareData(result.avgData);
        setParameters(result.parameters);
        setCurrCompareResult(result.tabledata);
      } else {
        setParameters('');
        setCurrCompareResult([]);
      }
    } catch (err) {
      console.log('error in generating compare data', err);
      return;
    }
  };

  function getNextCompareData2(theWorkingState, field = 'all') {
    if (theWorkingState) {
      const { currentSamplePoint, allParameters, results } = theWorkingState;
      const samplePtId = currentSamplePoint.samplePointId;

      let tempWorkingState = { ...theWorkingState };

      try {
        let result = extractCompareData({ samplePtId, field, results });
        if (result && result.avgData) {
          tempWorkingState = updateState(result.avgData, 'avgLocalCompareResult', tempWorkingState);
          tempWorkingState = updateState(
            result.tabledata,
            'currLocalCompareResult',
            tempWorkingState
          );

          const paramKeys = Object.keys(allParameters[samplePtId]);

          let matchingParams = [];
          if (paramKeys && paramKeys.length > 0) {
            matchingParams = allParameters[samplePtId][paramKeys[0]];
          }

          tempWorkingState = updateState(
            matchingParams,
            'compareLocalParameters',
            tempWorkingState
          );
          return tempWorkingState;
        } else {
          tempWorkingState = updateState('', 'compareLocalParameters', tempWorkingState);
          tempWorkingState = updateState([], 'currLocalCompareResult', tempWorkingState);
          return tempWorkingState;
        }
      } catch (err) {
        console.log('error in generating compare data', err);
        return theWorkingState;
      }
    }
  }

  const getCompareReport = async (facilityId, facData, type, yyyy = currentYear) => {
    const compList = [];
    const results = [];
    const sptPoints = [];
    const actualParams = {};
    const parametas = {};
    let tempComparativeState = { ...comparativeState, facilityDetails: facData };
    try {
      let { result } = await getComparativeReport(facilityId, yyyy);

      if (result.data && result.data.length > 0) {
        for (const res of result.data) {
          if (res.type.toLowerCase().trim() !== 'adhoc') {
            compList.push(res);
            const resultObj = {};
            const sptObj = {};
            const resultToKeep = [...res.results];
            if (resultToKeep && resultToKeep.length > 0) {
              resultToKeep.map((resKp) => {
                resKp['reportType'] = res.reportType;
                resKp['reportStatus'] = res.reportStatus;
                resKp['reportQuarter'] = `q${res.reportQuarter}`;
              });
            }
            resultObj[`q${res.reportQuarter}`] = resultToKeep;
            sptObj[`q${res.reportQuarter}`] = res.samplePoints;
            results.push(resultObj);
            sptPoints.push(sptObj);

            for (const res1 of res.samplePoints) {
              tempComparativeState = await getAndSetSamplePointRecord2(
                res1.samplePointId,
                res.reportId,
                res.reportQuarter,
                tempComparativeState
              );
            }

            res.results.map((res2) => {
              const thePmtObj = [];
              const whichRpt =
                res2.qaqc && res2.report
                  ? res2.qaqc.length > res2.report.length
                    ? res2.qaqc
                    : res2.report
                  : null;
              if (whichRpt && whichRpt.length > 0) {
                whichRpt.map((thWch) => {
                  const pmtObj = {};
                  pmtObj['parameterId'] = thWch['parameterId'];
                  pmtObj['unit'] = thWch['unit'];
                  pmtObj['siUnit'] = thWch['siUnit'];
                  pmtObj['measurementId'] = thWch['measurementId'];
                  pmtObj['worldBank'] = thWch['worldBank'];
                  pmtObj['fmEnv'] = thWch['fmEnv'];
                  thePmtObj.push(pmtObj);
                });
                parametas[`${res2['samplePointName']}`] = thePmtObj;
              }
            });
            actualParams[`q${res.reportQuarter}`] = parametas;
          }
        }
      }

      if (isEmpty(tempComparativeState.parameters)) {
        tempComparativeState = updateState(actualParams, 'parameters', tempComparativeState);
      }
      if (isEmpty(tempComparativeState.results))
        tempComparativeState = updateState(results, 'results', tempComparativeState);
      if (isEmpty(tempComparativeState.samplePoints))
        tempComparativeState = updateState(sptPoints, 'samplePoints', tempComparativeState);
      if (isEmpty(tempComparativeState.comparativeReport))
        tempComparativeState = updateState(compList, 'comparativeReport', tempComparativeState);

      let { currentSamplePoint, samplePoints } = tempComparativeState;

      if (isEmpty(currentSamplePoint)) {
        const biggestSamplePoint = getBiggestSamplePoint(samplePoints);
        let biggestSamplePointArray = [];
        if (biggestSamplePoint && Object.keys(biggestSamplePoint))
          biggestSamplePointArray = biggestSamplePoint[Object.keys(biggestSamplePoint)[0]];

        tempComparativeState = updateState(
          biggestSamplePointArray[0],
          'currentSamplePoint',
          tempComparativeState
        );

        tempComparativeState = updateState(
          biggestSamplePointArray,
          'samplePointsLists',
          tempComparativeState
        );
      }

      return tempComparativeState;

      // console.log('reportsss', result);
      // if (result.data && result.data.samplePoints) {
      //   const sptId = result.data.samplePoints[0].samplePointId;

      //   if (result.data.results && result.data.results.length > 0) {
      //     result.data.results.map((rst1) => {
      //       if (!rst1.qaqc || !rst1.qaqc.length) return setCompareFail('No qaqc');
      //       if (!rst1.report || !rst1.report.length) return setCompareFail('No qaqc');
      //     });
      //     setResults(result.data.results);
      //   }
      // }

      // .then(() => getASampAndCompare(comparativeState))
    } catch (err) {
      console.log('Fetch report error', err);
      setModal('');
      return openNotification({
        type: 'error',
        title: 'Failed to get report',
        message: `${err}`,
      });
    }
  };

  const getAReport = (reportId, type) => {
    let url = `/${reportId}`;
    if (type === 'comparing') url = `/${reportId}/ComparativeReport`;
    getReports(url)
      .then(({ result }) => {
        if (result.data && result.data.samplePoints) {
          const sptId = result.data.samplePoints[0].samplePointId;

          getAndSetSamplePointRecord(sptId, reportId);
          // result is an array and but qaqc is null when empty
          // if change to data structure, change it here
          if (type === 'comparing' && result.data.results && result.data.results.length > 0) {
            result.data.results.map((rst1) => {
              if (!rst1.qaqc || !rst1.qaqc.length) return setCompareFail('No qaqc');
              if (!rst1.report || !rst1.report.length) return setCompareFail('No qaqc');
            });
            setResults(result.data.results);
            // getAndSetCompareData(result.data.results[0].qaqc, result.data.results[0].report);
          }
        }
        setReport({ type, data: result });
      })
      .catch((err) => {
        console.log('Fetch report error', err);
        setModal('');
        return openNotification({
          type: 'error',
          title: 'Failed to get report',
          message: `${err}`,
        });
      });
  };

  function getASampAndCompare(comparativeState) {
    if (isEmpty(comparativeState.samplePointsLists)) {
      const { allSamplePointRecordData } = comparativeState;

      const samplePointsLists = Object.keys(allSamplePointRecordData);
      updateState(samplePointsLists, 'samplePointsLists');
      const currentSamplePoint = allSamplePointRecordData[samplePointsLists[0]];

      updateState(currentSamplePoint, 'currentSamplePoint');
    }
  }

  function doCompareWork(theWorkingState) {
    return getNextCompareData2(theWorkingState);
  }

  function doAndSetCompareWork(theWorkingState) {
    if (theWorkingState) {
      setComparativeState(getNextCompareData2(theWorkingState));
    }
  }

  useEffect(() => {
    if (page !== 'create' && page !== 'error') setPage('loading');
    try {
      if (props.location.pathname === '/reports/create') setPage('create');
      else if (page === 'error') setPage('error');
      else {
        if (!modal) {
          setCompareFail('');
          setReport('');
          setCurrCompareResult('');
          getReportsLists();
        }
        if (modal === 'viewing' && report.data && report.data.reportId) {
          getAReport(report.data.reportId, 'viewing');
        }
        if (modal === 'compare' && report.data && report.data.reportId) {
          let theWorkingState = { ...comparativeState };
          if (isEmpty(theWorkingState.currLocalCompareResult)) {
            getCompareReport(report.data.facilityId, report.data, 'comparing').then((res) => {
              theWorkingState = res;
              setComparativeState(doCompareWork(theWorkingState));
            });
          } else setComparativeState(doCompareWork(theWorkingState));
        }
        setPage('');
      }
    } catch (err) {
      console.log('some problems', err);
      setPage('error');
    }
    return;
  }, [
    page,
    modal,
    report,
    currCompareResult,
    consultantFilter,
    comparativeState.currentSamplePoint,
    quarterFilter,
    facilityFilter,
    reportTypeFilter,
    periodFilter,
    statusFilter,
    eiaFilter,
    avgEnv,
  ]);

  const createReport = () => {
    setPage('create');
    props.history.push('/reports/create');
  };

  const { userCategory, userPermission } = props;
  const userCat = userCategory.trim().toLowerCase();

  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  const isConsultant = userCat === 'consultant';
  const isNotConsultant = userCat !== 'consultant';
  const isEDM = userCat === 'edm';

  const canViewReport =
    userCat === 'edm' ||
    userCat === 'regulator' ||
    (userCat === 'consultant' && userPerm.includes('view_comparative_report'));
  const canViewComparativeReport = userCat === 'regulator' || userCat === 'edm';

  // if (page === 'create') return <CreateNewReport />;
  if ((page === 'loading' && !reports) || page === 'loading' || (!reports && page !== 'error'))
    return (
      <div className="spin-fullpage">
        <LoadingContent pageLoading={true} />
      </div>
    );

  const handleOk = () => setModal('');
  const handleCancel = (which) => {
    switch (which) {
      case 'viewing':
        setModal('');
        setReport('');
        break;
      case 'comparing':
        setModal('');
        // setCompareFail('');
        setComparativeState(initialComparativeState);
        setReport('');
        // setCurrCompareResult('');
        break;
      case 'reporting':
        break;
      default:
        setModal('');
        break;
    }
  };

  function reading(reportId, data) {
    return function() {
      setPage('loading');
      if (data) {
        if (data.samplePointId !== activeSamplePoint.data.samplePointId)
          getAndSetSamplePointRecord(data.samplePointId, reportId);
        setPage('');
      } else {
        setPage('Loading');
      }
    };
  }

  function rowContent(spt, index, reportId) {
    if (!spt) return <LoadingContent modal={true} />;

    let times = 0;
    let start = index;
    const content = [];
    while (times < 4 && times < spt.length - index) {
      let hoverable = false;
      if (spt[start] && activeSamplePoint && activeSamplePoint.data)
        hoverable = spt[start].samplePointId !== activeSamplePoint.data.samplePointId;

      const newCol = (
        <Col key={spt[start].samplePointId}>
          <Card
            hoverable={hoverable}
            className={`facility-card__body ${hoverable ? 'nocursor opacity-7-10' : ''}`}
            style={{
              backgroundColor: generateRadomColor(),
              marginBottom: '24px',
              // marginLeft: '-0.8rem',
              // marginRight: '0.8rem',
            }}
            onClick={reading(reportId, spt[start])}
          >
            <div className="card-content">
              <div className="qtr-icon">
                <img
                  src={spt[start].icon ? spt[start].icon : WindPowerIcon}
                  className="calendar-6"
                  alt="calendar"
                />
              </div>
              <div className="qtr-subtext">{spt[start].samplePointName}</div>
              <div className="qtr-text">{`(${
                spt[start].groupName
                  ? spt[start].groupName
                  : spt[start].measurementName
                  ? spt[start].measurementName
                  : 'Sample Point Type'
              })`}</div>
            </div>
          </Card>
        </Col>
      );

      content.push(newCol);

      start++;
      times++;
    }
    return content;
  }

  const changeAvgEnv = (e) => {
    setAvgEnv(e.target.value);
  };

  function panelContent(index, records) {
    // const { overallAverage, overallAverage_QAQC } = records.data.data;
    const content = [];
    const { compareLocalParameters, currLocalCompareResult, avgLocalCompareResult } = records;

    if (!compareLocalParameters || compareLocalParameters.length === 0)
      return (
        <Col span={22} key={`${index}-${index}-0`}>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
            style={{ marginTop: '0.3rem' }}
            type="flex"
            justify="end"
            align="middle"
          >
            {/* <CloseIcon onClick={() => handleCancel('comparing')} /> */}
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
            style={{ marginTop: '0.3rem', textAlign: 'left' }}
            type="flex"
            justify="start"
            align="middle"
          >
            <LoadingContent biggerModal={true} />
          </Row>
        </Col>
      );

    const newCol = (
      <Row
        key={`${index}-${index}-0`}
        gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
        type="flex"
        justify="space-between"
        align="middle"
        style={{ flexWrap: 'nowrap' }}
      >
        <Col span={23} className="comparison-table">
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
            style={{ marginTop: '0.24rem', textAlign: 'left' }}
            type="flex"
            justify="start"
            align="middle"
          >
            <ReportCompareTable
              data={currLocalCompareResult}
              setData={() => {}}
              parameters={compareLocalParameters}
              published={true}
              loading={!currLocalCompareResult}
              type="facilityView"
              avgEnv={avgEnv}
              avg={{
                qaqcAvg: avgLocalCompareResult ? avgLocalCompareResult[0][avgEnv] : null,
                conAvg: avgLocalCompareResult ? avgLocalCompareResult[0][avgEnv] : null,
              }}
            />
            {/* <ReportCompareTable
              data={currCompareResult}
              setData={() => {}}
              parameters={parameters}
              published={true}
              loading={!currCompareResult}
              avg={{ qaqcAvg: avgCompareData[0][avgEnv], conAvg: avgCompareData[1][avgEnv] }}
            /> */}
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
            style={{
              marginTop: '0.4rem',
              textAlign: 'left',
            }}
            type="flex"
            justify="space-between"
            align="middle"
          >
            <Col style={{ alignSelf: 'start', paddingLeft: '0' }}>
              {/* <span className="qaqc-underneath">QA/QC Report</span> */}
              {/* <span className="cons-underneath">Consultant's Report</span> */}
            </Col>
            <Col style={{ alignSelf: 'end', paddingRight: '0' }}>
              <Row span={1} className="switch-average-type">
                <Col>
                  <p style={{ overflow: 'ellipsis' }}>Benchmark with</p>
                </Col>
                <Radio.Group options={avgRadioOptions} onChange={changeAvgEnv} value={avgEnv} />
              </Row>
              {false && (
                <>
                  <span className="average-underneath-b">
                    <TrendDown /> Below Average
                  </span>

                  <span className="average-underneath-a">
                    <TrendUp />
                    Above Average
                  </span>
                </>
              )}
            </Col>
          </Row>
        </Col>
        {/* <Col span={1} className="switch-average-type">
          <p style={{ overflow: 'ellipsis' }}>Benchmark with</p>
          <Radio.Group options={avgRadioOptions} onChange={changeAvgEnv} value={avgEnv} />
        </Col> */}
      </Row>
    );

    content.push(newCol);

    return content;
  }

  function renderViewingModal(records) {
    if (!records.data || !records.data.data)
      return (
        <>
          <CloseIcon
            className="close-icon report-viewing"
            onClick={() => handleCancel('viewing')}
            style={{
              marginBottom: '1rem',
            }}
          />
          <LoadingContent biggerModal={true} />
        </>
      );

    const { samplePoints } = records.data.data || [];
    const { facility } = records.data.data || '';
    const { entryDate } = records.data.data || '';
    const { reportTitle } = records.data.data || '';
    const { reportId } = records.data.data || '';
    return (
      <>
        <div className="facility-report__body onviewing-modal">
          {records && records.data && records.data.data && (
            // <div className="arrow-back" onClick={() => handleCancel('locking')} />
            <CloseIcon
              className="close-icon report-viewing"
              onClick={() => handleCancel('viewing')}
            />
          )}
          {!records && records.data && records.data.data && <Spin />}
          {records && records.data && records.data.data && (
            <div className="onviewing-modal-body">
              <Row
                gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
                type="flex"
                justify="start"
                align="middle"
              >
                <Col span={24}>
                  <Row
                    type="flex"
                    justify="start"
                    className="text-left"
                    style={{ marginBottom: '25px', marginTop: '30px' }}
                  >
                    <Col
                      span={22}
                      className="reporting-form__heading"
                      style={{
                        overflowX: 'unset',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <h1 className="heading mb-5">
                        {`${numeralize(reportTitle)} Quarter Report`}
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    type="flex"
                    justify="start"
                    className="text-left"
                    style={{ marginBottom: '8px' }}
                  >
                    <Col
                      span={6}
                      className="reporting-form__subheading"
                      style={{ overflowX: 'unset', textOverflow: 'ellipsis' }}
                    >
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px' }}
                      >
                        <p className="subheading">Facility</p>
                      </Row>
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left reporting-form__subtext"
                      >
                        <Text
                          type="primary"
                          style={{
                            overflowX: 'unset',
                            wordBreak: 'break-word',
                            textOverflow: 'ellipsis',
                            fontWeight: 'bold',
                            fontSize: 'bold',
                          }}
                        >
                          {facility ? properCase(facility) : 'Not Available'}
                        </Text>
                      </Row>
                    </Col>
                    <Col
                      span={6}
                      className="reporting-form__subheading"
                      style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px' }}
                      >
                        <p className="subheading">Report Date & Time</p>
                      </Row>
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left reporting-form__subheading"
                      >
                        <Text
                          type="primary"
                          style={{
                            overflowX: 'unset',
                            wordBreak: 'break-word',
                            textOverflow: 'ellipsis',
                            fontSize: 'bold',
                            fontWeight: 'bold',
                          }}
                        >
                          {/* {entryDate
                            ? formatDate(new Date(entryDate), 'dd LLL yyyy, h aaaa')
                            : '00:00'} */}
                            {entryDate
                            ? formatDate(new Date(entryDate), 'dd LLL yyyy')
                            : '00:00'}
                        </Text>
                      </Row>
                    </Col>
                  </Row>

                  <Row type="flex" justify="start" className="text-left">
                    <Carousel className="viewing-carousel">
                      {samplePoints &&
                        samplePoints.length > 0 &&
                        samplePoints.map((spt, index) => {
                          if (index % 4 === 0) {
                            return (
                              <div key={`${index}${spt.samplePointId}`}>
                                <Row
                                  gutter={16}
                                  style={{
                                    marginTop: '1.2rem',
                                    overflowX: 'unset',
                                  }}
                                >
                                  {rowContent(samplePoints, index, reportId)}
                                </Row>
                              </div>
                            );
                          }
                        })}
                    </Carousel>
                  </Row>

                  <Row
                    type="flex"
                    justify="start"
                    className="text-left"
                    style={{ marginBottom: '0.8rem' }}
                  >
                    <Col span={24} className={`reporting-form__heading published`}>
                      <FacilityUploadTable
                        tableType="report-view"
                        userCategory={userCategory}
                        data={currSamplePointReport}
                        setData={() => {}}
                        loading={!currSamplePointReport}
                        parameters={parameters}
                        published={true}
                        type="reports viewing"
                      />
                    </Col>
                  </Row>
                  {/* <Row
                    type="flex"
                    justify="start"
                    className="text-left"
                    style={{ marginBottom: '0.8rem' }}
                  >
                    <Button type="secondary" onClick={() => handleCancel('viewing')}>
                      Close
                    </Button>
                  </Row> */}
                </Col>
              </Row>
            </div>
          )}
        </div>
      </>
    );
  }

  function changeCurrentSamplePoint(sptId, records) {
    const { samplePointsLists } = records;
    if (samplePointsLists && samplePointsLists.length > 0) {
      const matchingSpt = samplePointsLists.filter((bgSpt) => bgSpt.samplePointId === sptId);
      if (matchingSpt && matchingSpt.length > 0) {
        // setCurrLocalSamplePoint(matchingSpt[0]);
        const newComparativeState = updateState(matchingSpt[0], 'currentSamplePoint', records);
        setComparativeState(newComparativeState);
      }
    }
  }

  function nextSptCompare(spts, index, records) {
    if (index !== 0 && !index) return;
    const theSptId = spts[index].samplePointId;
    changeCurrentSamplePoint(theSptId, records);
  }

  function compareFailModal(status) {
    const noQaqc = status && status.toLowerCase() === 'no qaqc';
    return (
      <Result
        status="warning"
        className="facility-compare-result"
        title={'No comparative report yet!'}
        subTitle={
          noQaqc
            ? `No qaqc report has been submitted for this facility. Please check back later.`
            : 'No consultant report has been submitted for this facility. Please check back later.'
        }
        extra={
          <>
            <Button
              type="primary"
              appearance="default"
              key="console"
              onClick={() => handleCancel('comparing')}
            >
              Close
            </Button>
          </>
        }
      />
    );
  }

  // const biggestSamplePoint = getBiggestSamplePoint(comparativeState.samplePoints);
  // let biggestSamplePointArray = [];
  // if (biggestSamplePoint && Object.keys(biggestSamplePoint))
  //   biggestSamplePointArray = biggestSamplePoint[Object.keys(biggestSamplePoint)[0]];

  // console.log('biggestttt', biggestSamplePoint);

  function renderCompareModal(records) {
    if (isEmpty(records))
      return (
        <>
          <CloseIcon
            className="close-icon report-viewing"
            onClick={() => handleCancel('comparing')}
            style={{ marginBottom: '1rem' }}
          />
          <LoadingContent biggerModal={true} />
        </>
      );

    const {
      compareLocalParameters,
      currentSamplePoint,
      samplePointsLists,
      facilityDetails,
      results,
    } = records;
    const { facility } = facilityDetails || '';
    const { facilityPersonels } = facilityDetails || [];
    const { entryDate } = facilityDetails || '';
    const { reportStatus = 'Draft' } = facilityDetails || '';

    const consultant = isEmpty(facilityPersonels) ? 'N|A' : facilityPersonels[0].fullName;

    if (isEmpty(compareLocalParameters))
      return (
        <>
          <CloseIcon
            className="close-icon report-viewing"
            onClick={() => handleCancel('comparing')}
            style={{ marginBottom: '1rem' }}
          />
          <LoadingContent biggerModal={true} />
        </>
      );

    if (isEmpty(results)) return compareFailModal('No qaqc');
    // if (results && results.length > 0 && results.some((rst) => !rst))
    //   return compareFailModal('No qaqc');
    // if (!currCompareResult && !samplePoints && samplePoints.length <= 0)
    //   return <LoadingContent modal={true} />;

    if (isEmpty(currentSamplePoint))
      return (
        (
          <>
            <CloseIcon
              className="close-icon report-comparing"
              onClick={() => handleCancel('comparing')}
              style={{ marginBottom: '1rem' }}
            />
            <LoadingContent biggerModal={true} />
          </>
        ) && doAndSetCompareWork(records)
      );
    return (
      <>
        <div className="facility-report__body onviewing-modal">
          {/* <div className="arrow-back" onClick={() => handleCancel('comparing')} /> */}
          <CloseIcon
            className="close-icon report-comparing"
            onClick={() => handleCancel('comparing')}
          />
          {true && (
            <div className="oncompare-modal-body">
              <Row
                gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
                type="flex"
                justify="start"
                align="middle"
              >
                <Col span={24}>
                  <Row
                    type="flex"
                    justify="start"
                    className="text-left"
                    style={{ marginBottom: '25px', marginTop: '30px' }}
                  >
                    <Col
                      span={24}
                      className="reporting-form__heading"
                      style={{
                        overflowX: 'unset',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <h1 className="heading mb-5">Comparative Report</h1>
                    </Col>
                  </Row>
                  <Row
                    type="flex"
                    justify="start"
                    className="text-left"
                    style={{ marginBottom: '43px' }}
                  >
                    <Col
                      span={6}
                      className="reporting-form__subheading"
                      style={{ overflowX: 'unset', textOverflow: 'ellipsis' }}
                    >
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px' }}
                      >
                        <p className="subheading">Facility</p>
                      </Row>
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px', fontWeight: 'bold' }}
                      >
                        <Text
                          type="primary"
                          style={{
                            overflowX: 'unset',
                            wordBreak: 'break-word',
                            textOverflow: 'ellipsis',
                            fontWeight: 'bold',
                          }}
                        >
                          {facility ? facility : 'Not Available'}
                        </Text>
                      </Row>
                    </Col>
                    <Col
                      span={6}
                      className="reporting-form__subheading"
                      style={{ overflowX: 'unset', textOverflow: 'ellipsis' }}
                    >
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px' }}
                      >
                        <p className="subheading">Report Consultant</p>
                      </Row>
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px', fontWeight: 'bold' }}
                      >
                        <Text
                          type="primary"
                          style={{
                            overflowX: 'unset',
                            wordBreak: 'break-word',
                            textOverflow: 'ellipsis',
                            fontWeight: 'bold',
                          }}
                        >
                          {consultant ? consultant : 'Not Available'}
                        </Text>
                      </Row>
                    </Col>
                    <Col
                      span={6}
                      className="reporting-form__subheading"
                      style={{ overflowX: 'unset', textOverflow: 'ellipsis' }}
                    >
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px' }}
                      >
                        {false && <p className="subheading">Report Date & Time</p>}
                      </Row>
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px', fontWeight: 'bold' }}
                      >
                        {false && (
                          <Text
                            type="primary"
                            style={{
                              overflowX: 'unset',
                              wordBreak: 'break-word',
                              textOverflow: 'ellipsis',
                              fontWeight: 'bold',
                            }}
                          >
                            {entryDate
                              ? formatDate(new Date(entryDate), 'dd LLL yyyy, h aaaa')
                              : '00:00'}
                          </Text>
                        )}
                      </Row>
                    </Col>
                    <Col
                      span={6}
                      className="reporting-form__subheading compare-logo-col"
                      style={{ overflowX: 'unset', textOverflow: 'ellipsis' }}
                    >
                      <Row
                        type="flex"
                        justify="start"
                        className="text-left"
                        style={{ marginBottom: '8px' }}
                      >
                        {/* <div className="compare-status">
                        <div className="compare-status__mark1" />
                        {'Re-check'}
                        <div className="compare-status__mark2" />
                      </div> */}
                        <div className="compare-logo">
                          <div className={`sq sq-letter ${reportStatus.toLowerCase()}`}>
                            <p>Re-Check Next Quarter</p>
                            {/* <p>{properCase(reportStatus)}</p> */}
                          </div>
                          <div className="sq sq-one" />
                          <div className="sq sq-two" />
                          <div className="sq sq-three" />
                          <div className="sq sq-four" />
                          <div className="sq sq-five" />
                          <div className="sq sq-six" />
                          <div className="sq sq-seven" />
                          <div className="sq sq-eight" />
                          <div className="sq sq-nine" />
                          <div className="sq sq-ten" />
                          <div className="sq sq-one-2" />
                          <div className="sq sq-two-2" />
                          <div className="sq sq-three-2" />
                          <div className="sq sq-four-2" />
                          <div className="sq sq-five-2" />
                          <div className="sq sq-six-2" />
                          <div className="sq sq-seven-2" />
                          <div className="sq sq-eight-2" />
                          <div className="sq sq-nine-2" />
                          <div className="sq sq-ten-2" />
                        </div>
                      </Row>
                    </Col>
                  </Row>

                  <Collapse
                    accordion
                    bordered={false}
                    defaultActiveKey={['0']}
                    onChange={(index) => nextSptCompare(samplePointsLists, index, records)}
                    className="compare-accordion"
                    expandIconPosition="right"
                  >
                    {samplePointsLists &&
                      samplePointsLists.length > 0 &&
                      samplePointsLists.map((spt, index) => (
                        <Panel
                          header={
                            <span>
                              {spt.samplePointName}{' '}
                              <p
                                style={{
                                  display: 'inline',
                                  fontWeight: 'normal',
                                  textOverflow: 'ellipsis',
                                }}
                              >{`(${spt.measurementName})`}</p>
                            </span>
                          }
                          key={`${index}`}
                        >
                          {panelContent(index, records)}
                        </Panel>
                      ))}
                  </Collapse>
                </Col>
              </Row>
              {/* <Row
                type="flex"
                justify="start"
                className="text-left"
                style={{ marginBottom: '0.8rem' }}
              >
                <Button type="secondary" onClick={() => handleCancel('comparing')}>
                  Close
                </Button>
              </Row> */}
            </div>
          )}
        </div>
      </>
    );
  }

  function refreshPage() {
    window.location.reload();
  }

  function searchTable(args) {
    const max = 1000000;
    if (args) {
      setIsSearching(true);
      setSearch(true);
      getReportsLists(`?search=${args}&offset=0&limit=${max}`);
      // setIsSearching(false);
    } else {
      setIsSearching(true);
      getReportsLists();
      // setSearch(false);
    }
  }

  const filterOnChange = (val, which) => {
    switch (which) {
      case 'facilityOperators':
        setConsultantFilter(String(val).trim());
        break;
      case 'reportQuarter':
        setQuarterFilter(val);
        break;
      case 'reportStatus':
        setStatusFilter(String(val).trim());
        break;
      case 'facility':
        setFacilityFilter(String(val).trim());
        break;
      case 'reportType':
        setReportTypeFilter(String(val).trim());
        break;
      case 'sectorName':
        setSectorFilter(String(val).trim());
        break;
      case 'stateName':
        setStateFilter(String(val).trim());
        break;
      case 'facilityEia':
        setEiaFilter(String(val).trim());
        break;
      default:
        break;
    }
  };

  const filterOnSearch = (val, which) => {
    switch (which) {
      case 'facilityOperators':
        setConsultantFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'reportQuarter':
        setQuarterFilter(val);
        break;
      case 'facility':
        setFacilityFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'reportType':
        setReportTypeFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'stateName':
        setStateFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'sectorName':
        setSectorFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'reportStatus':
        setStatusFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'facilityEia':
        setEiaFilter(String(val).trim());
        break;
      default:
        break;
    }
  };

  const getFullName = (facilityOps) => {
    // Nothing to do with this really
    return facilityOps;
  };

  const toggleFilter = () => setFiltering(!filtering);

  const displayOptions = (list, which) => {
    let key = list;
    let { [key]: name } = { reports };

    if (name && name.length > 0) {
      let uniques;
      if (which === 'facilityOperators')
        uniques = [
          ...new Set(
            name.map((item) =>
              item[which]
                ? properCase(`${item[which][0].firstName} ${item[which][0].lastName}`)
                : 'Not Available'
            )
          ),
        ];
      else uniques = [...new Set(name.map((item) => item[which]))];
      uniques.sort((a, b) => {
        if (typeof a !== 'object') return a > b ? 1 : b > a ? -1 : 0;
        return a[which].localeCompare(b[which]);
      });

      return uniques.map((item, index) => {
        if (which === 'facilityEia') {
          return (
            <Option value={item} key={index}>
              {String(item)
                .trim()
                .toLowerCase() === 'yes'
                ? 'ACTIVE'
                : 'INACTIVE'}
            </Option>
          );
        }
        return (
          <Option value={item} key={index}>
            {which === 'reportQuarter'
              ? `${datelize(item)} Quarter`
              : which === 'facilityOperators'
              ? getFullName(item)
              : item}
          </Option>
        );
      });
    }
  };

  const filterObjs = [
    {
      label: 'Filter by report status',
      name: 'reportStatus',
      placeholder: 'Filter by report status',
      title: 'All Report Status',
      condition: isEDM,
      className: '',
    },
    {
      label: 'Filter by report type',
      name: 'reportType',
      placeholder: 'Filter by report type',
      title: 'All Report Types',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by report quarters',
      name: 'reportQuarter',
      placeholder: 'Filter by quarters',
      title: 'All Quarters',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by sectors',
      name: 'sectorName',
      placeholder: 'Filter by sectors',
      title: 'All Sectors',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by states',
      name: 'stateName',
      placeholder: 'Filter by states',
      title: 'All States',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by facility',
      name: 'facility',
      placeholder: 'Filter by facility',
      title: 'All Facilities',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by consultant',
      name: 'facilityOperators',
      placeholder: 'Filter by consultant',
      title: 'All Consultants',
      condition: isNotConsultant,
      className: '',
    },
    {
      label: 'Filter by EIA Status',
      name: 'facilityEia',
      placeholder: 'Filter by EIA status',
      title: 'All EIA Status',
      condition: false,
      // condition: isNotConsultant,
      className: '',
    },
  ];

  const filterContent = (
    <Form
      className={`filtering-search`}
      {...formItemLayout}
      layout="horizontal"
      ref={filterFormRef}
    >
      <Row>
        {filterObjs.map((fltO) => {
          return fltO.condition ? (
            <Col span={10} key={fltO.name}>
              <Form.Item
                label={fltO.label}
                name={fltO.name}
                className={fltO.className}
                style={{ marginBottom: '10px', display: 'inline-block' }}
              >
                <Select
                  showSearch
                  className="select-dropdown reports"
                  style={{ width: '12.8em' }}
                  placeholder={fltO.placeholder}
                  optionFilterProp="children"
                  onChange={(value) => filterOnChange(value, `${fltO.name}`)}
                  onSearch={(value) => filterOnSearch(value, `${fltO.name}`)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="">{`${fltO.title}`}</Option>
                  {displayOptions('reports', `${fltO.name}`)}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ''
          );
        })}
      </Row>
    </Form>
  );

  const filteringStates = [
    consultantFilter,
    quarterFilter,
    facilityFilter,
    reportTypeFilter,
    statusFilter,
    sectorFilter,
    stateFilter,
    eiaFilter,
  ];

  const filteringActions = [
    setConsultantFilter,
    setQuarterFilter,
    setFacilityFilter,
    setReportTypeFilter,
    setStatusFilter,
    setSectorFilter,
    setStateFilter,
    setEiaFilter,
  ];

  const isItFiltering = () => {
    return filteringStates.every((ftState) => {
      return Boolean(ftState) === false;
    });
  };

  const clearFilter = () => {
    filterFormRef.current.resetFields();
    return filteringStates.forEach((ftState, index) => ftState && filteringActions[index](''));
  };

  function handleMenuClick(e) {
    // const cmoment = moment().format('YYYY-MM-DD');
    const prevYear = new Date().getFullYear() - 1;
    let slected;
    let val;
    switch (parseInt(e.key)) {
      case 1:
        slected = 'yeartodate';
        val = [moment().subtract(1000, 'years'), moment().endOf('day')];
        setPeriodText('Year-to-Date');
        break;
      case 2:
        slected = 'lastyear';
        val = [moment([prevYear]).startOf('year'), moment([prevYear]).endOf('year')];
        // val = [moment().subtract(1, 'years'), moment().startOf('year').subtract(1, 'days')];
        setPeriodText('Last Year');
        break;
      case 3:
        slected = 'last6months';
        val = [moment().subtract(6, 'months'), moment().endOf('day')];
        setPeriodText('Last 6 Months');
        break;
      case 4:
        slected = 'cutomdata';
        val = [moment().startOf('month'), moment().endOf('month')];
        setPeriodText('Custom Date');

        break;
      default:
        break;
    }

    if (parseInt(e.key) !== 4) setPeriodFilter(val);
  }

  function onChangeRangePicker(dates, dateStrings) {
    // console.log('From: ', dates[0], ', to: ', dates[1]);
    // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);

    let val = [moment().subtract(1000, 'years'), moment().endOf('day')];
    if (dates && dates[0] && dates[1]) val = [dates[0], dates[1]];
    setPeriodFilter(val);
  }

  // const onOpenChange = (openKeys) => setOpenKeys([...OPEN_KEYS, ...openKeys]);

  const intelligentMenu = (
    // <Menu onClick={handleMenuClick} openKeys={openKeys} onOpenChange={onOpenChange}>
    // <Menu onClick={handleMenuClick} openKeys={['sub1', 'sub2']}>
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Year-to-Date</Menu.Item>
      <Menu.Item key="2">Last Year</Menu.Item>
      <Menu.Item key="3">Last 6 Months</Menu.Item>
      <Menu.Item key="4">Custom Date</Menu.Item>
      {/* <SubMenu title="Custom Date" key="sub1">
        <Menu.Item key="sub2">
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            onChange={onChangeo}
          />
        </Menu.Item>
      </SubMenu> */}
    </Menu>
  );

  return (
    <>
      <PageHeader split={true} className="facility-header__main reports-head">
        {false && (
          <div className="buttons left" style={{ display: 'none' }}>
            <Button type="primary" appearance="blue" size="small" onClick={createReport}>
              <PlusIcon /> New Report
            </Button>
          </div>
        )}
        {(reports.length > 0 || search) && page !== 'error' && (
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
            type="flex"
            align="start"
            justify="start"
            style={{ width: '100%', justifyContent: 'space-between' }}
          >
            <Col>
              <Dropdown.Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // console.log('welcome baby');
                }}
                // visible={true}
                // onVisibleChange={(e) => console.log('this select drop menu ', e)}
                overlay={intelligentMenu}
              >
                {periodText}
              </Dropdown.Button>
              {periodText === 'Custom Date' && (
                <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  style={{ marginLeft: '2rem' }}
                  onChange={onChangeRangePicker}
                />
              )}
            </Col>
            <Col style={{ paddingLeft: 'unset', paddingRight: 'unset !important' }}>
              <Filter
                onClick={toggleFilter}
                content={filterContent}
                isFiltering={!isItFiltering()}
                clearFilter={clearFilter}
              />
              <SearchInput
                placeholder="Search reports"
                searchTable={searchTable}
                className="ml-1rem"
              />
            </Col>
          </Row>
        )}
      </PageHeader>

      <PageContent>
        {page === 'error' && page !== 'loading' && (
          <>
            <Row type="flex" justify="center" align="middle" className="text-center">
              <Col
                xs={{
                  span: 16,
                }}
                lg={{
                  span: 12,
                }}
              >
                <ReportsIcon className="icon" />
              </Col>
            </Row>

            <div>
              <Row type="flex" justify="center" align="middle" className="text-center">
                <Col
                  xs={{
                    span: 12,
                  }}
                  lg={{
                    span: 8,
                  }}
                >
                  <EmptyContent className="no-content">
                    <h1 className="no-content-header">Something's wrong!</h1>
                    <p className="no-content-text">We are having issues loading reports</p>
                    <div className="buttons">
                      <Button type="secondary" onClick={refreshPage}>
                        TRY AGAIN
                      </Button>
                    </div>
                  </EmptyContent>
                </Col>
              </Row>
            </div>
          </>
        )}

        {(page !== 'loading' && !reports && page !== 'error' && !search) ||
          (page !== 'error' && !search && reports.length < 1 && (
            <>
              <Row type="flex" justify="center" align="middle" className="text-center">
                <Col
                  xs={{
                    span: 16,
                  }}
                  lg={{
                    span: 12,
                  }}
                >
                  <ReportsIcon className="icon" />
                </Col>
              </Row>

              <div>
                <Row type="flex" justify="center" align="middle" className="text-center">
                  <Col
                    xs={{
                      span: 12,
                    }}
                    lg={{
                      span: 8,
                    }}
                  >
                    <EmptyContent className="no-content">
                      <h1 className="no-content-header">No reports submitted yet!</h1>
                      <p className="no-content-text">
                        No reports have been generated on the IES platform.
                      </p>
                      {false && (
                        <div className="buttons" style={{ display: 'none' }}>
                          <Button appearance="blue" size="small" onClick={createReport}>
                            <PlusIcon /> New Report
                          </Button>
                        </div>
                      )}
                    </EmptyContent>
                  </Col>
                </Row>
              </div>
            </>
          ))}
        {page !== 'loading' && page !== 'error' && reports && (reports.length > 0 || search) && (
          <div className="reports-table">
            <ReportsTable
              userCategory={userCategory}
              userPerm={userPerm}
              data={reports}
              setReport={setReport}
              setModal={setModal}
              loading={isSearching}
              consultantFilter={consultantFilter}
              facilityFilter={facilityFilter}
              quarterFilter={quarterFilter}
              reportTypeFilter={reportTypeFilter}
              periodFilter={periodFilter}
              statusFilter={statusFilter}
              stateFilter={stateFilter}
              sectorFilter={sectorFilter}
              eiaFilter={eiaFilter}
            />
          </div>
        )}
        {page !== 'loading' && page !== 'error' && modal === 'compare' && canViewComparativeReport && (
          <Modal
            visible={!!report.data}
            footer={null}
            onOk={handleOk}
            onCancel={() => handleCancel('comparing')}
            className={`facility-report__standard reporting ${!compareFail ? 'comparing' : ''}`}
          >
            {renderCompareModal(comparativeState)}
          </Modal>
        )}
        {page !== 'loading' && page !== 'error' && modal === 'viewing' && canViewReport && (
          <Modal
            visible={!!report.data}
            footer={null}
            onOk={handleOk}
            onCancel={() => handleCancel('viewing')}
            className="facility-report__standard reporting viewing"
          >
            {renderViewingModal(report)}
          </Modal>
        )}
      </PageContent>
    </>
  );
}
