import React, { useEffect, useState } from 'react';
import { Row, Col, Popover } from 'antd';
import LoadingContent from 'components/common/LoadingContent';
import Button from 'components/common/Button';
import { getUserRoles } from 'helpers/api';
import { loadState } from 'store/localStorage';
import { openNotification } from 'helpers/notification';
import { InfoCircleFilled, EditOutlined } from '@ant-design/icons';
import PopoverTitle from './PopoverTitle';
import './Roles.css';
import EditRole from './EditRole/EditRole';

const RoleList = ({ className }) => {
  const [AccountId, setAccountId] = useState('');
  const [Roles, setRoles] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [RolePrivileges, setRolePrivileges] = useState([]);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [deleteRoleModal, setDeleteRoleModal] = useState(false);
  const [selectedRoleId, setselectedRoleId] = useState('');

  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [selectedRoleDescription, setselectedRoleDescription] = useState('');
  const [selectedRolePrivileges, setselectedRolePrivileges] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    getAccountId();
  }, [AccountId]);

  useEffect(() => {
    refreshRoleList();
  }, []);

  useEffect(() => {
    filterRoles();
  });

  // FILTERS ROLES PRIVILEGES
  const getRolePermissions = (ev) => {
    ev.stopPropagation();
    const roleId = ev.currentTarget.dataset.roleid;

    let permissions = Roles.filter((role) => role.roleId === roleId);
    setRolePrivileges(permissions[0].privileges);
  };

  // ADDS EVENT LISTENER TO ACTION BUTTON THAT DISPLAY POPUP
  const filterRoles = () => {
    const actionBtn = document.querySelectorAll('.role-action');
    for (let i = 0; i < actionBtn.length; i++) {
      actionBtn[i].addEventListener('mouseover', getRolePermissions);
    }
  };

  // GET ACCOUNTID FOR MAKING ROLES REQUEST
  const getAccountId = () => {
    const accountId = loadState().auth.result.data.accountId;
    setAccountId(accountId);
  };

  // FETCH CREATED ROLES
  const refreshRoleList = () => {
    getUserRoles(AccountId)
      .then((res) => {
        if (res.result.status.code === '00') {
          setRoles(res.result.data);
          setLoading(true);
        }
      })
      .catch((error) => {
        setLoading(true);
        return openNotification({
          type: 'error',
          title: 'Something went wrong, could not get roles',
          message: `${error}`,
        });
      });
  };

  // CONTENT FOR ROLES POPUP
  const getContent = () => {
    if (RolePrivileges.length > 0) {
      return RolePrivileges.map((permission) => {
        return (
          <div className="content-container" key={permission.id}>
            <div>
              <div className="box">
                <strong className="dark">{permission.subDivision.toLowerCase()}</strong>
                <span className="light">{permission.description}</span>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="content-container">
          <b>No Permissions for this role</b>
        </div>
      );
    }
  };

  const openEditModal = (ev) => {
    ev.stopPropagation();
    const roleId = ev.currentTarget.dataset.roleid;

    setselectedRoleId(roleId);

    setEditRoleModal(true);
    if (Roles !== null) {
      if (Roles.length > 0) {
        const roleData = Roles.filter((role) => role.roleId === roleId)[0];
        if (roleData) {
          console.log(roleData.userCategory, 'caty');
          setSelectedRoleName(roleData.roleName);
          setselectedRoleDescription(roleData.roleDescription);
          setselectedRolePrivileges(roleData.privileges);
          setSelectedCategory(roleData.userCategory);
        }
      } else {
        return null;
      }
    }
    return null;
  };

  const closeEditModal = () => {
    setEditRoleModal(false);
  };

  const displayRoles = () => {
    if (Roles === null) {
      return null;
    } else {
      if (Roles.length > 0) {
        return Roles.map((role) => {
          return (
            <div className="role-list" key={role.roleId}>
              <Row>
                <Col span={8}>
                  <h4 className="role-name">{role.roleName}</h4>
                </Col>
                <Col span={10}>
                  <p className="role-description">{role.roleDescription}</p>
                </Col>

                <Col lg={{ span: 4 }} className="actions__column" key={role.roleId}>
                  <Popover
                    content={getContent()}
                    className="role__popover"
                    title={
                      <PopoverTitle
                        roleName={role.roleName}
                        key={role.roleId}
                        description={role.roleDescription}
                      />
                    }
                  >
                    <InfoCircleFilled
                      className="role-action"
                      data-roleid={role.roleId}
                      style={{
                        color: '#0c5bab',
                        background: '#fff',
                        fontSize: '1.1rem',
                      }}
                    />
                  </Popover>
                  <Button
                    type="secondary"
                    className="transparent__bg edit__role-btn"
                    title="Edit"
                    id={role.roleId}
                    onClick={openEditModal}
                    data-roleid={role.roleId}
                  >
                    <EditOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          );
        });
      } else {
        return <h3>No Role Created Yet!</h3>;
      }
    }
  };

  return (
    <div className="roles__container">
      {editRoleModal && (
        <EditRole
          open={editRoleModal}
          close={closeEditModal}
          roleId={selectedRoleId}
          roleName={selectedRoleName}
          desc={selectedRoleDescription}
          privileges={selectedRolePrivileges}
          category={selectedCategory}
        />
      )}

      {!Loading ? (
        <div className="loader-container">
          <LoadingContent pageLoading={true} />
        </div>
      ) : (
        <>
          {/* <Col span={18}> */}
          {displayRoles()}
          {/* </Col> */}
        </>
      )}
    </div>
  );
};

export default RoleList;
