import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Spin } from 'antd';
import Button from '../../common/Button';
import { resetPassword } from '../../../helpers/api';
import { withRouter } from 'react-router';
import history from '../../common/History';
import { openNotification } from '../../../helpers/notification';
import ConfirmationModal from './ConfirmationModal';
import './reset.css';

const ResetForm = withRouter(({ history, className }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [Loading, setLoading] = useState(false);
  const [resetId, setResetId] = useState('');
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    // GET USERNAME
    const resetId = window.location.hash.split('=')[1];
    setResetId(resetId);
    console.log(resetId);
  }, []);

  // ALPHANUMERIC PASSWORD VALIDATION
  const isAlphaNumeric = (value) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const test = re.test(value);
    console.log(test);
    return test;
  };

  // CHECK PASSWORD LENGTH && IF PASSWORD IS ALPHANUMERIC
  const validatePassword = () => {
    if (password.length > 6 && isAlphaNumeric(password)) {
      return true;
    } else {
      setErrorMessage(
        'Password cannot be shorter than 6 characters and should include at least one number and one capital and small letter'
      );
      return false;
    }
  };

  // CHECK PASSWORDS MATCH
  const matchPasswords = (password, password2) => {
    if (password === password2) {
      return true;
    } else {
      setErrorMessage('passwords do not match');
      return false;
    }
  };

  const closeModal = () => {
    setvisible(false);
    history.push('/signin');
  };

  // SET NEW PASSWORD
  const handleSubmit = (ev) => {
    ev.preventDefault();
    setLoading(true);
    setErrorMessage('');

    if (validatePassword(password) && matchPasswords(password, ConfirmPassword)) {
      const payload = {
        resetId: resetId,
        password: password,
      };
      resetPassword(payload)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setvisible(true);
        })
        .catch((error) => {
          if (error === undefined) {
            setLoading(false);
            return openNotification({
              type: 'error',
              title: 'Error updating password',
              message: `Password Already Exists`,
            });
          } else {
            console.log(error);
            setLoading(false);
            return openNotification({
              type: 'error',
              title: 'Error updating  password',
              message: `${error}`,
            });
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <form className="reset__password-form" onSubmit={handleSubmit}>
      <ConfirmationModal open={visible} close={closeModal} />
      <div className="form-header">
        <h1>New Password</h1>
        <p>Enter your new password</p>
      </div>
      <div className="form-group">
        <p className="error-text">{errorMessage}</p>
        <label htmlFor="">New Password *</label>
        <Input.Password
          type="password"
          onChange={({ target: { value } }) => setPassword(value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="">Confirm Password *</label>
        <Input.Password
          type="password"
          onChange={({ target: { value } }) => setConfirmPassword(value)}
          required
        />
      </div>
      <div className="form-group text-center">
        <Button size="large" appearance="blue" disabled={Loading}>
          {Loading && <Spin />} &nbsp;Update Password
        </Button>
      </div>
    </form>
  );
});

export default ResetForm;
