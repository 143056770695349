import React, { useState, useEffect } from 'react';
import { addUserAssignedAreas, deleteUserAssignedAreas } from '../../../../helpers/api';
import Select from '../../../common/Select';
import { Form, Spin, Row, Col, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './UpdateUser.css';

const { Option } = Select;

const UpdateUserForm = (props) => {
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [selectedLGA, setSelectedLGA] = useState([]);

  const [loading, setLoading] = useState(false);
  const [userAssignedAreas, setUserAssignedAreas] = useState(null);
  const [showAddMoreInput, setShowAddMoreInput] = useState(false);
  const [showDeleteAreas, setShowDeleteAreas] = useState(false);

  const [initialZonePermit, setInitialZonePermit] = useState([]);
  const [initialStatePermit, setInitialStatePermit] = useState([]);
  const [initialLgaPermit, setInitialLgaPermit] = useState([]);

  useEffect(() => {
    getUserAssignedAreas();
  }, [props.userData]);

  const getUserAssignedAreas = () => {
    setUserAssignedAreas(props.userData.userAreaPermissions);
    const { zonePermission, statePermission, lgaPermission } = props.userData.userAreaPermissions;
    setInitialStatePermit(statePermission);
    setInitialZonePermit(zonePermission);
    setInitialLgaPermit(lgaPermission);
  };
  const handleSelectArea = (value) => {
    setSelectedArea(value);
  };
  const handleSelectedZones = (value) => {
    setSelectedZone(value);
  };

  const handleSelectedLg = (value) => {
    setSelectedLGA(value);
  };
  const handleSelectedStates = (value) => {
    setSelectedStates(value);
  };

  const handleShowAddContent = () => {
    setShowDeleteAreas(false);
    setShowAddMoreInput(true);
  };

  const handleDeleteContent = () => {
    setShowAddMoreInput(false);
    setShowDeleteAreas(true);
  };

  // DISPLAY ZONES IN SELECT
  const displayZones = () => {
    return (
      <Form.Item
        name="zoneId"
        label="Select Zone"
        rules={[{ required: true, message: 'please select a zone' }]}
      >
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select a zone"
          onChange={handleSelectedZones}
        >
          {props.zoneList.map((zone) => {
            return (
              <Option value={zone.zoneId} key={zone.zoneId} className="">
                {zone.zoneName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };

  // DISPLAY LGA IN SELECT
  const displayLGA = () => {
    return (
      <Form.Item
        name="lgaId"
        label="Select Local Government"
        rules={[{ required: true, message: 'please select a local govt' }]}
      >
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select a local govt"
          onChange={handleSelectedLg}
        >
          {props.lgaList.map((lga) => {
            return (
              <Option value={lga.lgaId} key={lga.lgaId} className="">
                {lga.lgaName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };
  // DISPLAY STATES IN SELECT
  const displayStates = () => {
    return (
      <Form.Item
        name="stateId"
        label="Select State"
        rules={[{ required: true, message: 'please select a state' }]}
      >
        <Select mode="multiple" placeholder="Please select a state" onChange={handleSelectedStates}>
          {props.stateList.map((state) => {
            return (
              <Option value={state.stateId} key={state.stateId} className="">
                {state.stateName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };
  // DELTE ZONE
  const confirmDeleteZone = (e, id, name) => {
    handleDeleteAreas({ zoneId: [id] }, name);
  };

  // CONFIRM DELETE STATE
  const confirmDeleteState = (e, id, name) => {
    handleDeleteAreas({ stateId: [id] }, name);
  };

  // CONFIRM DELETE lGA
  const confirmDeleteLg = (e, id, name) => {
    handleDeleteAreas({ lgaId: [id] }, name);
  };

  // DISPLAY USER ASSIGNED LGA
  const displayUserAssignedLGA = () => {
    if (userAssignedAreas === null) {
      return null;
    }
    // const { lgaPermission } = userAssignedAreas;
    if (initialLgaPermit.length > 0) {
      return initialLgaPermit.map((lga) => {
        return (
          <Col lg={6}>
            <div className="area__badge">
              <span>{lga.lgaName}</span>
              <Popconfirm
                title="Are you sure delete this Local government area?"
                onConfirm={(e, name) => confirmDeleteLg(e, lga.lgaId, lga.lgaName)}
                okText="Yes"
                cancelText="No"
                id={lga.lgaId}
              >
                <a href="#" id={lga.lgaId}>
                  <DeleteOutlined id={lga.lgaId} />
                </a>
              </Popconfirm>
            </div>
          </Col>
        );
      });
    }
  };

  // DISPLAY ASSIGNED STATES
  const displayUserAssignedState = () => {
    if (userAssignedAreas === null) {
      return null;
    }
    // const { statePermission } = userAssignedAreas;
    if (initialStatePermit.length > 0) {
      return initialStatePermit.map((state) => {
        return (
          <Col lg={6} key={state.stateId} className="user__area">
            <div className="area__badge">
              <span>{state.stateName}</span>

              <Popconfirm
                title="Are you sure delete this state?"
                onConfirm={(e, name) => confirmDeleteState(e, state.stateId, state.stateName)}
                okText="Yes"
                cancelText="No"
                id={state.stateId}
              >
                <a href="#" id={state.stateId}>
                  <DeleteOutlined id={state.stateId} />
                </a>
              </Popconfirm>
            </div>
          </Col>
        );
      });
    }
  };

  // DISPLAYS ASSIGNED ZONES
  const displayAssignedZones = () => {
    if (userAssignedAreas === null) {
      return null;
    } else {
      // const { zonePermission } = userAssignedAreas;
      if (initialZonePermit.length > 0) {
        return initialZonePermit.map((zone) => {
          return (
            <Col lg={7} key={zone.zoneId} className="user__area">
              <div className="area__badge">
                {zone.zoneName}

                <Popconfirm
                  title="Are you sure delete this zone?"
                  onConfirm={(e, name) => confirmDeleteZone(e, zone.zoneId, zone.zoneName)}
                  okText="Yes"
                  cancelText="No"
                  id={zone.zoneId}
                >
                  <a href="#" id={zone.zoneId}>
                    <DeleteOutlined id={zone.zoneId} />
                  </a>
                </Popconfirm>
                {/* <button id={zone.zoneId} type="button" className="delete__area" /> */}
              </div>
            </Col>
          );
        });
      }
    }
  };

  //   HANDLES USER UPDATES TO ADD MORE AREAS
  const handleUpdateUser = (values) => {
    setLoading(true);
    if (selectedStates.length === 0 && selectedZone.length === 0 && selectedLGA.length === 0) {
      message.error('No Changes has been made');
      setLoading(false);
    }
    // else if (selectedStates.length > 0 && selectedZone.length > 0 && selectedLGA.length > 0) {
    //   message.error('please add either state, zone or lga');
    // }
    else {
      const payload = {
        zoneId: selectedZone,
        stateId: selectedStates,
        lgaId: selectedLGA,
      };

      addUserAssignedAreas(props.userId, payload)
        .then((res) => {
          setLoading(false);
          if (res !== null) {
            message.success(`Area permissions to ${props.name.toUpperCase()} successfully!`);
            props.reload(true);
            // getUserAssignedAreas();
            setSelectedLGA([]);
            setSelectedStates([]);
            setSelectedLGA([]);
          } else {
            message.error('Update failed');
          }
        })
        .catch((error) => {
          message.error(error);
          setLoading(false);
        });
    }
  };

  const handleDeleteAreas = ({ lgaId = [], stateId = [], zoneId = [] }, areaName) => {
    setLoading(true);
    const payload = {
      zoneId,
      stateId,
      lgaId,
    };
    if (zoneId.length === 0 && stateId.length === 0 && lgaId === 0) {
      message.error('please select an area to delete');
      setLoading(false);
    } else {
      deleteUserAssignedAreas(props.userId, payload)
        .then((res) => {
          if (res !== null) {
            setLoading(false);
            message.success(`${areaName} has been removed successfully`);
            props.reload(true);
            // getUserAssignedAreas();
          } else {
            setLoading(false);
            message.error('Something went wrong could not delete areas');
          }
        })
        .catch((error) => {
          message.error(error);
          setLoading(false);
        });
    }
  };
  return (
    <Form layout="vertical" className="update__user-form" onFinish={handleUpdateUser}>
      <div className="delete__areas__container">
        <Row className="area__container">
          <Col lg={22}>
            <h3 className="area__title">
              {userAssignedAreas !== null && userAssignedAreas.zonePermission.length > 0
                ? 'Zones '
                : null}
            </h3>
            <Row>{displayAssignedZones()}</Row>
          </Col>
          <Col lg={2} />
          <Col lg={22}>
            <h3 className="area__title">
              {userAssignedAreas !== null && userAssignedAreas.statePermission.length > 0
                ? ' States '
                : null}
            </h3>
            <Row>{displayUserAssignedState()}</Row>
          </Col>
          <Col lg={2} />

          <Col lg={22}>
            <h3 className="area__title">
              {userAssignedAreas !== null && userAssignedAreas.lgaPermission.length > 0
                ? 'Local Government Areas'
                : null}
            </h3>
            <Row>{displayUserAssignedLGA()}</Row>
          </Col>
          <Col lg={2} />
          <Form.Item>
            {/* <button
                type="button"
                className="save__btn"
                disabled={loading}
                
              >
                Save
              </button>
              <button
                type="button"
                className="cancel__btn"
                disabled={loading}
                onClick={props.closeUpdateModal}
              >
                Cancel
              </button> */}
          </Form.Item>
        </Row>
      </div>

      {showAddMoreInput && (
        <div className="add__more__container">
          <div className="form-group" name="area">
            <label htmlFor="">Select an area</label>
            <Select placeholder="select an area" onChange={handleSelectArea}>
              <Option value="zone">ZONE</Option>
              <Option value="state">STATE</Option>
              <Option value="lga">LGA</Option>
            </Select>
          </div>

          {selectedArea === 'zone'
            ? displayZones()
            : selectedArea === 'state'
            ? displayStates()
            : selectedArea === 'lga'
            ? displayLGA()
            : null}
          <Form.Item>
            <button type="submit" className="save__btn" disabled={loading}>
              {loading && <Spin />} &nbsp; Add Areas
            </button>
            <button
              type="button"
              className="cancel__btn"
              disabled={loading}
              onClick={(e) => setShowAddMoreInput(false)}
            >
              Cancel
            </button>
          </Form.Item>
        </div>
      )}
      <Row justify="end">
        <Col lg={6}>
          {!showAddMoreInput && (
            <button type="button" className="add__btn" onClick={handleShowAddContent}>
              <i className="fa fa-plus" style={{ marginRight: '5px' }} />
              Add More
            </button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default UpdateUserForm;
