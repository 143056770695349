import React, { useState } from 'react';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import { Form, message } from 'antd';
import { createSector } from '../../../../../helpers/api';
import './style.css';

const AddSectorForm = ({ reloadSector, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const handleCreateSector = (values) => {
    setLoading(true);
    const payload = {
      sectorName: values.sectorName.toLowerCase(),
    };
    console.log(payload);
    createSector(payload)
      .then((res) => {
        message.success(`New Sector ${values.sectorName.toUpperCase()} created successfully`);
        setLoading(false);
        reloadSector();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        message.error(`Something went wrong, ${error}`);
        setLoading(false);
      });
  };
  return (
    <Form layout="vertical" onFinish={handleCreateSector} hideRequiredMark={true}>
      <Form.Item
        label="Sector Name"
        name="sectorName"
        rules={[{ required: true, message: 'Please enter a sector name' }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          appearance="default"
          className="mr-10"
          loading={loading}
        >
          Add
        </Button>
        <Button htmlType="button" type="secondary" onClick={closeModal} loading={loading}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddSectorForm;
