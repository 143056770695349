import React, { useState } from 'react';
import styled from 'styled-components';
import { CloseSquareFilled, CloseSquareOutlined } from '@ant-design/icons';

import '../../scrollfix.css';

const closeIcon = ({ onClick, className, style = {} }) => (
  <CloseSquareOutlined onClick={onClick} className={className} style={style} />
);

export default styled(closeIcon)`
  float: right;
  width: fit-content;
  svg {
    transform: scale(1.5);
    &:hover {
      background-color: red;
      background: red;
      fill: white;
    }
  }
  &:hover {
    color: 'red';
  }
`;
