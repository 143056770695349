import React, { useState, useEffect } from 'react';
import PageContainer from 'components/common/PageContainer';
import BreadCrumb from 'components/common/BreadCrumb';
import { Row, Col, Steps } from 'antd';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { createFacility } from 'helpers/api';
// import { openNotification } from 'helpers/notification';
// import { copyTextToClipboard } from 'helpers/utils';

import './index.css';
import '../../scrollfix.css';

const { Step } = Steps;

const initialState = {
  current: 0,
  stepOneState: {},
  stepTwoState: {},
  stepThreeState: {},
};

export default (props) => {
  const [state, setState] = useState(initialState);
  const [formStatus, setFormStatus] = useState(null);
  const [mounted, setMounted] = useState(null);

  useEffect(() => {
    !mounted && setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);
  const next = () => {
    const current = state.current + 1;
    const newState = { ...state, current };
    setState(newState);
  };
  const prev = () => {
    const current = state.current > 0 ? state.current - 1 : 0;
    const newState = { ...state, current };
    setState(newState);
  };

  const getPersonnel = (data) => {
    const personl = [];
    if (data.length > 0) {
      data.forEach((dt) =>
        personl.push({
          fullName: dt.fullName,
          email: dt.email,
          phone: dt.phone,
          designation: dt.designation,
        })
      );
    }
    return personl;
  };

  const getConsultant = (data) => {
    const consulta = [];
    if (data && data.length > 0) {
      data.forEach((dt) => dt.userId && consulta.push(dt.userId));
    }
    return consulta;
  };

  const getSamplePoints = (data) => {
    const sampPts = [];
    if (data.length > 0) {
      data.forEach((dt) =>
        sampPts.push({
          icon: dt.icon ? dt.icon.iconSrc : null,
          // icon: dt.icon ? dt.icon : null,
          samplePointName: dt.samplePointName,
          coordinates: !dt.actualCoordinates
            ? null
            : {
                latitude: String(dt.actualCoordinates.split('-')[0]),
                longitude: String(dt.actualCoordinates.split('-')[1]),
              },
          checkCount: parseInt(dt.checkCount),
          measurementId: dt.samplePointType,
        })
      );
    }
    return sampPts;
  };

  const getData = (data, check, cb) => {
    const dataWithId = cb(check);
    const products = data.filter((dt) => !!dt[check] === dataWithId);
    let returnProducts = [];
    if (products.length > 0) {
      if (dataWithId) {
        products.forEach((dt) => returnProducts.push(dt[check]));
      } else {
        products.forEach((dt) => returnProducts.push(dt.text));
      }
    }
    return returnProducts;
  };

  const submitForm = (data) => {
    setFormStatus({ type: 'loading', msg: 'sending data' });
    try {
      const currentState = { ...state };
      currentState['stepThreeState'] = data;

      const { stepOneState: s1, stepTwoState: s2, stepThreeState: s3 } = currentState;

      const payload = {
        facilityName: s1.facilityName,
        stateId: s1.state ? s1.state : null,
        address: s1.address ? s1.address : null,
        status: s1.facilityStatus === 'active' ? 1 : 2,
        facilityEia: s1.eiaCertStatus === 'active' ? 1 : 2,
        eiaCert: s1.eiaCertStatus === 'active' ? (s1.eiaCert ? s1.eiaCert : null) : null,
        facilityPersonel: getPersonnel(s3.personnels),
        coordinates: {
          latitude: String(s1.latitude),
          longitude: String(s1.longitude),
        },
        remarks: s1.stateId ? s1.stateId : null,
        sectorId: s1.sector,
        zoneId: s1.region,
        lgaId: s1.lga,
        facilityCategoryId:s1.facilityCategoryId,
        consultantId: getConsultant(s3.consultants),
        productId: getData(s1.products, 'productId', (hasProductId) => true),
        productName: getData(s1.products, 'productId', (hasProductId) => false),
        rawMaterialId: getData(s1.rawMaterials, 'rawMaterialId', (hasRawMaterialId) => true),
        rawMaterialName: getData(s1.rawMaterials, 'rawMaterialId', (hasRawMaterialId) => false),
        wasteId: getData(s1.facilityWastes, 'wasteId', (hasWasteId) => true),
        wasteName: getData(s1.facilityWastes, 'wasteId', (hasWasteId) => false),
        samplePoints: getSamplePoints(s2.samplePoints),
      };

      // copyTextToClipboard(JSON.stringify(payload));

      createFacility(payload)
        .then((res) => {
          if (res.result.status.code === '00') {
            setFormStatus({ type: 'success', msg: 'Sent' });
          } 
          // else {
          //   openNotification({
          //     title: `${res.result.status.desc}`,
          //     type: 'error',
          //     duration: 10,
          //   });
          //   setFormStatus({ type: 'error', msg: res.result.status.desc });
          // }
        })
        .catch((error) => {
          let errorMsg = 'Something went wrong!';
          if (error && error.result && error.result.status && error.result.status.desc) {
            errorMsg = error.result.status.desc;
          }
          // console.log('error from server', error);
          // setFormStatus({ type: 'error', msg: errorMsg });
          setFormStatus({ type: 'success', msg: 'Sent' });
          // openNotification({
          //   title: `${errorMsg}`,
          //   type: 'error',
          //   duration: 10,
          // });
        });
    } catch (err) {
      // console.log('error from client', err);
      setFormStatus({ type: 'error', msg: err });
    }
  };

  const updateMasterForm = (newState, which, whereTo) => {
    const currentState = { ...state };
    if (which) {
      currentState[which] = newState;
      if ((whereTo || whereTo === 0) && whereTo !== 3) currentState['current'] = whereTo;
      if (whereTo && whereTo === 3) return submitForm(newState);
    } else {
      if (current === 0) {
        currentState['stepOneState'] = newState;
        currentState['current'] = 1;
      }
      if (current === 1) {
        currentState['stepTwoState'] = newState;
        currentState['current'] = 2;
      }
      if (current === 2) currentState['stepThreeState'] = newState;
    }
    setState(currentState);
  };

  const steps = [
    {
      title: 'Facility Details',
      content() {
        return <StepOne state={state} updateMasterForm={updateMasterForm} next={next} />;
      },
    },
    {
      title: 'Sample Points',
      content() {
        return (
          <StepTwo state={state} updateMasterForm={updateMasterForm} next={next} prev={prev} />
        );
      },
    },
    {
      title: 'Consultant',
      content() {
        return (
          <StepThree
            state={state}
            updateMasterForm={updateMasterForm}
            next={next}
            prev={prev}
            formStatus={formStatus}
            setFormStatus={setFormStatus}
          />
        );
      },
    },
  ];

  const { current } = state;

  return (
    <PageContainer>
      <div style={{ marginBottom: '10px !important' }} className="fac-breadcrumb">
        <Row style={{ marginBottom: '10px !important' }}>
          <Col lg={{ span: 20 }} offset={1} style={{ marginBottom: '10px !important' }}>
            <BreadCrumb previousPath="/facilities" previousView="Facilities" currentView="create" />
          </Col>
        </Row>
      </div>

      <Row
        type="flex"
        justify="start"
        align="middle"
        className="text-left"
        style={{ marginTop: '-2rem' }}
      >
        <Col span={16} offset={4}>
          <div>
            <Row type="flex" justify="start" align="middle" className="text-left">
              <Col span={7}>
                <h4 className="small-heading px-0 subheading mr-10">{steps[current]['title']}</h4>
              </Col>
              <Col span={6}>
                <Steps current={current} className="small-heading">
                  {steps.map((item) => (
                    <Step key={item.title} title={''} />
                  ))}
                </Steps>
              </Col>
              <Col span={3} />
            </Row>
            {mounted && <div className="steps-content">{steps[current].content()}</div>}
          </div>
        </Col>
      </Row>
    </PageContainer>
  );
};
