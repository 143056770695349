import React from 'react';
import PageContainer from 'components/common/PageContainer';
import Layout from 'components/common/Layout';
import NotFound from 'components/screens/NotFound';

export default (props) => {
  return (
    <Layout headerTitle="Page Not Found">
      <PageContainer id="notfound">
        <NotFound props={props} />
      </PageContainer>
    </Layout>
  );
};
