import React from 'react';
import PropTypes from 'prop-types';

class InfoBox extends React.Component {
  constructor(props) {
    super(props);

    this.lockFocusInSection = this.lockFocusInSection.bind(this);
  }

  // takes raw fetched data from parent component and turns it into render-ready form
  processSptData(data) {
    let contentObject = {
      title: '',
      imgSrc: '',
      link: '',
      status: '',
    };

    if (!data) {
      contentObject.title = 'Data is being fetched at the moment.';
      contentObject.status = 'fetching';
    } else if (data[0].hasOwnProperty('samplePointId')) {
      contentObject.title = data[0]['samplePointName'];
      contentObject.imgSrc = data[0]['icon'];
      contentObject.link = data[0]['measurementType'];
      contentObject.status = data[0]['status'] || 'Active';
    } else {
      contentObject.title = 'An unknown error occured';
      contentObject.status = 'unkown_error';
    }

    return contentObject;
  }

  lockFocusInSection(e) {
    let focusableElements = [];
    document.querySelectorAll('.focusable-element').forEach((li) => {
      focusableElements.push(li);
    });

    let firstElement = focusableElements[0];

    if (e.key === 'Tab') {
      if (!focusableElements.includes(e.target)) {
        // second if parameter checks if focus need to switched to InfoBox
        firstElement.focus();
      }
    }
  }

  componentDidUpdate() {
    // lets parent to decide to render this component or not via props
    this.refs.dialog.open = JSON.parse(this.props.isDialogOpen);

    if (this.refs.dialog.open) {
      document.addEventListener('keyup', this.lockFocusInSection);
    } else {
      document.removeEventListener('keyup', this.lockFocusInSection);
    }
  }

  componentDidMount() {
    // lets pressing the escape key to close the infoBox
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.props.closeDialog();
      }
    });
  }

  render() {
    let contentObject = this.processSptData(this.props.spt);
    console.log('info box props', this.props);

    // if the proper data is available, renders the relevant data
    return (
      <dialog aria-label="Info Box" className="info-box" ref="dialog">
        <button
          aria-label="close info box"
          className="close-box focusable-element"
          onClick={this.props.closeDialog}
        >
          X
        </button>
        <h1>{contentObject.title}</h1>
        <div className="img-container">
          <img className="pub-img" src={contentObject.imgSrc} alt={contentObject.title} />
        </div>
        <br />
        <a
          className="focusable-element"
          role="button"
          aria-label="Go to FourSquare Page"
          href={contentObject.link}
        >
          See Details
        </a>
        <br />
        <p className="hint">Click on X or press "escape" to close this info box</p>
      </dialog>
    );
  }
}

InfoBox.propTypes = {
  isDialogOpen: PropTypes.string,
  closeDialog: PropTypes.func.isRequired,
  spt: PropTypes.any,
  sptData: PropTypes.any,
  qtr: PropTypes.string,
};

export default InfoBox;
