import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const SideBarLink = styled.li`
  // margin-bottom: 1.4rem;
  margin-bottom: 12px;
  color: #fff !important;
  font-size: 16px;
  padding-top: var(--sidebar-min-padding-h);
  padding-bottom: var(--sidebar-min-padding-h);

  ${({ active }) => active && 'font-weight: 700;'};
  list-style: none;
  padding-left: var(--sidebar-min-padding-w);
  padding-right: var(--sidebar-min-padding-w);
  ${({ active }) =>
    active &&
    `
		background: rgba(0, 0, 0, 0.1);
		border-top-right-radius: 50px;
		// width: 188px;
		border-bottom-right-radius: 50px;
  `}
  &:hover {
    // font-weight: 600;
    background: rgba(0, 0, 0, 0.1);
    border-top-right-radius: 50px;
    // width: 188px;
    transition: 0.2s ease-in-out;
    border-bottom-right-radius: 50px;
  }
  @media screen and (max-width: 769px) {
    list-style: none;
    ${({ expand, active }) =>
      !expand &&
      active &&
      `
			background: rgba(0, 0, 0, 0.1);
      // padding: var(--sidebar-min-padding);
        // padding: var(--sidebar-min-padding);
			border-top-right-radius: 50px;
			width: var(--sidebar-min-w);
			transition: 0.2s ease-in-out;
			border-bottom-right-radius: 50px;
		`}
  }
  a {
    display: flex;
    align-items: center;
  }
  svg {
    // margin-right: 13.6px;
    mix-blend-mode: ${({ active }) => (!active ? 'luminosity' : 'lighten')};
  }
  span {
    margin-left: 13.6px;
    display: inline-block;
  }
`;

export default withRouter(({ location: { pathname }, url, children, logout, sideNavExpand }) => {
  let urL = url;

  const itIsPathNameByExtension = (pathN = pathname, urL = url) => {
    if (pathN && pathN !== '' && pathN !== '/') {
      if (
        pathN &&
        url &&
        url !== '' &&
        url !== '/' &&
        typeof pathN === 'string' &&
        pathN.includes(urL)
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <SideBarLink
      active={pathname === urL || itIsPathNameByExtension()}
      onClick={logout}
      expand={sideNavExpand}
    >
      <Link to={url || '#'}>{children}</Link>
    </SideBarLink>
  );
});
