import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Form, message } from 'antd';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import Select from 'components/common/Select';
import LocationInput from 'components/common/LocationInput';
import {
  properCase,
  optionalCheckLatitude as checkLatitude,
  optionalCheckLongitude as checkLongitude,
  findItemInListOfObjects,
} from 'helpers/utils';

import { updateLGA } from 'helpers/api';
import './lga.css';

const { Option } = Select;

const EditLgaForm = ({
  name: lgaName,
  lgId: lgaId,
  coordinates = {},
  stateId,
  stateName,
  stateList,
  closeEditModal,
  reloadLGA,
}) => {
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(coordinates.latitude);
  const [longitude, setLongitude] = useState(coordinates.longitude);

  const formRef = useRef();

  useEffect(() => {
    defaultValues();
  }, []);

  const defaultValues = () => {
    // formRef.current.setFieldsValue({ lgaName: name });
  };

  const editLga = (values) => {
    setLoading(true);
    const payload = {
      lgaId,
      lgaName: values.lgaName,
      stateId: values.stateId,
      coordinates: {
        latitude: values.latitude
          ? values.latitude.loc
            ? values.latitude.loc
            : values.latitude
          : 0,
        longitude: values.longitude
          ? values.longitude.loc
            ? values.longitude.loc
            : values.longitude
          : 0,
      },
    };
    updateLGA(payload)
      .then((res) => {
        setLoading(false);
        if (res === null) {
          message.error('Local Government already exists');
        } else {
          message.success('Local government has been updated successfully!');
          reloadLGA();
          closeEditModal();
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  const getActualItem = (
    list,
    item,
    property,
    type,
    alternative,
    alternativeProperty,
    alternativeType
  ) => {
    let itemF = '';
    let foundItem = findItemInListOfObjects(list, item, property, type);
    if (!foundItem) {
      foundItem = findItemInListOfObjects(list, alternative, alternativeProperty, alternativeType);
    }
    if (foundItem && foundItem[property]) itemF = foundItem[property];
    return itemF;
  };

  const initValues = {
    lgaName,
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
    stateId: getActualItem(stateList, stateId, 'stateId', 'string', stateName, 'name', 'string'),
  };

  return (
    <Form
      layout="vertical"
      onFinish={editLga}
      ref={formRef}
      initialValues={initValues}
      className="edit__Lg-form"
    >
      {/* <Form.Item
        name="stateId"
        label="Select a state"
        rules={[{ required: true, message: 'please select a state' }]}
      >
        <Select showSearch optionFilterProp="children" placeholder={stateName}>
          {stateList.map((states) => {
            return <Option value={states.stateId}>{states.stateName}</Option>;
          })}
        </Select>
      </Form.Item> */}

      <Row style={{ marginBottom: '10px' }}>
        <Col span={24}>
          <Form.Item
            label="State Name"
            name="stateId"
            rules={[
              {
                required: true,
                message: 'Please select a state',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a state"
              className="add__more__select form-controlxx"
            >
              {stateList &&
                stateList.length &&
                stateList.map((states) => {
                  return (
                    <Option key={states.stateId} value={states.stateId}>
                      {states.stateName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ marginBottom: '10px' }}>
        <Col span={24}>
          <Form.Item
            name="lgaName"
            label="Local Govt Name"
            rules={[
              {
                required: true,
                message: 'Please enter local govt name',
              },
            ]}
          >
            <Input placeholder="Enter local govt name here" className="form-controlx" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="lgaChord" label="LGA Co-ordinates (Optional)">
        <Row style={{ marginBottom: '10px' }}>
          <Col span={12}>
            <Form.Item name="latitude" rules={[{ validator: checkLatitude }]}>
              <LocationInput
                type="latitude"
                placeholder="Enter Latitude"
                location={latitude}
                setLocation={setLatitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
          <span
            style={{
              display: 'inline-block',
              width: '0px',
              lineHeight: '32px',
              textAlign: 'center',
            }}
          />
          <Col span={12} style={{ textAlign: 'right' }}>
            <Form.Item
              name="longitude"
              rules={[{ validator: checkLongitude }]}
              style={{ display: 'inline-block', width: 'calc(100% - 20px)' }}
            >
              <LocationInput
                type="longitude"
                placeholder="Enter Longitude"
                location={longitude}
                setLocation={setLongitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Row>
        <Col span={24}>
          <Form.Item className="mt-20 lg-btn">
            <Button
              htmlType="submit"
              type="primary"
              appearance="default"
              className="mr-10"
              loading={loading}
              disable={loading}
            >
              Update
            </Button>
            <Button type="secondary" htmlType="button" onClick={closeEditModal} disable={loading}>
              Cancel
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditLgaForm;
