import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CaretDownFilled } from '@ant-design/icons/lib/icons';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
// import Avatar from 'assets/img/user-avatar.png';
import { UserOutlined } from '@ant-design/icons';
import { AppContext } from 'store/AppContext';
import { UserContext } from 'store/UserContext';

const NavUser = styled.div`
  background: transparent;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .name {
    text-transform: capitalize;
    font-family: var(--font-family);
    color: var(--dark);
    font-size: 14px;
  }
  .ant-dropdown-menu-item {
    font-size: 14px !important;
  }
`;

export default ({ className }) => {
  const {
    state: { auth },
  } = useContext(AppContext);
  const { dispatch } = useContext(AppContext);
  const { userData, getUserData } = useContext(UserContext);
  // const { firstname, lastname, userId } = auth.result.data;

  useEffect(() => {
    getUserData();
  }, []);

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/settings/profile">Profile</Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link to="/settings">Settings</Link>
      </Menu.Item> */}
      <Menu.Item>
        <Link to="/" onClick={logout}>
          Log Out
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <NavUser className={className}>
      <Dropdown overlay={menu} trigger={['hover', 'click']}>
        <a
          className="ant-dropdown-link"
          href="/#"
          onClick={(e) => e.preventDefault()}
          // style={{ marginTop: '-1rem' }}
        >
          <UserOutlined style={{ fontSize: '14px', marginRight: '5px' }} />
          {userData && userData !== null ? (
            <span className="name">
              {userData.firstname} {userData.lastname}
            </span>
          ) : null}

          <CaretDownFilled
            style={{
              fontSize: '14px',
              paddingLeft: '5px',
              color: '#66788a',
              verticalAlign: 'middle',
            }}
          />
        </a>
      </Dropdown>
    </NavUser>
  );
};
