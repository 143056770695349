import React from 'react';
import styled from 'styled-components';
import Button from '../../common/Button';
import { Modal, Row, Col } from 'antd';
import CheckIcon from '../../../assets/img/checkmark.svg';
import './reset.css';

const ConfirmationModal = ({ className, open, close }) => {
  return (
    <Modal className="reset__modal" visible={open} footer={null}>
      <div>
        <Row>
          <Col span={24}>
            <img src={CheckIcon} alt="" />
          </Col>
          <Col span={24}>
            <h3>Password Changed Successfully!</h3>
          </Col>

          <div className="footer">
            <Button appearance="blue" size="small" onClick={close}>
              OK
            </Button>
          </div>
        </Row>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
