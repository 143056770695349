import React from 'react';
import styled from 'styled-components';
import CloseIcon from 'components/common/CloseIcon';
import './EditRole.css';

const ModalHeader = ({ className, closeModal }) => {
  return (
    <div className="edit__role__header">
      <span>
        <CloseIcon onClick={closeModal} />
      </span>
      <h3 className="heading mb-10">Edit Role</h3>
      <p className="subheading text-gray">Roles define what permissions are granted to accounts</p>
    </div>
  );
};

export default ModalHeader;
