import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Button from 'components/common/Button';
import { Row, Col, Form } from 'antd';
import Input from 'components/common/AntInput';
import { changePassword } from 'helpers/api';
import { openNotification } from 'helpers/notification';

const ChangePasswordForm = ({ className }) => {
  const [Loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const changePassRef = useRef();

  /*
   * function isAlphaNumeric
   * checks New Password entry
   * returns true if input is AlphaNumeric
   */
  const isAlphaNumeric = (value) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const test = re.test(value);
    return test;
  };

  const invalidPasswMsg =
    'Password cannot be shorter than 6 characters and should include at least one number and one capital and small letter';

  /*
   * function validatePassword
   * return true
   * if password is alpha numeric and not less than 6
   */
  const validatePassword = (NewPassword = '') => {
    if (NewPassword.length > 6 && isAlphaNumeric(NewPassword)) {
      return true;
    }
    return false;
  };
  // const validatePassword = () => {
  //   if (NewPassword.length > 6 && isAlphaNumeric(NewPassword)) {
  //     return true;
  //   } else {
  //     setErrorMessage(
  //       'Password cannot be shorter than 6 characters and should include at least one number and one capital and small letter'
  //     );
  //     return false;
  //   }
  // };

  const saveResetPassword = (record) => {
    setLoading(true);
    const data = {
      oldPwd: record.currentpasword,
      newPwd: record.newpassword,
    };
    changePassword(data)
      .then((res) => {
        setLoading(false);
        changePassRef.current.resetFields();
        return openNotification({
          type: 'success',
          title: 'Password changed successfully!',
        });
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Unable to change password',
          message: error && error.message ? error.message : '',
        });
      });
  };

  return (
    <div className={className}>
      <Form
        ref={changePassRef}
        layout="vertical"
        form={form}
        scrollToFirstError
        onFinish={saveResetPassword}
      >
        <div className="form-group">
          <Row gutter={12}>
            <Col
              md={{ span: 24 }}
              lg={{
                span: 12,
              }}
            >
              <Form.Item
                name="currentpasword"
                label="Current Password"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.resolve();
                      }
                      if (!value) return Promise.reject('This field is required!');
                    },
                  }),
                ]}
                style={{ display: 'block' }}
              >
                <Input.Password
                  type="currentpassword"
                  className="form-control"
                  placeholder="Enter current password here"
                />
              </Form.Item>
            </Col>
            <Col
              md={{ span: 24 }}
              lg={{
                span: 12,
              }}
            >
              <Form.Item
                name="newpassword"
                label="New Password"
                style={{ display: 'block' }}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (validatePassword(value)) {
                        return Promise.resolve();
                      }
                      if (!value) return Promise.reject('Please provide a new password');
                      return Promise.reject(`${invalidPasswMsg}`);
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  className="form-control"
                  placeholder="Enter new password here"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="form-group">
          <Row />
        </div>
        <div className="form-group">
          <Form.Item className="form-group">
            <Button
              appearance="default"
              type="primary"
              htmlType="submit"
              className="save_btn"
              loading={Loading}
            >
              {Loading ? 'Updating ...' : 'Save Changes'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default styled(ChangePasswordForm)`
  font-family: var(--font-family);
  label {
    font-weight: 600;
    font-size: 14px;
  }
  .form-control {
    // width: 98%;
    padding: 0px 15px;
    border: 1px solid #d8d8d8;
    background: transparent;
    margin: unset !important;
    border-radius: 5px !important;
    margin-bottom: var(--input-mb);
    input {
      height: unset;
    }
    &:focus {
      border: 1px solid #0c5bab;
      outline: none !important;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .ant-input-affix-wrapper > input.ant-input {
    background: transparent !important;
    height: 40px;
  }
  .form-control.ant-input-affix-wrapper {
    max-height: 41px;
  }
  .ant-input-affix-wrapper-focused {
    border: 1px solid #0c5bab;
    outline: none;
    box-shadow: none;
  }
  .ant-input-affix-wrapper > input.ant-input {
    background: transparent;
  }
`;
