import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../common/BreadCrumb';
import { Row, Col } from 'antd';

const LookupContent = ({ className, userCategory }) => {
  console.log(userCategory);
  return (
    <Row justify="center">
      <Col lg={23} className="pl-10">
        <BreadCrumb currentView="Lookups" previousView="Settings" previousPath="/settings" />
      </Col>
      <Col lg={{ span: 20 }} className={className}>
        <Row justify="space-between">
          <Col sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 5 }} className="lookup-card purple">
            <Link to="/lookup/settings/zone">
              Zone <br /> Management
            </Link>
          </Col>
          <Col md={{ span: 11 }} lg={{ span: 5 }} className="lookup-card blue">
            <Link className="" to="/lookup/settings/state">
              State <br /> Management
            </Link>
          </Col>
          <Col md={{ span: 11 }} lg={{ span: 5 }} className="lookup-card teal">
            <Link className="" to="/lookup/settings/lga">
              Local Government <br /> Management{' '}
            </Link>
          </Col>
          {/* <Col md={{ span: 11 }} lg={{ span: 5 }} className="lookup-card green">
            <Link className="">
              General Lookup <br /> Management
            </Link>
          </Col> */}
          <Col md={{ span: 11 }} lg={{ span: 5 }} className="lookup-card green">
            <Link className="" to="/lookup/settings/sector">
              Sector <br /> Management
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default styled(LookupContent)`
  padding: 4rem 1.5rem;
  .lookup-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    padding: 1rem;
    text-align: center;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    letter-spacing: -0.3px;
    font-family: var(--font-family);
    @media screen and (max-width: 768px) {
      display: flex;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .lookup-card.purple {
    background: #c49cff;
  }
  .lookup-card.blue {
    background: #0c5bab;
  }
  .lookup-card.teal {
    background: #0072c3;
  }
  .lookup-card.green {
    background: #007d79;
  }
`;
