import React from 'react';
import { Tabs, Breadcrumb } from 'antd';
import './style.css';
import MeasurmentConfig from './Measurements/index';
import NotificationConfig from './Notification';
import PreferencesConfig from './Preferences';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const ConfigurationContent = ({ userCategory }) => {
  const userCat = userCategory.trim().toLowerCase();

  const canViewConfig = userCat === 'edm';
  const canCreateConfig = userCat === 'edm';

  const isEDM = userCat && userCat.toLowerCase().trim() === 'edm';
  const isConsultant = userCat && userCat.toLowerCase().trim() === 'consultant';
  const isRegulator = userCat && userCat.toLowerCase().trim() === 'regulator';

  function callback(key) {
    console.log(key);
  }

  return (
    <div className="config__container">
      <div className="config__content pl-2rem pr-2rem mt-20">
        {isEDM ? (
          <>
            <div className="breadcrumb__container">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/settings">Settings</Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item>Configuration Settings</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <Tabs
              defaultActiveKey="1"
              onChange={callback}
              tabPosition="left"
              className="config__tabs"
            >
              <TabPane tab="Measurement" key="1">
                <MeasurmentConfig />
              </TabPane>

              <TabPane tab="Notifications" key="3">
                <NotificationConfig />
              </TabPane>
              <TabPane tab="Preferences" key="2">
                <PreferencesConfig />
              </TabPane>
            </Tabs>
          </>
        ) : (
          <Tabs
            defaultActiveKey="1"
            onChange={callback}
            tabPosition="left"
            className="config__tabs"
          >
            <TabPane tab="Preferences" key="2">
              <PreferencesConfig />
            </TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default ConfigurationContent;
