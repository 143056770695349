// import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

export default styled(Input)`
  margin: unset !important;
  margin-bottom: var(--input-mb) !important;
  border-radius: 5px !important;
  font-family: var(--font-family);
`;
