import React, { useRef, useEffect, useState } from 'react';
import { Form, message, Spin } from 'antd';
import Input from '../../../../common/AntInput';
import Button from '../../../../common/Button';
import { updateZone } from '../../../../../helpers/api';
import './Zone.css';
import ConfirmLookup from '../ConfirmLookup';

const EditZoneForm = ({ zoneName, closeModal, id, reload }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  useEffect(() => {
    formRef.current.setFieldsValue({ zoneName: zoneName });
  });

  const handleEditZone = (values) => {
    setLoading(true);
    const payload = {
      zoneName: values.zoneName,
      zoneId: id,
    };
    updateZone(payload)
      .then((res) => {
        setLoading(false);
        message.success(`${values.zoneName} zone updated successfully!`);
        reload();
        closeModal();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  return (
    <Form ref={formRef} layout="vertical" className="edit__zone__form" onFinish={handleEditZone}>
      {/* <ConfirmLookup message={confirmMessage} open={confirm} close={closeConfirm} /> */}
      <Form.Item
        label="Zone Name"
        name="zoneName"
        rules={[{ required: true, message: 'please enter zone name' }]}
      >
        <Input placeholder="Enter zone name" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" appearance="blue" disabled={loading}>
          {' '}
          {loading ? <Spin /> : 'Save'}
        </Button>
        <Button type="secondary" className="ml-15" onClick={closeModal} disabled={loading}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditZoneForm;
