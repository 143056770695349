import styled from 'styled-components';

export default styled.div`
  margin-top: 35px;
  font-family: var(--font-family);
  h1 {
    font-size: 25px;
    color: var(--dark);
    font-family: var(--font-family-bold);
  }
  p {
    font-size: 14px;
    line-height: 1.79;
    text-align: center;
    color: #828ea9;
    margin-top: 20px;
  }
  button {
    margin-top: 20px;
    font-family: var(--font-family);
  }
`;
