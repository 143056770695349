import React, { useState } from 'react';
import { Tree, Divider } from 'antd';
import { CarryOutOutlined, MoreOutlined } from '@ant-design/icons';
import compareDesc from 'date-fns/compareDesc';
import { properCase, formatDate } from 'helpers/utils';
import { generateFacilityReport } from 'helpers/api';
import { openNotification, actionInProgress } from 'helpers/notification';
import Table from 'components/common/Table';
import Menu from 'components/common/Menu';
import Popover from 'components/common/Popover';
import Dropdown from 'components/common/Dropdown';
import './index.css';
import '../../scrollfix.css';

let overflowMenu, moreOutlinedIcon;
let whoYou;
let facilityX, sectorX, consultantX, stateX, statusX, eiaStatusX;
// let quarterX;

const getPersonalDeet = (which, facilityPersonel) => {
  if (which !== 'consultant') {
    return facilityPersonel && facilityPersonel.length > 0 ? (
      facilityPersonel.length > 1 ? (
        <>
          {`${properCase(`${facilityPersonel[0].firstName} ${facilityPersonel[0].lastName}`)} & ${
            facilityPersonel.length > 2 ? `${facilityPersonel.length - 1} others` : '1 other'
          }`}
        </>
      ) : (
        properCase(`${facilityPersonel[0].firstName} ${facilityPersonel[0].lastName}`)
      )
    ) : (
      <p className="facility-personnel__notaval">Not Available</p>
    );
  } else {
    return facilityPersonel && facilityPersonel.length > 0 ? (
      facilityPersonel.length > 1 ? (
        <>
          {facilityPersonel[0].fullName}{' '}
          <p className="facility-personnel__others">
            {`& ${
              facilityPersonel.length > 3 ? `${facilityPersonel.length - 1} others` : `1 other`
            } `}
          </p>
        </>
      ) : (
        properCase(facilityPersonel[0].fullName)
      )
    ) : (
      <p className="facility-personnel__notaval">Not Available</p>
    );
  }
};

const getConsultantTitle = () => {
  if (whoYou === 'consultant') return 'Contact Person';
  return 'Consultant';
};

const popMe = (which, data) => {
  let content;
  switch (which) {
    case 'address':
      content = (
        <div>
          <p>{data ? properCase(data) : 'Not Available'}</p>
        </div>
      );
      break;
    case 'samplePoints':
      let treeData =
        data && data.length > 0
          ? data.map((dt, index) => ({
              title: dt.samplePointName,
              key: `${index / 100}-${index / 10}-${index % 10}`,
              icon: <CarryOutOutlined />,
              children: [
                {
                  title: `Sample Point Type: ${
                    dt.samplePointType ? properCase(dt.samplePointType) : ''
                  }`,
                  key: `${index / 1000}-${index / 100}-${index / 10}-1`,
                  icon: <CarryOutOutlined />,
                },
                {
                  title: 'Coordinates',
                  key: `${index / 1000}-${index / 100}-${index / 10}-2`,
                  icon: <CarryOutOutlined />,
                  children: [
                    {
                      title: `lat: ${dt.coordinates ? dt.coordinates.latitude : ''}`,
                      key: index + '-0-1',
                    },
                    {
                      title: `lng: ${dt.coordinates ? dt.coordinates.longitude : ''}`,
                      key: index + '-0-2',
                    },
                  ],
                },
                {
                  title: `Entry Date: ${formatDate(new Date(dt.entryDate), 'LLL do, yyyy p')}`,
                  key: `${index / 1000}-${index / 100}-${index / 10}-3`,
                  icon: <CarryOutOutlined />,
                },
              ],
            }))
          : [];
      content = <Tree showLine={true} showIcon={false} treeData={treeData} />;
      break;

    default:
      break;
  }
  return content;
};

const FacilityTable = ({
  userCategory,
  userPerm,
  data,
  loading = false,
  setRecord,
  setModal,
  setPage,
  facilityFilter,
  consultantFilter,
  statusFilter,
  sectorFilter,
  stateFilter,
  eiaFilter,
  // quarterFilter,
}) => {
  const [row, setRow] = useState('');
  const userCat = userCategory.trim().toLowerCase();

  const canCreateStandardReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  const canCreateAdhocReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  const canLockFacilityReport = userCat === 'edm';
  const canViewFacilityReport =
    userCat === 'edm' || (userCat === 'regulator' && userPerm.includes('view_report'));
  const canUpdateFacility =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('update_facility'));

  whoYou = userCat;

  consultantX = consultantFilter;
  statusX = statusFilter;
  stateX = stateFilter;
  sectorX = sectorFilter;
  // quarterX = quarterFilter;
  facilityX = facilityFilter;
  eiaStatusX = eiaFilter;

  const columns = [
    {
      title: 'Facility Name',
      width: '1.4%',
      dataIndex: 'facilityName',
      key: 'facilityName',
      // fixed: 'left',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.facilityName > b.facilityName ? 1 : -1),
      onFilter: (value, record) => (facilityX ? record.facilityName === facilityX : record),
      filterDropdownVisible: true,
      filteredValue: [facilityX],
    },
    {
      title: 'State',
      width: '0.6%',
      ellipsis: true,
      dataIndex: 'stateName',
      render: (stte) => <span>{stte ? properCase(stte) : 'Not Available'}</span>,
      key: 'stateName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.stateName > b.stateName ? 1 : -1),
      onFilter: (value, record) => (stateX ? record.stateName === stateX : record),
      filterDropdownVisible: true,
      filteredValue: [stateX],
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '1.2%',
      render: (address) => (
        <Popover
          placement="topLeft"
          title={'Address'}
          content={() => popMe('address', address)}
          trigger="hover"
          className="facility-table__popover address__popover"
        >
          <span>{address ? properCase(address) : 'Not Available'}</span>
        </Popover>
      ),
      ellipsis: true,
    },
    {
      title: 'Sample Points',
      dataIndex: 'samplePointCount',
      key: 'samplePointCount',
      width: '1%',
      render: (samplePt, record) => (
        <Popover
          placement="topLeft"
          title={'Sample Points'}
          content={() => popMe('samplePoints', record.samplePoints)}
          trigger="hover"
          className="facility-table__popover sample-point__popover"
        >
          <span>{`${samplePt} ${samplePt > 1 ? 'Sample Points' : 'Sample Point'}`}</span>
        </Popover>
      ),
      ellipsis: true,
    },
    {
      title: getConsultantTitle(),
      dataIndex: `${whoYou === 'consultant' ? 'facilityPersonel' : 'facilityConsultant'}`,
      key: '3',
      width: '1%',
      render: (facilityPersonel) => <span>{getPersonalDeet(whoYou, facilityPersonel)}</span>,
      ellipsis: true,
      onFilter: (value, record) =>
        consultantX
          ? consultantX === 'Not Available'
            ? !record.facilityConsultant
            : record.facilityConsultant &&
              record.facilityConsultant.length > 0 &&
              properCase(
                `${record.facilityConsultant[0].firstName} ${record.facilityConsultant[0].lastName}`
              ) === consultantX
          : record,
      filterDropdownVisible: true,
      filteredValue: [consultantX],
    },
    {
      title: 'Date Created',
      dataIndex: 'entryDate',
      key: 'entryDate',
      width: '1%',
      render: (entryDate) => (
        <span>{entryDate ? formatDate(new Date(entryDate), 'LLL do, yyyy') : '00:00'}</span>
      ),
      sorter: (a, b) => compareDesc(new Date(a.entryDate), new Date(b.entryDate)),
      ellipsis: true,
    },
    {
      // title: 'Sector',
      // dataIndex: 'sectorName',
      key: 'sector',
      width: '0%',
      // fixed: 'right',
      onFilter: (value, record) =>
        sectorX && typeof record.sectorName === 'string'
          ? record.sectorName.toLowerCase().includes(sectorX.toLowerCase().trim())
          : record,
      filterDropdownVisible: true,
      filteredValue: [sectorX],
      // render: (sector) => <span>{String(sector)}</span>,
      ellipsis: true,
    },
    {
      key: 'eia',
      width: '0%',
      onFilter: (value, record) =>
        eiaStatusX && typeof record.facilityEia === 'string'
          ? record.facilityEia.toLowerCase().trim() === eiaStatusX.toLowerCase().trim()
          : record,
      filterDropdownVisible: true,
      filteredValue: [eiaStatusX],
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '0.7%',
      // fixed: 'right',
      onFilter: (value, record) =>
        statusX && typeof record.status === 'string'
          ? record.status.toLowerCase().includes(statusX.toLowerCase().trim())
          : record,
      filterDropdownVisible: true,
      filteredValue: [statusX],
      render: (sttus) => (
        <span
          className={
            String(sttus)
              .trim()
              .toLowerCase() === '1' || String(sttus).toLowerCase() === 'active'
              ? 'active'
              : 'not-active'
          }
        >
          {String(sttus) === '1' || String(sttus).toLowerCase() === 'active'
            ? 'Active'
            : 'Inactive'}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: '',
      key: '111',
      fixed: 'right',
      width: '0.3%',
      render: (_, record) => {
        return (
          <Dropdown overlay={overflowMenu} trigger={['click']}>
            <a
              className="ant-dropdown-link"
              href="/#"
              onClick={(e) => e.preventDefault() && actionInProgress()}
            >
              {moreOutlinedIcon(record)}
            </a>
          </Dropdown>
        );
      },
    },
  ];

  moreOutlinedIcon = (record) => (
    <MoreOutlined
      className="icon-more-outline"
      onClick={() => setRow(record)}
      // onMouseEnter={() => setRow(record)}
    />
  );

  overflowMenu = (
    <Menu className="facility-overflow__menu">
      {canCreateStandardReport && (
        <Menu.Item
          onClick={() => {
            let generatedReport = generateFacilityReport(row.facilityId);
            if (generatedReport.isRejected) {
              generatedReport = generateFacilityReport(row.facilityId);
              if (generatedReport.isRejected) {
                return openNotification({
                  type: 'error',
                  title: 'Failed to get load report data for facility',
                  message: `Please check your connection`,
                });
              }
            }
            setRecord({ type: 'standard', data: row });
            return setModal('selectqtr');
          }}
        >
          <span>Make Standard Report</span>
        </Menu.Item>
      )}
      {canCreateAdhocReport && (
        <Menu.Item
          onClick={() => {
            const newRow = { ...row };
            newRow.adhocReports = [];
            setRecord({ type: 'adhoc', data: newRow });
            return setModal('adhoc');
          }}
        >
          <span>Make Ad Hoc Report</span>
        </Menu.Item>
      )}
      {(canCreateStandardReport || canCreateAdhocReport) && (
        <Menu.Item className="menu-divider">
          <Divider />
        </Menu.Item>
      )}
      {canLockFacilityReport && (
        <Menu.Item
          onClick={() => {
            setRecord({ type: 'locking', data: row });
            setModal('locking');
          }}
        >
          <span>Lock Quarter</span>
        </Menu.Item>
      )}
      {false && canViewFacilityReport && (
        <Menu.Item
          onClick={() => {
            // setRecord({ type: 'viewing', data: row });
            // setModal('viewing');
          }}
        >
          <span>View Facility Reports</span>
        </Menu.Item>
      )}
      {canViewFacilityReport && (
        <Menu.Item
          onClick={() => {
            setRecord({ type: 'viewingcert', data: row });
            setModal('viewingcert');
          }}
        >
          <span>View EIA Certificate</span>
        </Menu.Item>
      )}
      {/* {canUpdateFacility && canViewFacilityReport && canLockFacilityReport && ( */}
      {canUpdateFacility && canLockFacilityReport && (
        <Menu.Item className="menu-divider">
          <Divider />
        </Menu.Item>
      )}
      {canUpdateFacility && (
        <Menu.Item
          onClick={() => {
            setRecord({ type: 'edit', data: row });
            setPage('edit');
          }}
        >
          <span>Update Facility</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const keyedData = data;
  keyedData.forEach((dt) => (dt['key'] = dt.facilityName));

  return (
    <Table
      columns={columns}
      dataSource={keyedData}
      loading={loading}
      // scroll={{ x: 800, y: 1200 }}
      mainTable={true}
      rowClassName={'facility-table__rows'}
    />
  );
};

export default FacilityTable;
