import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col, Form, Popconfirm, InputNumber, Radio, Modal, message } from 'antd';
import { SettingFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { openNotification } from 'helpers/notification';
import LocationInput from './LocationInput';
import Map from 'components/Map/fnMap';
import Table from 'components/common/Table';
import Input from 'components/common/AntInput';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import LoadingContent from 'components/common/LoadingContent';
import { properCase, isLatitude, isLongitude } from 'helpers/utils';
import { getMeasurements, getIcons } from 'helpers/api';

// different from the one in utils
// cos latitude isn't compulsory in this step
const checkLatitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.resolve();
  if (!val) return Promise.resolve();
  if (isLatitude(val)) {
    return Promise.resolve();
  }

  return Promise.reject('Latitude must be between -90 and 90 degrees!');
};

// different from the one in utils
// cos latitude isn't compulsory in this step
const checkLongitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.resolve();
  if (!val) return Promise.resolve();

  if (isLongitude(val)) {
    return Promise.resolve();
  }
  return Promise.reject('Longitude must be between -180 and 180 degrees!');
};

function toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + ' ' + minutes + ' ' + seconds;
}

function convertDMS(lat, lng) {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? 'N' : 'S';

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return latitude + ' ' + latitudeCardinal + ' - ' + longitude + ' ' + longitudeCardinal;
}

const StepTwo = ({ className, state: initialFormState = {}, updateMasterForm, next, prev }) => {
  const { stepTwoState } = initialFormState;
  const {
    icon: initIcon = {},
    runs: initRuns = '3',
    samplePointName: sampName = '',
    latitude: lat = '',
    longitude: long = '',
    samplePoints: sampPoints = [],
    samplePointTypes: initSamplePoints = [],
  } = stepTwoState;

  const [icon, setIcon] = useState(initIcon);
  const [runs] = useState(initRuns);
  const [showIconSet, setShowIconSet] = useState(false);
  const [iconSets, setIconSet] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState(lat);
  const [longitude, setLongitude] = useState(long);
  const [samplePoints, setSamplePoints] = useState(sampPoints);
  const [samplePointName] = useState(sampName);
  const [samplePointTypes, setSamplePointTypes] = useState(initSamplePoints);
  const [editing, setEditing] = useState(false);
  const [editingState, setEditingState] = useState('');
  const [load, reload] = useState(false);

  const formRef = useRef();

  const editSamplePoint = (recordKey) => {
    const editingState = samplePoints.filter((samp) => samp.key === recordKey);
    const notEditingState = samplePoints.filter((samp) => samp.key !== recordKey);
    setEditingState(editingState);
    setSamplePoints(notEditingState);
    setEditing(true);
  };

  const deleteSamplePoint = (recordKey) => {
    const newState = samplePoints.filter((samp) => samp.key !== recordKey);
    setSamplePoints(newState);
  };

  const columns = [
    {
      title: 'Sample Point Name',
      dataIndex: 'samplePointName',
      width: '23%',
      ellipsis: true,
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      width: '10%',
      render: (_, record) => (
        <Imgs
          src={record.icon.iconSrc ? record.icon.iconSrc : record.icon ? record.icon : ''}
          alt={record.icon.iconName ? record.icon.iconName : ''}
        />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'samplePointType',
      width: '15%',
      ellipsis: true,
      render: (_, record) => {
        if (samplePointTypes && samplePointTypes.length > 0) {
          const pT = samplePointTypes.filter((smpT) => smpT.id === record.samplePointType);
          if (pT && pT.length > 0) return pT[0].name;
          else return '';
        } else return '';
      },
    },
    {
      title: '# Runs',
      dataIndex: 'checkCount',
      width: '13%',
      ellipsis: true,
      render: (runs) => <span>{runs}</span>,
    },
    {
      title: 'Co-ordinates',
      dataIndex: 'coordinates',
      // width: '15%',
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return !editing ? (
          typeof record.key === 'number' ? (
            <Popconfirm
              title="Are you sure you want to delete this record?"
              onConfirm={() => deleteSamplePoint(record.key)}
              okText="Yes"
              cancelText="No"
            >
              <a href="/#">
                <DeleteOutlined className="icon-delete-row" />
              </a>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Are you sure you want to edit this record?"
              onConfirm={() => editSamplePoint(record.key)}
              okText="Yes"
              cancelText="No"
            >
              <a href="/#">
                <EditOutlined className="icon-edit-row" />
              </a>
            </Popconfirm>
          )
        ) : typeof record.key === 'number' ? (
          <a href="/#" onClick={(e) => e.preventDefault()}>
            <DeleteOutlined className="icon-delete-row not-editing" />
          </a>
        ) : (
          <a href="/#" onClick={(e) => e.preventDefault()}>
            <EditOutlined className="icon-edit-row not-editing" />
          </a>
        );
      },
    },
  ];

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 14 },
  };

  const { Option } = Select;

  function getSamplePointTypes() {
    getMeasurements()
      .then(({ result }) => {
        const mLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach((res) =>
            mLists.push({
              measurementId: res.measurementId,
              measurementName: res.measurementName,
              measurementCode: res.measurementCode,
              measurementOrder: res.measurementOrder,
              name: res.measurementName,
              id: res.measurementId,
              text: res.measurementName,
            })
          );
        setSamplePointTypes(mLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get sample point types',
          message: `We encountered an error while trying to get list of sample point types`,
        });
      });
  }

  function getIconSet() {
    getIcons()
      .then(({ result }) => {
        const iconLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach((res) =>
            iconLists.push({
              iconId: res.iconId,
              alt: res.iconName,
              iconName: res.iconName,
              iconSrc: res.iconSrc,
              id: res.iconId,
              text: res.iconName,
            })
          );
        setIconSet(iconLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to load icons',
          message: `We encountered an error while trying to load the icon set`,
        });
      });
  }

  function clearFormFields() {
    formRef.current.setFieldsValue({
      icon: '',
    });

    formRef.current.setFieldsValue({ runs });
    formRef.current.setFieldsValue({ samplePointName: '' });
    formRef.current.setFieldsValue({ samplePointType: '' });
    formRef.current.setFieldsValue({
      latitude: '',
    });
    formRef.current.setFieldsValue({
      longitude: '',
    });
    setIcon('');
    setLatitude('');
    setLongitude('');
  }

  useEffect(() => {
    getSamplePointTypes();
    getIconSet();
    if (editing && editingState && editingState[0]) {
      clearFormFields();
      const chord = editingState[0].actualCoordinates;
      const theLat = !chord ? '' : String(chord.split('-')[0]).trim();
      const theLng = !chord ? '' : String(chord.split('-')[1]).trim();
      const iconSr = editingState[0].icon.iconSrc
        ? editingState[0].icon.iconSrc
        : editingState[0].icon
        ? editingState[0].icon
        : '';
      setIcon({ iconSrc: iconSr });

      formRef.current.setFieldsValue({
        icon: iconSr,
      });

      formRef.current.setFieldsValue({ runs: editingState[0].checkCount });
      formRef.current.setFieldsValue({ samplePointName: editingState[0].samplePointName });
      formRef.current.setFieldsValue({ samplePointType: editingState[0].samplePointType });
      // formRef.current.setFieldsValue({
      //   latitude: { loc: theLat },
      // });
      // formRef.current.setFieldsValue({
      //   longitude: { loc: theLng },
      // });
      setLatitude(theLat);
      setLongitude(theLng);
      formRef.current.setFieldsValue({
        latitude: theLat,
      });
      formRef.current.setFieldsValue({
        longitude: theLng,
      });
      reload(true);
    }
    return;
  }, [editing, load]);

  const onFinish = () => {
    const GOTONEXT = 2;
    const theSamplePoints = [...samplePoints];
    if (editing && editingState && editingState[0]) theSamplePoints.push(editingState[0]);

    try {
      const stepTwoPayload = {
        icon: icon ? { iconSrc: icon.iconSrc, iconName: (icon.iconName = '') } : {},
        runs,
        samplePointName: samplePointName ? properCase(samplePointName) : '',
        samplePointTypes,
        latitude: latitude && latitude.loc ? latitude.loc : latitude,
        longitude: longitude && longitude.loc ? longitude.loc : longitude,
        samplePoints: theSamplePoints,
      };
      updateMasterForm(stepTwoPayload, 'stepTwoState', GOTONEXT);
    } catch (e) {
      // console.log('Something went wrong', e);
    }
  };

  const onFinishFailed = () => {};

  const saveAndPrev = () => {
    const GOTOPREV = 0;
    const theSamplePoints = [...samplePoints];
    if (editing && editingState && editingState[0]) theSamplePoints.push(editingState[0]);
    try {
      const stepTwoPayload = {
        icon: icon ? { iconSrc: icon.iconSrc, iconName: (icon.iconName = '') } : {},
        runs,
        samplePointName: samplePointName ? properCase(samplePointName) : '',
        samplePointTypes: samplePointTypes,
        latitude: latitude && latitude.loc ? latitude.loc : latitude,
        longitude: longitude && longitude.loc ? longitude.loc : longitude,
        samplePoints: theSamplePoints,
      };
      updateMasterForm(stepTwoPayload, 'stepTwoState', GOTOPREV);
    } catch (e) {
      // console.log('Something went wrong', e);
    }
  };

  const addSamplePoint = (record) => {
    try {
      // append to samplePoints
      // Reset form
      const currSamplePt = [...samplePoints];
      if (
        currSamplePt.some(
          (spPoint) =>
            spPoint.samplePointName.trim().toLowerCase() ===
            record.samplePointName.trim().toLowerCase()
        )
      ) {
        message.error(`Failed to ${editing ? 'update' : 'add'} record. SamplePoint name exists`);
        return;
      }
      const newSamplePt = {
        key: currSamplePt.length,
        id: currSamplePt.length,
        icon: record.icon.iconSrc
          ? record.icon.iconSrc
          : record.icon
          ? record.icon
          : icon.iconSrc
          ? icon.iconSrc
          : '',
        checkCount: record.runs || 3,
        samplePointName: properCase(record.samplePointName),
        samplePointType: record.samplePointType,
        actualCoordinates: latitude && longitude ? `${latitude}-${longitude}` : '',
        coordinates: latitude && longitude ? convertDMS(latitude, longitude) : '',
        // actualCoordinates:
        //   record.latitude && record.longitude && record.latitude.loc && record.longitude.loc
        //     ? `${record.latitude.loc} - ${record.longitude.loc}`
        //     : record.latitude && record.longitude
        //     ? record.latitude
        //     : '',
        // coordinates:
        //   record.latitude && record.longitude && record.latitude.loc && record.longitude.loc
        //     ? convertDMS(record.latitude.loc, record.longitude.loc)
        //     : record.latitude && record.longitude
        //     ? convertDMS(record.latitude, record.longitude)
        //     : '',
        // record.latitude && record.longitude && record.latitude.loc && record.longitude.loc
        //   ? `{${toDegreesMinutesAndSeconds(record.latitude.loc)},
        //         ${toDegreesMinutesAndSeconds(record.longitude.loc)}}`
        //   : '',
      };

      if (editing && editingState && editingState[0]) {
        newSamplePt.id = editingState[0].id;
        newSamplePt.key = editingState[0].id;
      }

      currSamplePt.push(newSamplePt);

      setEditingState('');
      // reset form
      // setSamplePoints([]);

      formRef.current.setFieldsValue({ icon: '' });
      formRef.current.setFieldsValue({ runs: runs });
      formRef.current.setFieldsValue({ samplePointName: '' });
      formRef.current.setFieldsValue({ samplePointType: '' });
      // formRef.current.setFieldsValue({ latitude: '' });
      // formRef.current.setFieldsValue({ longitude: '' });
      setIcon({});
      // setLatitude('');
      // setLongitude('');

      message.success(`Sample point ${editing ? 'updated' : 'added'}`);
      setSamplePoints(currSamplePt);
      setEditing(false);
    } catch (err) {}
  };

  const displayOptions = (list) => {
    let key = list;
    let { [key]: name } = { samplePointTypes };

    if (name.length > 0) {
      return name.map((item) => {
        return (
          <Option value={item.id} key={item.id}>
            {item.text}
          </Option>
        );
      });
    }
  };

  function Imgs({ src, alt = 'sample point icon' }) {
    return (
      <span className="icon-logo">
        <img src={src} style={{ verticalAlign: 'sub' }} alt={alt} />
      </span>
    );
  }

  const getSamplePointName = () => {
    let sptName = formRef.current.getFieldValue('samplePointName');
    return sptName;
  };

  const getCoordinateForMap = (whichCoordinate) => {
    let theCoordinate = formRef.current.getFieldValue(whichCoordinate);
    let key = whichCoordinate;
    let { [key]: stateCoordinate } = { latitude, longitude };

    return theCoordinate && theCoordinate.loc ? theCoordinate.loc : stateCoordinate;
  };

  const initValues = {
    // ['icon']: ['a'],
    runs,
    samplePointName,
    latitude,
    longitude,
  };

  return (
    <>
      <Form
        className={`${className} step-form`}
        {...formItemLayout}
        layout="horizontal"
        form={form}
        ref={formRef}
        initialValues={initValues}
        onFinish={addSamplePoint}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Choose Icon"
          name="icon"
          className="icon-choose"
          style={{ marginBottom: 0 }}
          rules={[{ required: true, message: 'Choose an Icon!' }]}
        >
          {showIconSet && (
            <Modal
              title={false}
              visible={showIconSet}
              className="icon-choose-modal"
              onCancel={() => setShowIconSet(!showIconSet)}
              maskClosable={false}
              closable
              footer={false}
            >
              {!iconSets && <LoadingContent modal={true} />}
              {iconSets && (
                <Radio.Group
                  style={{ display: 'block', marginLeft: '-1.5rem', marginBottom: '5px' }}
                  onChange={(ev) => {
                    formRef.current.setFieldsValue({
                      icon: { iconSrc: ev.target.value },
                    });
                    setShowIconSet(!showIconSet);
                    setIcon({ iconSrc: ev.target.value });
                  }}
                  buttonStyle="solid"
                >
                  <Row>
                    {iconSets.map((icn, index) => (
                      <Col span={2} key={icn.iconId || index}>
                        <Radio.Button value={icn.iconSrc} style={{ lineHeight: '32px' }}>
                          {<Imgs src={icn.iconSrc} alt={icn.iconName || ''} />}
                        </Radio.Button>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              )}
            </Modal>
          )}
          <>
            <SettingFilled className="icon-setting" onClick={() => setShowIconSet(!showIconSet)} />
            {icon && icon.iconSrc && (
              <span className="icon-logo">
                <img src={icon.iconSrc} alt={icon.iconName || 'sample point icon'} />
              </span>
            )}
          </>
        </Form.Item>
        <Row>
          <Col span={10}>
            <Form.Item
              name="samplePointName"
              label="Sample Point Name"
              // hasFeedback
              rules={[{ required: true, message: 'Sample Point Name is required!' }]}
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Input
                placeholder="Enter Sample Point Name"
                className="form-controlx"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={10} className="pl-3">
            <Form.Item
              name="samplePointType"
              label="Sample Point Type"
              // hasFeedback
              rules={[{ required: true, message: 'Sample Point Type is required!' }]}
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Select
                placeholder="Select Sample Point Type"
                className="form-controlxx"
                disabled={
                  editing &&
                  editingState &&
                  editingState[0] &&
                  typeof editingState[0].id === 'string'
                }
              >
                {displayOptions('samplePointTypes')}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Co-ordinates (Optional)" style={{ marginBottom: 0, display: 'block' }}>
          <Row>
            <Col span={10}>
              <Form.Item
                name="latitude"
                rules={[{ validator: checkLatitude }]}
                // style={{ display: 'inline-block', width: 'calc(50%)' }}
              >
                <LocationInput
                  type="latitude"
                  placeholder="Enter Latitude"
                  value={{ latitude }}
                  location={latitude}
                  setLocation={setLatitude}
                  className="form-controlx"
                />
              </Form.Item>
            </Col>
            <span
              style={{
                display: 'inline-block',
                width: '20px',
                lineHeight: '32px',
                textAlign: 'center',
              }}
            />
            <Col span={10}>
              <Form.Item
                name="longitude"
                rules={[{ validator: checkLongitude }]}
                style={{ display: 'inline-block', width: 'calc(100% - 20px)' }}
              >
                <LocationInput
                  type="longitude"
                  placeholder="Enter Longitude"
                  value={{ longitude }}
                  location={longitude}
                  setLocation={setLongitude}
                  className="form-controlx"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              {latitude && longitude && isLatitude(latitude) && isLongitude(longitude) && (
                <p
                  style={{
                    marginTop: '0.1rem',
                    transform: 'translateY(-0.5rem)',
                    position: 'relative',
                    color: '#0c5bab',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowMap(!showMap)}
                >
                  {showMap ? 'Hide map' : 'Show on map'}
                </p>
              )}
            </Col>
          </Row>
        </Form.Item>
        <Row>
          <Col span={10}>
            {showMap && (
              <Map
                latitude={getCoordinateForMap('latitude')}
                longitude={getCoordinateForMap('longitude')}
                title={getSamplePointName()}
                zoom={14}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item
              label="# Min. Runs"
              name="runs"
              style={{ marginBottom: 0, display: 'block' }}
            >
              <InputNumber
                min={1}
                type="number"
                max={1000000000000}
                step={1}
                parser={(value) => parseInt(value)}
                size="small"
                className="form-controlx"
              />
            </Form.Item>
          </Col>
          <Col span={10} className="pl-3" style={{ alignSelf: 'end', placeContent: 'end' }}>
            <Form.Item
              label=" "
              {...tailLayout}
              style={{ marginBottom: '0', display: 'block' }}
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.samplePointName.toLowerCase() !==
                  currentValues.samplePointName.toLowerCase() ||
                prevValues.samplePointType !== currentValues.samplePointType
              }
              className="add-sample-point-btn"
            >
              {({ getFieldValue }) => {
                return (
                  <Button
                    type="primary"
                    appearance="default"
                    htmlType="submit"
                    disabled={
                      !getFieldValue('samplePointName') || !getFieldValue('samplePointType')
                    }
                  >
                    {editing ? 'Update Sample Point' : 'Add Sample Point'}
                  </Button>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={10} className="pl-3" />
        </Row>
      </Form>

      <Form
        className={`${className} step-form step-form-table spt-data`}
        {...formItemLayout}
        layout="horizontal"
      >
        <Row>
          <Col span={20}>
            {/* {samplePoints.length > 0 && ( */}
            <Table
              columns={columns}
              dataSource={samplePoints}
              pagination={false}
              scroll={{ y: 240 }}
            />
            {/* )} */}
          </Col>
        </Row>
      </Form>

      <Form
        className={`${className} step-form`}
        {...formItemLayout}
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          {...tailLayout}
          style={{ marginTop: '1rem', marginBottom: '2em', display: 'block' }}
        >
          <Button type="secondary" onClick={saveAndPrev}>
            Back
          </Button>
          <Button
            type="primary"
            appearance="default"
            htmlType="submit"
            style={{ margin: 8 }}
            disabled={samplePoints.length === 0}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default styled(StepTwo)`
  min-width: 735px !important;
  max-width: 90% !important;
  max-height: max-content;
  overflow-y: hidden;
  border-radius: 5px;

  .ant-modal-header {
    padding: 27px 40px 29px !important;
  }

  .ant-modal-body {
    padding: 27px 40px !important;
    max-height: 700px;
    overflow-y: auto;
  }

  .ant-modal-body,
  .ant-collapse {
    background-color: #fff;
  }
  label {
    font-family: var(--font-family);
    font-size: var(--form-label-fs);
    font-weight: var(--form-label-fw);
    margin-bottom: var(--form-label-mb);
    letter-spacing: normal;
    color: var(--dark);
  }
  .pl-3 {
    padding-left: 19px;
  }
  .ant-select-selection {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ant-select-selection--single {
    height: 41px;
  }
  .ant-select-selection__rendered {
    margin-top: 3px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .form-control {
    width: 100%;
    height: 41px;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(245, 247, 255, 0.2);
    &:focus {
      outline: 0;
      border: 0.5px solid #007ace;
    }
  }
`;
