import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  UploadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { FacilityProvider } from '../context';
import Analytics from './Analytics';
import Reports from './Reports';
import './index.css';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export default function index({ record }) {
  const [collapsed, setCollapsed] = useState(false);
  const [curr, setCurr] = useState('4');

  // console.log('records', record);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const lML = collapsed ? 60 : 180;

  const renderContent = (argg) => {
    // console.log('rednernig', argg);
    setCurr(argg.key);
  };

  const loadContent = () => {
    switch (curr) {
      case '1':
        return <Analytics record={record} />;
        break;
      case '2':
        return <Reports />;
        break;
      case '41':
        return;

      default:
        return (
          <Content style={{ margin: '0 16px 0', overflow: 'scroll' }}>
            <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
              Nav Areas
            </div>
          </Content>
        );
        break;
    }
  };

  return (
    <FacilityProvider record={record}>
      <Layout className="facility-details__layout">
        <Sider
          collapsible
          theme="light"
          collapsed={collapsed}
          onCollapse={onCollapse}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <div className="logo" />
          <Menu theme="light" mode="inline" defaultSelectedKeys={['4']}>
            <Menu.Item key="1" icon={<AppstoreOutlined />} onClick={renderContent}>
              Overview
            </Menu.Item>
            <Menu.Item key="2" icon={<VideoCameraOutlined />} onClick={renderContent}>
              Reports
            </Menu.Item>
            <Menu.Item key="3" icon={<UploadOutlined />} onClick={renderContent}>
              nav 3
            </Menu.Item>
            <SubMenu key="sub1" icon={<UserOutlined />} title="Sample Points">
              {record &&
                record.samplePoints &&
                record.samplePoints.map((spt, index) => (
                  <Menu.Item key={`4-${index + 1}`} onClick={renderContent}>
                    {spt.samplePointName}
                  </Menu.Item>
                ))}
            </SubMenu>

            <Menu.Item key="4" icon={<BarChartOutlined />} onClick={renderContent}>
              Wastes
            </Menu.Item>
            <Menu.Item key="5" icon={<CloudOutlined />} onClick={renderContent}>
              Products
            </Menu.Item>
            <Menu.Item key="6" icon={<AppstoreOutlined />} onClick={renderContent}>
              nav 6
            </Menu.Item>
            <Menu.Item key="7" icon={<TeamOutlined />} onClick={renderContent}>
              nav 7
            </Menu.Item>
            <Menu.Item key="8" icon={<ShopOutlined />} onClick={renderContent}>
              nav 8
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: lML }}>
          {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
          {/* <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}> */}
          {loadContent()}
          {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
        </Layout>
      </Layout>
    </FacilityProvider>
  );
}
