import React, { useState, useEffect } from 'react';
import { Form, message, InputNumber, Row, Col } from 'antd';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import Table from 'components/common/Table';
import { addParameters } from '../../../../helpers/api';
import { DeleteOutlined } from '@ant-design/icons';


// let parameters = [];

const AddParameterForm = ({ closeModal, reload, measurementId }) => {
  const [submitParameters, setSubmitParameters] = useState(false);
  const [parameterCollection, setParameterCollection] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const storeParameters = (params) => {
    // parameters.push(params);

    setParameterCollection([...parameterCollection, params]);
  };

  useEffect(() => {
    if (submitParameters) {
      handleParameters();
    }
  }, [submitParameters]);

  const removeParameter = (ev) => {
    console.log(ev);
    const filteredData = parameterCollection.filter((params) => params.unit !== ev);
    if (filteredData && filteredData.length) {
      console.log(filteredData);
      setParameterCollection(filteredData);
    }
  };

  const columns = [
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'SI Unit',
      dataIndex: 'siUnit',
      key: 'siUnit',
    },
    {
      title: 'FMEnv',
      dataIndex: 'fmEnv',
      key: 'fmEnv',
    },
    {
      title: 'World Bank',
      dataIndex: 'worldBank',
      key: 'worldBank',
    },
    {
      title: '',
      dataIndex: 'unit',
      key: 'unit',
      render: (unit) => (
        <>
          {' '}
          <Button id={unit} onClick={(e) => removeParameter(unit)} className="transparent__bg">
            <DeleteOutlined className="text-danger" />
          </Button>{' '}
        </>
      ),
    },
  ];

  const previewParameter = () => {
    if (parameterCollection && parameterCollection.length > 0) {
      return (
        <Table columns={columns} dataSource={parameterCollection} rowClassName="parameter__table" />
      );
    } else {
      return null;
    }
  };

  const handleSubmitParameters = () => {
    console.log('should create stored parameters ');
    setSubmitParameters(true);
  };

  // HANDLE  PARAMETERS
  const handleParameters = (values) => {
    // console.log(submitParameters, 'submit', parameters);
    if (submitParameters !== true) {
      storeParameters(values);
      form.resetFields();
    }
    if (submitParameters === true) {
      setLoading(true);
      console.log('creating parameters', measurementId, parameterCollection);
      addParameters(parameterCollection, measurementId)
        .then((res) => {
          console.log(res);
          setLoading(false);
          message.success(`Parameters added successfully`);
          reload();
          closeModal();
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleParameters} hideRequiredMark={true}>
        <Row justify="space-between">
          <Col lg={11}>
            <Form.Item
              name="unit"
              label="Parameter Name"
              rules={[{ required: true, message: 'Please enter parameter unit' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={11}>
            <Form.Item
              name="siUnit"
              label="SI Unit"
              rules={[{ required: true, message: 'SI Unit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col lg={11}>
            <Form.Item
              name="fmEnv"
              label="FM Env"
              className="parameter__input"
              rules={[{ required: true, message: 'fmEnv is required' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col lg={11}>
            <Form.Item
              name="worldBank"
              label="World Bank"
              className="parameter__input"
              rules={[{ required: true, message: 'Please enter the world bank limit' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="text-right">
          <Button htmlType="submit" type="primary" appearance="default" loading={loading}>
            Add
          </Button>
        </Form.Item>
      </Form>
      <div>
        <Button
          onClick={handleSubmitParameters}
          htmlType="button"
          type="primary"
          appearance="default"
          className="mr-15"
          loading={loading}
        >
          Save
        </Button>

        <Button onClick={closeModal} htmlType="button" type="secondary" loading={loading}>
          Cancel
        </Button>
      </div>
      <div className="preview__parameter mt-2rem">
        {previewParameter()}
        {/* <Table columns={columns} dataSource={parameters && parameters.length > 0 && parameters} /> */}
      </div>
    </>
  );
};

export default AddParameterForm;
