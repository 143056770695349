import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { white } from 'design-system/colors';

const LoadingContent = ({ className, fullPage, pageLoading, modal, biggerModal }) =>
  pageLoading ? (
    <LoadingOutlined
      style={{
        fontSize: '2rem',
        display: 'flex',
        width: '100%',
      }}
      className="loading-outlined"
      spin
    />
  ) : modal ? (
    <LoadingOutlined style={{ fontSize: '48', display: 'flex', width: '100%' }} />
  ) : biggerModal ? (
    <LoadingOutlined style={{ fontSize: '2rem', display: 'flex', width: '100%' }} />
  ) : fullPage ? (
    <div className={`${className} spin-fullpage`}>
      <Spin />
    </div>
  ) : (
    <div className={className}>
      <Spin />
    </div>
  );

export default styled(LoadingContent)`
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;
  background-color: ${white};
`;
