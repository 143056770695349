import React, { useState, useEffect, useRef } from 'react';
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  DatePicker,
  Modal,
  Tooltip,
  Typography,
  Form,
  Divider,
  Input,
  InputNumber,
  Carousel,
  Progress,
  Popconfirm,
  Upload,
  message,
  Button as AntButton,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import EmptyContent from 'components/common/EmptyContent';
import LoadingContent from 'components/common/LoadingContent';
import CloseIcon from 'components/common/CloseIcon';
import { ReactComponent as FacilitiesIcon } from 'assets/img/facilities.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus.svg';
import Button from 'components/common/Button';
import CreateNewFacility from 'components/CreateNewFacility';
import EditFacility from 'components/EditFacility';
import PageHeader from 'components/common/PageHeader';
import PageContent from 'components/common/PageContent';
import SearchInput from 'components/common/SearchInput';
import Select from 'components/common/Select';
import Filter from 'components/common/Filter';
import { openNotification } from 'helpers/notification';
import {
  getFacilities,
  getFacility,
  lockReport,
  createAdhocReport,
  getFacilityAdhocReport,
  getFacilityReport,
  getAdhocReport,
  getSamplePointRecord,
  sendSamplePointRecord,
  publishFacilityReport,
  uploadEIACert,
} from 'helpers/api';
import FacilitiesTable from 'components/FacilityTable';
import FacilityReportingModal from './FacilityReportingModal';
import {
  properCase,
  formatDate,
  getQuarter,
  getErrorDesc,
  datelize,
  parseString,
  parseString3,
  parseString4,
  setFormatValue,
} from 'helpers/utils';
import CalendarLock from 'assets/img/calendar-6.svg';
import CalendarOpen from 'assets/img/calendar-18.svg';
import LockRed from 'assets/img/lock.svg';
import LockGrey from 'assets/img/lock-grey.svg';
import CalendarGrey from 'assets/img/calendar-grey.svg';
import WindPowerIcon from 'assets/img/wind-power.svg';
// Icons made by <a href="https://www.flaticon.com/authors/srip"
// title="srip">srip</a> from <a href="https://www.flaticon.com/" title="Flaticon">
// www.flaticon.com</a>
// import DraftIcon from 'assets/img/draft.svg';
// import TaskCompleteIcon1 from 'assets/img/task-complete.svg';
import TaskCompleteIcon from 'assets/img/taskdone.svg';
// import TaskCompleteIcon from 'assets/img/taskcomplete.svg';
import DraftIcon from 'assets/img/draw.svg';

import FacilityUploadTable from '../../FacilityUploadTable';
import FacilityView from './FacilityView';
import '../../../shared.css';
import './index.css';
import '../../../scrollfix.css';

const { Text } = Typography;
const { TextArea } = Input;
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = 'YYYY-MM-DD';
const date = new Date();
const y = date.getFullYear();
const m = date.getMonth();
const currentDay = moment(new Date()).format(dateFormat);
// .startOf('month')
const endOfYear = moment()
  .endOf('year')
  .format(dateFormat);

const initAdhocValues = {
  adhocReportPeriod: moment(currentDay),
  // adhocReportPeriod: [moment(currentDay), moment(endOfYear)],
};

// Generate random color
const generateRadomColor = () => {
  const xxx = Math.random() * 255;
  const yyy = Math.random() * 255;
  const zzz = Math.random() * 255;
  return `rgba(${xxx}, ${yyy}, ${zzz}, 0.4)`;
};

// left here for when we want to use timestamp to lock quarter
// current only when report type is not under QA/QC
const currentYear = new Date().getFullYear();
const isNotLocked = (report, qtr) => {
  if (report.length > 0) {
    const rpt = report.filter(
      (rp) =>
        rp &&
        rp.reportQuarter &&
        parseInt(rp.reportQuarter) === parseInt(qtr) &&
        rp.reportType !== 'QAQC'
    );
    if (rpt && rpt.length > 0) return true;
    return false;
  }
  return false;
};

export default function Facilities({ props }) {
  const initialPage = props.location.pathname === '/facilities/create' ? 'create' : 'loading';
  const [page, setPage] = useState(initialPage);
  const [facilities, setFacilities] = useState('');
  const [record, setRecord] = useState('');
  const [samplePointRecord, setSamplePointRecord] = useState('');
  const [samplePointRecordData, setSamplePointRecordData] = useState([]);
  const [parameters, setParameters] = useState('');
  const [report, setReport] = useState('');
  const [facilityRecord, setFacilityRecord] = useState('');
  const [adhocReport, setAdhocReport] = useState('');
  const [modal, setModal] = useState('');
  const [load, reload] = useState(false);
  const [consultantFilter, setConsultantFilter] = useState('');
  const [facilityFilter, setFacilityFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sectorFilter, setSectorFilter] = useState('');
  const [eiaFilter, setEiaFilter] = useState('');
  const [search, setSearch] = useState('');
  const [filtering, setFiltering] = useState(false);
  const [createNewAdhocModal, setCreateNewAdhocModal] = useState('');
  const [creatingAdhocReport, setCreatingAdhocReport] = useState('');
  const [adhocStep, setAdhocStep] = useState('');
  const [currentAdhocSummary, setCurrentAdhocSummary] = useState('');
  const [currentAdhocReport, setCurrentAdhocReport] = useState('');
  const [reportingModalState, setReportingModalState] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();
  const sampleDataAddFormRef = useRef();
  const filterFormRef = useRef();

  const getFacilitiesLists = (urlParam, type) => {
    // setIsSearching(true);
    getFacilities(urlParam)
      .then(({ result }) => {
        const fLists = [];
        result.data && result.data.length > 0 && result.data.forEach((res) => fLists.push(res));
        setFacilities(fLists);
      })
      .catch((err) => {
        if (search || type === 'search') setFacilities([]);
        else setPage('error');
        // return openNotification({
        //   type: 'error',
        //   title: 'Failed to get facilities',
        //   message: `${err}`,
        // });
      })
      .finally(() => setIsSearching(false));
  };

  const getAndSetFacilityReport = (id, qtr, yyyy = currentYear) => {
    getFacilityReport(id, qtr, yyyy)
      .then(({ result }) => {
        const fReport = { qtr, data: null };
        if (result.data) fReport.data = result.data;
        setReport(fReport);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get facility report',
          message: `${err}`,
        });
      });
  };

  const getAndSetSamplePointRecord = (samplePtId, reportId) => {
    getSamplePointRecord(samplePtId, reportId)
      .then(({ result }) => {
        const sptRecord = { data: null };
        if (result.data) sptRecord.data = result.data;
        setSamplePointRecord(sptRecord);
        if (result.data && result.data.parameters) setParameters(result.data.parameters);
        if (result.data && result.data.results) {
          const formatResult = { ...result.data };
          const resultReturning = [];

          formatResult.results.map((fmRst, index) => {
            const objR = {};
            fmRst.data.map((fmRst2) => {
              const thePamata = result.data.parameters.filter(
                (pamata) => pamata.parameterId === fmRst2.parameterId
              );
              if (thePamata && thePamata[0] && thePamata[0]['unit'])
                objR[thePamata[0]['unit']] = fmRst2.resultValue;
            });

            if (!objR['entryDate']) {
              if (fmRst && fmRst.data && fmRst.data[0] && fmRst.data[0]['entryDate'])
                objR['entryDate'] = fmRst.data[0]['entryDate'];
              else objR['entryDate'] = new Date();
            }
            resultReturning.push(objR);
          });
          setSamplePointRecordData(resultReturning);
        }
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get facility record',
          message: `${err}`,
        });
      });
  };

  const sendSamplePointsData = (type) => {
    const data = {};
    if (type === 'adhoc' && currentAdhocSummary) data['reportId'] = currentAdhocSummary.reportId;
    else data['reportId'] = report.data.reportId;
    data['facilityId'] = samplePointRecord.data.facilityId;
    data['samplePointId'] = samplePointRecord.data.samplePointId;
    data['measurmentId'] = samplePointRecord.data.measurementId;
    const measurMntData = [];
    samplePointRecordData.map((smptRData) => {
      const theParams = [];
      parameters.map((pamta) =>
        theParams.push({
          parameterId: String(pamta.parameterId),
          value: parseFloat(smptRData[pamta.unit]) || 0,
          entryDate: smptRData['entryDate'],
        })
      );

      measurMntData.push(theParams);
    });

    data['measurements'] = measurMntData;

    sendSamplePointRecord(data)
      .then(() => {
        // setRecord('');
        if (type === 'adhoc') handleCancel('adhoc-recording');
        else backToReporting(report.qtr);
        return openNotification({
          type: 'success',
          title: 'Sample point record saved successfully',
          message: '',
        });
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to save record',
          message: `${err}`,
        });
      });
  };

  const getAdhocReports = (id, yyyy = currentYear) => {
    getAdhocReport(id, yyyy)
      .then(({ result }) => {
        const fList = [];
        result.data && result.data.length > 0 && result.data.forEach((res) => fList.push(res));
        setAdhocReport(fList);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: `${err && typeof err === 'string' ? err : 'Failed to get facility.'}`,
        });
      });
  };

  const getFacilityRecord = (id, yyyy = currentYear) => {
    getFacility(id, yyyy)
      .then(({ result }) => {
        const fList = [];
        result.data && result.data.length > 0 && result.data.forEach((res) => fList.push(res));
        setFacilityRecord(fList);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: `${err && typeof err === 'string' ? err : 'Failed to get facility.'}`,
        });
      });
  };

  const getAndLock = (id, qtr) => {
    // make a request to the api to
    // lock this facility report
    // for the stated qtr
    // and re set the report state
    lockReport(id)
      .then(({ result }) => {
        openNotification({
          type: 'success',
          title: 'Successful',
          message: `Quarter ${qtr} locked for ${result.data.facility}`,
        });
        reload(!load);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: `${err && typeof err === 'string' ? err : 'Failed to lock report.'}`,
        });
      });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 14 },
  };

  const onFinishFailed = () => {};
  const saveSamplePointData = (type) => {
    sendSamplePointsData(type);
    // clear sample point record data
    // return to samplepoint selecting
  };

  useEffect(() => {
    if (page !== 'create' && page !== 'edit' && page !== 'error') setPage('loading');
    setCreateNewAdhocModal(false);
    try {
      if (props.location.pathname === '/facilities/create') setPage('create');
      // else if (props.location.pathname === '/facilities/edit') setPage('edit');
      else if (page === 'edit') setPage('edit');
      else if (page === 'error') setPage('error');
      else {
        if (!modal) getFacilitiesLists();
        if (modal === 'adhoc' && record.data.facilityId) {
          // do adhoc stuffs
          //first a form to submit adhoc body fields
          getAdhocReports(record.data.facilityId);
        }
        if ((modal === 'selectqtr' || modal === 'locking') && record.data.facilityId) {
          // getFacilityRecord(record.facilityId);
          getFacilityRecord(record.data.facilityId);
        }
        if (modal === 'viewingcert') {
          // do nothing really
        }
        setPage('');
      }
    } catch (err) {
      setPage('error');
    }
    return;
  }, [
    props,
    page,
    modal,
    record,
    currentAdhocReport,
    currentAdhocSummary,
    samplePointRecordData,
    parameters,
    load,
    consultantFilter,
    facilityFilter,
    stateFilter,
    sectorFilter,
    statusFilter,
    eiaFilter,
  ]);

  const createFacility = () => {
    setPage('create');
    props.history.push('/facilities/create');
  };

  const { userCategory, userPermission } = props;

  const userCat = userCategory.trim().toLowerCase();
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  const isNotEDM = userCat !== 'edm';
  const isEDM = !isNotEDM;
  const isNotConsultant = userCat !== 'consultant';
  const isConsultant = !isNotConsultant;

  const canMakeFacilityReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  const canLockReport = userCat === 'edm';
  const canViewReport =
    userCat === 'edm' ||
    (userCat === 'consultant' && userPerm.includes('view_report')) ||
    userCat === 'regulator';
  const canCreateAdhocReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  const canRecordReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  const canUpdateFacility =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('update_facility'));
  const canCreateFacilityReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  const canCreateFacility =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('create_facility'));
  const canPublishReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  const canViewRecordedReport =
    userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('view_report'));

  const closeEditing = (e, which) => {
    if (!which) {
      e.preventDefault();
      return setPage('');
    }
    if (which === 'startediting') {
      openNotification({
        type: 'info',
        title: `Editing: ${record.data.facilityName}`,
        message: 'Use the breadcrumb link on the top left to exit action',
      });
    } else if (which === 'closeediting') {
      return setPage('');
    } else if (which === 'error') return setPage(which);
    else setPage('');
  };

  if (page === 'create' && canCreateFacility) return <CreateNewFacility />;
  if (page === 'edit' && canUpdateFacility)
    return <EditFacility facility={record.data} closeEditing={closeEditing} />;
  if (
    (page === 'loading' && !facilities) ||
    page === 'loading' ||
    (!facilities && page !== 'error')
  )
    return (
      <div className="spin-fullpage">
        <LoadingContent pageLoading={true} />
      </div>
    );

  const handleOk = () => setModal('');
  const handleCancel = (which) => {
    switch (which) {
      case 'selecting':
        setModal('');
        setRecord('');
        break;
      case 'recording':
        setRecord('');
        setSamplePointRecordData([]);
        setSamplePointRecord('');
        setParameters('');
        break;
      case 'locking':
        setModal('');
        setRecord('');
        break;
      case 'reporting':
        setReport('');
        setFacilityRecord('');
        setRecord('');
        setModal('');
        break;
      case 'viewing':
        setRecord('');
        setModal('');
        break;
      case 'viewingcert':
        setRecord('');
        setFileList([]);
        setModal('');
        break;
      case 'adhoc':
        setModal('');
        setRecord('');
        setAdhocStep('');
        setCreatingAdhocReport('');
        setCurrentAdhocSummary('');
        setAdhocReport('');
        break;
      case 'adhoc-recording':
        adhocReporting2(currentAdhocSummary);
        break;
      default:
        break;
    }
  };

  function backToReporting(whichQtr) {
    setPage('loading');
    getAndSetFacilityReport(record.data.facilityId, whichQtr);
    setPage('');
    setModal('reporting');
  }

  function backToReportingQtr(e) {
    e.preventDefault();
    // setPage('loading');
    // setPage('');
    setModal('selectqtr');
  }

  const locking = (whichQtr) => {
    setPage('loading');
    let qtr = whichQtr;
    let { [qtr]: selectedQtr } = {
      q1: 1,
      q2: 2,
      q3: 3,
      q4: 4,
    };
    if (facilityRecord && facilityRecord.length > 0) {
      const rpt = facilityRecord.filter((rp) => parseInt(rp.reportQuarter) === selectedQtr);
      getAndLock(rpt[0].reportId, selectedQtr);
      setPage('');
    } else {
      setPage('');
    }
  };

  // get quarter from click and fetch report data
  const reporting = (whichQtr) => {
    setPage('loading');
    let qtr = whichQtr;
    let { [qtr]: currQtr } = {
      q1: 1,
      q2: 2,
      q3: 3,
      q4: 4,
    };
    if (record.data) {
      getAndSetFacilityReport(record.data.facilityId, currQtr);
      setPage('');
      setModal('reporting');
    } else {
      setModal('');
      // setRecord('');
      setPage('');
    }
  };

  // const uploadEIACert = () => new Promise((resolve, reject) => resolve(true));

  const uploadProps = {
    name: 'file',
    action: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const formerRecord = Object.assign({}, record);
          uploadEIACert(formerRecord.data.facilityId, reader.result)
            .then(() => {
              message.success('Uploaded succesfully');
              formerRecord.data.eiaCert = reader.result;
              setRecord(formerRecord);
              setFileList([file]);
            })
            .catch((error) => {
              let errMsg = getErrorDesc(error)
                ? getErrorDesc(error)
                : 'Failed to upload. Please try again!';
              message.error(errMsg);
              setRecord(formerRecord);
              setFileList([]);
              reject(error);
            });
          // setRecord(formerRecord);
          return resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      }),
    beforeUpload(file, fileList) {
      const isJpgOrPngorPdf =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'application/pdf' ||
        file.type === 'image/x-eps';
      if (!isJpgOrPngorPdf) {
        message.error('You can only upload JPG/PNG/PDF file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('File must smaller than 2MB!');
      }
      isJpgOrPngorPdf && isLt2M && fileList && setFileList(fileList.slice(-1));
      return isJpgOrPngorPdf && isLt2M;
    },
    onRemove: () =>
      new Promise((resolve) => {
        setFileList([]);
        return resolve(true);
      }),
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
        // message.success(`${info.file.name ? info.file.name : ''} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        setFileList([]);
        // message.error(`${info.file.name ? info.file.name : ''} file upload failed.`);
      }
    },
    transformFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          // setCertBase64(reader.result);
          return resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    fileList,
    // disabled: fileList && fileList.length > 0,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  function generateUploadProps(which) {
    const retProps = { ...uploadProps };
    // if (which === 'new') delete retProps.fileList;
    if (which === 'header') {
      retProps.fileList = [];
    }
    return retProps;
  }

  function renderModalHeader(status, modalType, cancelAct) {
    if (!cancelAct) cancelAct = modalType;
    if (status && status.data) {
      let sampleCount = status.data.samplePointCount;
      let sampleText =
        sampleCount > 1 ? `(${sampleCount} SamplePoints)` : `(${sampleCount} SamplePoint)`;
      const { facilityName } = status.data;
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className="facility-report__header"
        >
          <span style={{ alignSelf: 'start' }}>
            <div className="report-title">
              <Text type="strong">
                {modalType === 'selecting' || modalType === 'locking'
                  ? properCase(facilityName)
                  : properCase(record.data.facilityName)}
              </Text>

              {modalType === 'viewingcert' && (
                <span
                  className={
                    record.data.status.toLowerCase().trim() === 'active' ? 'active' : 'not-active'
                  }
                >
                  {properCase(record.data.status)}
                </span>
              )}
            </div>
            <div className="report-subtitle-1">
              {modalType !== 'adhocrecording' &&
                modalType !== 'viewingcert' &&
                modalType !== 'adhoc' && (
                  <Text type="secondary">
                    {!sampleCount
                      ? `${
                          modalType === 'selecting' ||
                          modalType === 'locking' ||
                          modalType === 'viewing' ||
                          modalType === 'adhoc'
                            ? datelize(getQuarter(new Date(Date.now())))
                            : datelize(report.data.reportQuarter)
                        }
                 Quarter`
                      : sampleText}
                  </Text>
                )}
              {modalType === 'adhoc' && (
                <Text type="secondary">{formatDate(new Date(Date.now()), 'd LLL yyyy')}</Text>
              )}
              {modalType === 'adhocrecording' && currentAdhocSummary && (
                <Text type="secondary">{`${currentAdhocSummary.reportTitle}`}</Text>
              )}
              {modalType === 'viewingcert' && <Text type="secondary">EIA Certificate</Text>}
            </div>
            <div className="report-subtitle-2">
              {modalType !== 'viewingcert' && (
                <Text type="secondary">
                  {(record.type === 'standard' || record.type === 'locking') &&
                  modalType !== 'adhocrecording'
                    ? 'Standard Report'
                    : 'Adhoc Report'}
                </Text>
              )}
              {modalType === 'viewingcert' && status.data.eiaCert && (
                <Upload {...generateUploadProps('header')} className="cert-upload-btn">
                  <AntButton>
                    <UploadOutlined /> Upload New Certificate
                  </AntButton>
                </Upload>
              )}
            </div>
          </span>
          <span style={{ alignSelf: 'end', textAlign: 'right' }}>
            <>
              <CloseIcon
                className="close-icon report-viewing"
                onClick={() => handleCancel(cancelAct)}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
              {false && (
                <>
                  <div className="report-date__title">
                    <Text type="secondary">{formatDate(new Date(Date.now()), 'd LLL yyyy')}</Text>
                  </div>
                  <div className="report-subtitle-1 report-time">
                    <Text type="secondary">{formatDate(new Date(Date.now()), 'hh mm aaaa')}</Text>
                  </div>
                </>
              )}
            </>
          </span>
        </div>
      );
    } else
      return (
        <>
          <div className="facility-report__body">
            <Row gutter={16} style={{ marginTop: '1.5rem' }} type="flex" align="end" justify="end">
              <CloseIcon
                className="close-icon report-viewing"
                onClick={() => handleCancel('viewing')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row>
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );
  }

  function renderSelectModal(factyRecord) {
    if (!factyRecord || factyRecord.length < 1)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('selecting')} /> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    const isNotPassedFirstQuarter = !isNotLocked(factyRecord, 1);
    const isNotPassedSecondQuarter = !isNotLocked(factyRecord, 2);
    const isNotPassedThirdQuarter = !isNotLocked(factyRecord, 3);
    const isNotPassedFourthQuarter = !isNotLocked(factyRecord, 4);
    const getStatusAsap = (q) => factyRecord.filter((fR) => parseInt(fR.reportQuarter) === q)[0];
    const q1Status = getStatusAsap(1)['reportStatus'].toLowerCase();
    const q2Status = getStatusAsap(2)['reportStatus'].toLowerCase();
    const q3Status = getStatusAsap(3)['reportStatus'].toLowerCase();
    const q4Status = getStatusAsap(4)['reportStatus'].toLowerCase();

    const quarters = [
      {
        code: 'q1',
        text: 'First Quarter',
        period: 'Jan - Mar',
        hoverable: isEDM ? isNotPassedFirstQuarter : !isNotPassedFirstQuarter,
        status: q1Status,
      },
      {
        code: 'q2',
        text: 'Second Quarter',
        period: 'Apr - Jun',
        hoverable: isEDM ? isNotPassedSecondQuarter : !isNotPassedSecondQuarter,
        status: q2Status,
      },
      {
        code: 'q3',
        text: 'Third Quarter',
        period: 'Jul - Sep',
        hoverable: isEDM ? isNotPassedThirdQuarter : !isNotPassedThirdQuarter,
        status: q3Status,
      },
      {
        code: 'q4',
        text: 'Fourth Quarter',
        period: 'Oct - Dec',
        hoverable: isEDM ? isNotPassedFourthQuarter : !isNotPassedFourthQuarter,
        status: q4Status,
      },
    ];

    return (
      <>
        <div className="facility-report__body">
          {/* <div className="arrow-back" onClick={() => handleCancel('selecting')} /> */}
          <Row gutter={16} style={{ marginTop: '1.5rem' }}>
            {quarters.map((qt) => {
              return (
                <Col span={6} key={qt.code}>
                  <Card
                    hoverable={qt.hoverable}
                    className={`facility-card__body ${!qt.hoverable ? 'nocursor' : ''}`}
                    onClick={qt.hoverable ? () => reporting(qt.code) : () => false}
                  >
                    {/* <div className={`card-lockx ${qt.hoverable ? 'hide' : ''}`}>
                      <p>Locked for QA/QC</p>
                    </div> */}

                    <div className={`card-lock qtr-lock-icon ${qt.hoverable ? '' : ''}`}>
                      {isNotConsultant || (isConsultant && qt.hoverable) ? (
                        ''
                      ) : (
                        <p className="lock-text-desc">Locked to QAQC</p>
                      )}
                      <img
                        src={
                          !qt.hoverable
                            ? LockRed
                            : qt.status === 'draft'
                            ? DraftIcon
                            : TaskCompleteIcon
                        }
                        className="lock-icon"
                        alt="lock"
                      />
                    </div>

                    <div className="card-content">
                      <div className="qtr-icon">
                        <img
                          src={qt.hoverable ? CalendarOpen : CalendarLock}
                          className="calendar-6"
                          alt="calendar"
                        />
                      </div>
                      {/* {qt.hoverable ? <div className="">{qt.status === 'draft' ? DraftIcon : CheckMark} </div> : ""} */}
                      <div className="qtr-text">{qt.text}</div>
                      <div className="qtr-month">{qt.period}</div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  }

  function adhocReporting2(adhocData) {
    setCreatingAdhocReport(true);
    setCurrentAdhocSummary('');
    // setAdhocStep('');
    setReportingModalState('loading');
    setCurrentAdhocReport('');
    setModal('adhoc');
    setCurrentAdhocSummary(adhocData);
    getFacilityAdhocReport(adhocData.facilityId, adhocData.reportId)
      .then(({ result: res2 }) => {
        setCurrentAdhocReport(res2);
        setReportingModalState('');
      })
      .then(() => setAdhocStep('adhocReporting'));

    setCreatingAdhocReport(false);
  }

  function adhocReporting(adhocData) {
    return function() {
      if (adhocData) {
        setCurrentAdhocSummary(adhocData);
        getFacilityAdhocReport(adhocData.facilityId, adhocData.reportId)
          .then(({ result: res2 }) => {
            setCurrentAdhocReport(res2);
          })
          .then(() => setAdhocStep('adhocReporting'));

        setCreatingAdhocReport(false);

        // return openNotification({
        //   type: 'success',
        //   title: 'Successful!',
        //   message: `You can now capture your data.`,
        // });
      }
    };
  }

  function recording(reportId, data) {
    return function() {
      setPage('loading');
      if (data) {
        setSamplePointRecord('');
        setSamplePointRecordData([]);
        // setReport('');
        setParameters('');
        getAndSetSamplePointRecord(data.samplePointId, reportId);
        setPage('');
        setModal('recording');
      } else {
        // or just setModal('reporting);
        setModal('');
        setSamplePointRecord('');
        setParameters('');
        setPage('');
      }
    };
  }

  function adhocRecording(reportId, adhocData) {
    return function() {
      if (adhocData) {
        setSamplePointRecord('');
        setSamplePointRecordData([]);
        // setReport('');
        setParameters('');

        getAndSetSamplePointRecord(adhocData.samplePointId, reportId);
        setModal('adhocrecording');
        setAdhocStep('adhocReporting');
        // setAdhocStep('adhocRecording');

        setCreatingAdhocReport(false);
      } else {
        setSamplePointRecord('');
        setParameters('');
        setAdhocStep('adhocReporting');
      }
    };
  }

  function navigateAdhocBreadCrumb(toWhere) {
    if (toWhere === 'root') {
      setAdhocStep('');
      setCreatingAdhocReport(false);
      setCurrentAdhocSummary('');
      setCurrentAdhocReport('');
    }
  }

  function proceedToAdhocReport(values) {
    try {
      setCreatingAdhocReport(true);
      const adhocPayload = {
        reportTitle: values['adhocReportName'],
        facilityId: record.data.facilityId,
        remarks: values['adhocReportRemark'] ? values['adhocReportRemark'] : null,
        dueDate: new Date(values['adhocReportPeriod'].format('YYYY-MM-DDTHH:mm:ssZ')),
      };
      createAdhocReport(adhocPayload)
        .then(({ result }) => {
          setCurrentAdhocSummary(result.data);
          if (result)
            getFacilityAdhocReport(record.data.facilityId, result.data.reportId)
              .then(({ result: res2 }) => {
                setCurrentAdhocReport(res2);
              })
              .then(() => setAdhocStep('adhocReporting'))
              .catch((err) => {
                return openNotification({
                  type: 'error',
                  title: 'Something went wrong!',
                  message: `$${getErrorDesc(err) ? getErrorDesc(err) : 'Failed to load report'}`,
                });
              });

          setCreatingAdhocReport(false);

          return openNotification({
            type: 'success',
            title: 'Successful!',
            message: `You can now capture your data.`,
          });
        })
        .catch((err) => {
          setCreatingAdhocReport(false);
          return openNotification({
            type: 'Error',
            title: 'Something went wrong!',
            message: `${getErrorDesc(err) ? getErrorDesc(err) : 'Failed to create report'}`,
          });
        });
    } catch (err) {
      setCreatingAdhocReport(false);
      return openNotification({
        type: 'Error',
        title: 'Something went wrong!',
        message: `${
          getErrorDesc(err)
            ? getErrorDesc(err).toLowerCase() === 'already exists'
              ? 'Report Name Already Exists'
              : getErrorDesc()
            : 'Failed to create report'
        }`,
      });
    }
  }

  function checkReportStatus(type = 'standard', adhocDatX) {
    let reportStatus = 'Draft';
    if (type !== 'adhoc' && report && report.data) reportStatus = report.data.reportStatus;
    else {
      if (adhocDatX) {
        const currDat = adhocDatX.data;
        if (currDat && currDat.some((cr) => cr.status && cr.status.toLowerCase() === 'draft')) {
          reportStatus = 'Draft';
        } else reportStatus = 'Published';
      }
    }
    return reportStatus;
  }

  // const published = checkReportStatus().toLowerCase() == 'published';
  const published = checkReportStatus().toLowerCase() === 'published';
  // const adhocPublished = checkReportStatus('adhoc', currentAdhocReport).toLowerCase() !== 'draft';
  const isAdhocPublished = (currentReport) => {
    let published = false;
    if (
      currentReport &&
      currentReport.reportStatus &&
      currentReport.reportStatus.toLowerCase() !== 'draft'
    ) {
      published = true;
    }
    return published;
  };
  const adhocPublished = isAdhocPublished(currentAdhocSummary);

  function renderAdhocModal(adhocReport) {
    if (!adhocReport)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('adhoc')} /> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );
    if (!adhocStep && adhocReport.length === 0)
      return (
        <>
          <div className="thereport__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('adhoc')} /> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
                className="sub-heading"
              >
                You have not created any adhoc report this year.
              </Col>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
                className="sub-heading"
              >
                Click the button below to create one
              </Col>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
              >
                <Button
                  type="primary"
                  appearance="default"
                  onClick={() => setAdhocStep('createNewAdhocReport')}
                >
                  Create Adhoc Report
                </Button>
              </Col>
            </Row>
          </div>
        </>
      );

    if (!adhocStep && adhocReport.length > 0) {
      return (
        <>
          {/* <div className="theadhocreport__body">
            <div className="arrow-back" onClick={() => handleCancel('adhoc')} />
          </div> */}
          <Carousel>
            {adhocReport.map((adr, index) => {
              if (index % 4 === 0) {
                return (
                  <div key={`${index}${adr.reportId}`}>
                    <Row gutter={16} style={{ marginTop: '1.5rem', overflowX: 'unset' }}>
                      {adhocRowContent(adhocReport, index, adr.reportId)}
                    </Row>
                  </div>
                );
              }
            })}
          </Carousel>
          <Row>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
              className="add-new-adhoc-btn"
            >
              <Button
                type="primary"
                appearance="default"
                onClick={() => setAdhocStep('createNewAdhocReport')}
              >
                <Tooltip title="Create new adhoc report">
                  <PlusIcon />
                </Tooltip>
                {/* <PlusIcon onClick={() => setAdhocStep('createNewAdhocReport')}/> */}
              </Button>
            </Col>
          </Row>
        </>
      );
    }

    if (adhocStep === 'createNewAdhocReport')
      return (
        <>
          <div className="adhoc-thereport__body">
            <Breadcrumb
              separator=""
              className="onreporting-modal__breadcrumb"
              style={{ marginTop: '-10px', marginBottom: 10 }}
            >
              <Breadcrumb.Item
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  navigateAdhocBreadCrumb('root');
                }}
                className="clickable-breadcrumb"
              >{`Adhoc Reports`}</Breadcrumb.Item>
              <Breadcrumb.Separator>:</Breadcrumb.Separator>
              <Breadcrumb.Item>Create Adhoc Report</Breadcrumb.Item>
              {/* <Breadcrumb.Separator /> */}
              {/* <Breadcrumb.Item>{`${datelize(records.data.reportQuarter)} Quarter`}</Breadcrumb.Item> */}
            </Breadcrumb>
            <Row>
              <Col span={20}>
                <Form
                  className=""
                  {...formItemLayout}
                  layout="horizontal"
                  form={form}
                  // ref={formRef}
                  initialValues={initAdhocValues}
                  onFinish={proceedToAdhocReport}
                  // onFinishFailed={onFinishFailed}
                >
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        name="adhocReportName"
                        label="Report Title"
                        rules={[{ required: true, message: 'Report Title is required!' }]}
                        style={{ marginBottom: 12, display: 'block' }}
                      >
                        <Input
                          placeholder="Give your report a name"
                          className="form-controlx"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    {/* </Row> */}
                    <Col span={10} className="pl-30">
                      <Form.Item
                        name="adhocReportPeriod"
                        // name="adhocReportPeriod"
                        label="Choose Report Date"
                        style={{ marginBottom: 12, display: 'block' }}
                      >
                        <DatePicker />
                        {/* <RangePicker disabled={[false, true]} /> */}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="adhocReportRemark"
                        label="Report Remarks (Optional)"
                        style={{ marginBottom: 20, display: 'block' }}
                      >
                        {/* <TextArea rows={4} onChange={onChange} value={value} /> */}
                        <TextArea rows={4} placeholder={'Enter your remarks here'} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={10}
                      className="pl-3"
                      style={{ alignSelf: 'end', placeContent: 'end' }}
                    >
                      <Form.Item
                        label=""
                        {...tailLayout}
                        style={{ marginBottom: '0', display: 'block' }}
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues['adhocReportName'] !== currentValues['adhocReportName']
                        }
                        className="create-adhoc-report-btn"
                      >
                        {({ getFieldValue }) => {
                          return (
                            <>
                              <Button
                                type="primary"
                                appearance="default"
                                htmlType="submit"
                                loading={Boolean(creatingAdhocReport)}
                                disabled={Boolean(!getFieldValue('adhocReportName'))}
                                style={{ marginRight: '10px' }}
                              >
                                Proceed
                              </Button>
                              <Button
                                type="secondary"
                                onClick={() => handleCancel('adhoc')}
                                disabled={Boolean(creatingAdhocReport)}
                              >
                                Cancel
                              </Button>
                            </>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      );

    if (adhocStep === 'adhocReporting' && currentAdhocReport)
      return (
        <FacilityReportingModal
          type="adhoc"
          handleCancel={handleCancel}
          currentReport={currentAdhocSummary}
          rowContent={adhocReportingRowContent}
          loading={reportingModalState === 'loading'}
          records={currentAdhocReport}
          adhocPublished={adhocPublished}
          // adhocPublished={isAdhocPublished(currentAdhocSummary)}
          back={navigateAdhocBreadCrumb}
          finalSubmitReport={() => finalSubmitReport('adhoc')}
        />
      );

    return <></>;
  }

  function renderLockingModal(lockingRecord) {
    if (!lockingRecord || lockingRecord.length < 1)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('locking')} /> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    const isLockedFirstQuarter = !isNotLocked(lockingRecord, 1);
    const isLockedSecondQuarter = !isNotLocked(lockingRecord, 2);
    const isLockedThirdQuarter = !isNotLocked(lockingRecord, 3);
    const isLockedFourthQuarter = !isNotLocked(lockingRecord, 4);

    const quarters = [
      {
        code: 'q1',
        text: 'First Quarter',
        period: 'Jan - Mar',
        hoverable: !isLockedFirstQuarter,
      },
      {
        code: 'q2',
        text: 'Second Quarter',
        period: 'Apr - Jun',
        hoverable: !isLockedSecondQuarter,
      },
      {
        code: 'q3',
        text: 'Third Quarter',
        period: 'Jul - Sep',
        hoverable: !isLockedThirdQuarter,
      },
      {
        code: 'q4',
        text: 'Fourth Quarter',
        period: 'Oct - Dec',
        hoverable: !isLockedFourthQuarter,
      },
    ];

    return (
      <>
        <div className="thereport__body">
          {/* <div className="arrow-back" onClick={() => handleCancel('locking')} /> */}
          <Row gutter={16} style={{ marginTop: '1.5rem' }}>
            {quarters.map((qt) => {
              return (
                <Col span={6} key={qt.code}>
                  {qt.hoverable ? (
                    <Popconfirm
                      title={
                        <>
                          {/* <h3>Submitting is final </h3> */}
                          <p>Are you sure you want to lock {qt.text} for QAQC?</p>
                        </>
                      }
                      onConfirm={() => locking(qt.code)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Card
                        hoverable={qt.hoverable}
                        className={`facility-card__body ${!qt.hoverable ? 'nocursor' : ''}`}
                        onClick={qt.hoverable ? () => true : () => false}
                      >
                        <div className="card-lock">
                          <div className="qtr-lock-icon">
                            {qt.hoverable ? '' : <p className="lock-text-desc">Locked for QA/QC</p>}

                            <img
                              src={!qt.hoverable ? LockRed : LockGrey}
                              className="lock-icon"
                              alt="lock"
                            />
                          </div>
                        </div>
                        <div className="card-content">
                          <div
                            className={`qtr-icon ${
                              !qt.hoverable ? 'calendar-loc' : 'calendar-grey'
                            }`}
                          >
                            <img
                              src={!qt.hoverable ? CalendarLock : CalendarGrey}
                              className="calendar-6"
                              alt="calendar"
                            />
                          </div>
                          <div className="qtr-text">{qt.text}</div>
                          <div className="qtr-month">{qt.period}</div>
                        </div>
                      </Card>
                    </Popconfirm>
                  ) : (
                    <Card
                      hoverable={qt.hoverable}
                      className={`facility-card__body ${!qt.hoverable ? 'nocursor' : ''}`}
                      onClick={qt.hoverable ? () => true : () => false}
                    >
                      <div className="card-lock">
                        <div className="qtr-lock-icon">
                          {qt.hoverable ? '' : <p className="lock-text-desc">Locked for QA/QC</p>}

                          <img
                            src={!qt.hoverable ? LockRed : LockGrey}
                            className="lock-icon"
                            alt="lock"
                          />
                        </div>
                      </div>
                      <div className="card-content">
                        <div
                          className={`qtr-icon ${!qt.hoverable ? 'calendar-loc' : 'calendar-grey'}`}
                        >
                          <img
                            src={!qt.hoverable ? CalendarLock : CalendarGrey}
                            className="calendar-6"
                            alt="calendar"
                          />
                        </div>
                        <div className="qtr-text">{qt.text}</div>
                        <div className="qtr-month">{qt.period}</div>
                      </div>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  }

  function renderSampleStatusProgress(status, type = 'standard', where) {
    const stat = status.split('/');

    if (type === 'standard' && published && stat[1]) {
      return (
        <div className="progress-published">
          <Progress type="line" percent={100} format={() => 'Published'} />
        </div>
      );
    }
    if (type === 'adhoc' && where !== 'onreporting' && adhocPublished && stat[1]) {
      return (
        <div className="progress-published">
          <Progress type="line" percent={100} format={() => 'Published'} />
        </div>
      );
    }

    const perccCalc = (parseInt(stat[0]) / parseInt(stat[1])) * 100;
    let percc = Math.round(perccCalc);
    if (percc < perccCalc) percc += 1;
    if (type === 'adhoc' && adhocPublished && stat[1]) {
      if (stat[0] >= stat[1])
        return (
          <div style={{ width: '100%', padding: '0.9px 15px' }} className="progress-notpublished">
            {/* <Progress percent={percc} steps={parseInt(stat[1])} strokeColor="#1890ff" /> */}
            Uploaded
          </div>
        );
      else {
        return (
          <div style={{ width: '100%', padding: '0.9px 15px' }} className="progress-notpublished">
            <Progress percent={percc} steps={parseInt(stat[1])} strokeColor="#1890ff" />
          </div>
        );
      }
    }

    return (
      <div style={{ width: '100%', padding: '0.9px 15px' }} className="progress-notpublished">
        <Progress percent={percc} steps={parseInt(stat[1])} strokeColor="#1890ff" />
      </div>
    );
  }

  function recording(reportId, data) {
    return function() {
      setPage('loading');
      if (data) {
        setSamplePointRecord('');
        setSamplePointRecordData([]);
        // setReport('');
        setParameters('');
        getAndSetSamplePointRecord(data.samplePointId, reportId);
        setPage('');
        setModal('recording');
      } else {
        // or just setModal('reporting);
        setModal('');
        setSamplePointRecord('');
        setParameters('');
        setPage('');
      }
    };
  }

  function finalSubmitReport(type) {
    let publishedX = published;
    if (type === 'adhoc') publishedX = adhocPublished;

    if (publishedX) {
      // do this;
      reload(!load);
      return;
    }
    let rId;
    if (type === 'adhoc') rId = currentAdhocSummary.reportId;
    else rId = report.data.reportId;
    publishFacilityReport(rId)
      .then(() => {
        // setRecord('');
        // backToReporting(report.qtr);
        // refetch report
        if (type !== 'adhoc') {
          const updatedReport = { ...report };
          updatedReport.data.reportStatus = 'PUBLISHED';
          setReport(updatedReport);

          return openNotification({
            type: 'success',
            title: 'Successful',
            message: `Report for ${report.data.facility} is published!`,
          });
        } else {
          const updatedAdhocReport = { ...currentAdhocSummary };
          updatedAdhocReport.reportStatus = 'PUBLISHED';
          setCurrentAdhocSummary(updatedAdhocReport);

          return openNotification({
            type: 'success',
            title: 'Successful',
            message: `${currentAdhocSummary.reportTitle} report is published!`,
          });
        }
      })
      .catch((err) => {
        return openNotification({
          type: 'Error',
          title: 'Something went wrong!',
          message: `${
            err && typeof err === 'string'
              ? err
              : err && err.result && err.result.status.desc
              ? err.result.status.desc
              : 'Failed to publish report.'
          }`,
        });
      });
  }

  function generateCol(paramtr, pLen) {
    let colspan = Math.round(20 / pLen);
    if (colspan > 3) colspan = 3;
    if (colspan < 2) colspan = 2;

    const content = [];

    let siUnite = parseString(`${paramtr.siUnit}`);
    if (siUnite && siUnite.some((siunt) => typeof siunt !== 'string'))
      siUnite = parseString4(parseString3(`${paramtr.siUnit}`));
    if (
      (siUnite && siUnite.length >= 5) ||
      (paramtr.unit && parseString4(parseString3(paramtr.unit)).length > 7)
    )
      colspan = 2;

    let colContent;
    if (parseString(paramtr.unit).length < 4) {
      colContent = (
        <Col span={3} key={paramtr.unit}>
          <Form.Item
            name={paramtr.unit}
            label={parseString(paramtr.unit)}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <InputNumber
              formatter={(value) => `${setFormatValue(value)}`}
              placeholder={`${siUnite}`}
              className="form-controlx"
            />
          </Form.Item>
        </Col>
      );
    } else if (parseString(paramtr.unit).length < 5) {
      colContent = (
        <Col
          span={colspan}
          key={paramtr.unit}
          className={colspan === 3 ? 'spanning-3' : 'spanning-2'}
        >
          <Form.Item
            name={paramtr.unit}
            label={parseString(paramtr.unit)}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <InputNumber
              formatter={(value) => `${setFormatValue(value)}`}
              placeholder={`${siUnite}`}
              className="form-controlx"
            />
          </Form.Item>
        </Col>
      );
    } else {
      colContent = (
        <Col
          // span={colspan}
          key={paramtr.unit}
          className={colspan === 3 ? 'spanning-3' : 'spanning-2'}
        >
          <Form.Item
            name={paramtr.unit}
            label={parseString(paramtr.unit)}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <InputNumber
              formatter={(value) => `${setFormatValue(value)}`}
              placeholder={`${siUnite}`}
              className="form-controlx"
            />
          </Form.Item>
        </Col>
      );
    }
    content.push(colContent);
    return content;
  }

  function rowContent(spt, index, reportId) {
    let times = 0;
    let start = index;
    const content = [];
    while (times < 8 && times < spt.length - index) {
      const sampStatus = spt[start] || { resultCount: 0, checkCount: 1 };
      const samplStatus = `${sampStatus.resultCount}/${sampStatus.checkCount}`;
      const newCol = (
        <Col span={6} key={spt[start].samplePointId}>
          <Card
            hoverable={true}
            className="facility-card__body"
            style={{ backgroundColor: generateRadomColor(), marginBottom: '1rem' }}
            onClick={recording(reportId, spt[start])}
          >
            <div className="card-content">
              <div className="qtr-icon">
                <img
                  src={spt[start].icon ? spt[start].icon : WindPowerIcon}
                  className="calendar-6"
                  alt="calendar"
                />
              </div>
              <div className="qtr-subtext">{spt[start].samplePointName}</div>
              <div className="qtr-text">{`(${
                spt[start].groupName
                  ? spt[start].groupName
                  : spt[start].measurementName
                  ? spt[start].measurementName
                  : 'Sample Point Type'
              })`}</div>

              <div className={`qtr-progress ${published ? 'qtr-published' : ''}`}>
                {renderSampleStatusProgress(samplStatus)}
              </div>
            </div>
          </Card>
        </Col>
      );

      content.push(newCol);

      start++;
      times++;
    }
    return content;
  }

  function adhocReportingRowContent(spt, index, reportId) {
    let times = 0;
    let start = index;
    const content = [];
    while (times < 8 && times < spt.length - index) {
      const sampStatus = spt[start] || { resultCount: 0, checkCount: 1 };
      const samplStatus = `${sampStatus.resultCount}/${sampStatus.checkCount}`;
      const newCol = (
        <Col span={6} key={spt[start].samplePointId}>
          <Card
            hoverable={true}
            className="facility-card__body"
            style={{ backgroundColor: generateRadomColor(), marginBottom: '1rem' }}
            onClick={adhocRecording(reportId, spt[start])}
          >
            <div className="card-content">
              <div className="qtr-icon">
                <img
                  src={spt[start].icon ? spt[start].icon : WindPowerIcon}
                  className="calendar-6"
                  alt="calendar"
                />
              </div>
              <div className="qtr-subtext">{spt[start].samplePointName}</div>
              <div className="qtr-text">{`(${
                spt[start].groupName
                  ? spt[start].groupName
                  : spt[start].measurementName
                  ? spt[start].measurementName
                  : 'Sample Point Type'
              })`}</div>

              <div
                className={`qtr-progress ${
                  spt[start]['status'].toLowerCase() !== 'draft' ? 'qtr-published' : ''
                }`}
              >
                {renderSampleStatusProgress(samplStatus, 'adhoc', 'onreporting')}
                {/* {spt[start]['status']} */}
              </div>
            </div>
          </Card>
        </Col>
      );

      content.push(newCol);

      start++;
      times++;
    }
    return content;
  }

  function getAdhocReportStatus(spt) {
    if (spt && spt.reportStatus) return spt.reportStatus;
  }

  function adhocRowContent(spt, index, reportId) {
    let times = 0;
    let start = index;
    const content = [];
    while (times < 4 && times < spt.length - index) {
      // const sampStatus = spt[start] || { resultCount: 0, checkCount: 1 };
      // const samplStatus = `${sampStatus.resultCount}/${sampStatus.checkCount}`;
      const newCol = (
        <Col span={6} key={spt[start].reportId}>
          <Card
            hoverable={true}
            className="facility-card__body"
            style={{ backgroundColor: generateRadomColor(), marginBottom: '1rem' }}
            onClick={adhocReporting(spt[start])}
          >
            <div className="card-content">
              {/* <div className="qtr-icon">
                <img
                  src={spt[start].icon ? spt[start].icon : WindPowerIcon}
                  className="calendar-6"
                  alt="calendar"
                />
              </div> */}
              <div className="qtr-subtext">{spt[start].reportTitle}</div>
              {/* <div className="qtr-text">{`(${
                spt[start].groupName
                  ? spt[start].groupName
                  : spt[start].measurementName
                  ? spt[start].measurementName
                  : 'Sample Point Type'
              })`}</div> */}

              <div
                className={`qtr-progress ${
                  spt[start].reportStatus.toLowerCase() === 'draft' ? '' : 'qtr-published'
                }`}
              >
                {getAdhocReportStatus(spt[start])}
              </div>
              <div className="qtr-month">{`${formatDate(
                new Date(spt[start].dueDate),
                'LLL do, yyyy'
              )}`}</div>
            </div>
          </Card>
        </Col>
      );

      content.push(newCol);

      start++;
      times++;
    }
    return content;
  }

  function notEverySamplePointCompleted(spt) {
    let disable = true;
    if (spt && spt.length > 0) {
      disable = !spt.every((sp) => sp.resultCount >= sp.checkCount);
    }
    return disable;
  }

  function renderReportingModal(records) {
    if (!records || records.length < 1)
      return (
        <>
          <div className="facility-report__body reporting-modal">
            {/* <div className="arrow-back" onClick={() => handleCancel('reporting')} /> */}
            {/* <Row gutter={16} type="flex" align="end" justify="end">
              <CloseIcon
                className="close-icon report-viewing"
                onClick={() => handleCancel('reporting')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    const { samplePoints } = records.data || [];
    const len = samplePoints && samplePoints.length ? samplePoints.length : 0;
    return (
      <>
        <div className="facility-report__body onreporting-modal">
          {/* {isConsultant && ( */}
          <Breadcrumb separator="" className="onreporting-modal__breadcrumb">
            <Breadcrumb.Item>{`${properCase(records.data.type)} Report`}</Breadcrumb.Item>
            <Breadcrumb.Separator>:</Breadcrumb.Separator>
            <Breadcrumb.Item href="" onClick={backToReportingQtr} className="clickable-breadcrumb">
              Quarters
            </Breadcrumb.Item>
            <Breadcrumb.Separator />
            <Breadcrumb.Item>{`${datelize(records.data.reportQuarter)} Quarter`}</Breadcrumb.Item>
          </Breadcrumb>
          {/* )} */}
          <Carousel>
            {samplePoints &&
              samplePoints.length > 0 &&
              samplePoints.map((spt, index) => {
                if (index % 8 === 0) {
                  return (
                    <div key={`${index}${spt.samplePointId}`}>
                      <Row gutter={16} style={{ marginTop: '1.5rem', overflowX: 'unset' }}>
                        {rowContent(samplePoints, index, records.data.reportId)}
                      </Row>
                    </div>
                  );
                }
              })}
          </Carousel>
          <div className={`submitBtnReport ${len < 5 ? 'submitBtnReporting' : ''}`}>
            <Form
              {...formItemLayout}
              // form={form}
              layout="horizontal"
              onFinish={() => {}}
              className="facility-reporting__form submit-report"
            >
              {!published && (
                <Row type="flex" justify="start" className="text-left">
                  <Col span={3}>
                    <Button
                      type="secondary"
                      className="back-button"
                      onClick={() => handleCancel('reporting')}
                      style={{ width: 'max-content' }}
                    >
                      Save draft
                    </Button>
                  </Col>
                  <Col span={2}>
                    <Popconfirm
                      title={
                        <>
                          <h3>Submitting is final </h3>
                          <p>Are you sure you want to publish this report?</p>
                        </>
                      }
                      onConfirm={finalSubmitReport}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        appearance="default"
                        htmlType="submit"
                        disabled={notEverySamplePointCompleted(samplePoints) || !canPublishReport}
                      >
                        Submit
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              )}
              {published && (
                <Row type="flex" justify="start" className="text-left">
                  <Col span={2}>
                    <Button
                      type="secondary"
                      className="back-button"
                      onClick={() => handleCancel('reporting')}
                    >
                      Exit
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </div>
      </>
    );
  }

  function renderRecordingModal(records, type) {
    let publishedX = published;
    if (type === 'adhoc') publishedX = adhocPublished;
    if (!records || !records.data)
      return (
        <>
          <div className="facility-report__body">
            {/* <div
              className="arrow-back"
              onClick={() =>
                type === 'adhoc' ? handleCancel('recording') : handleCancel('adhoc-recording')
              }
            /> */}
            {/* <Row gutter={16} type="flex" justify="end" align="end">
              <CloseIcon
                className="close-icon facility-viewing"
                onClick={() => handleCancel('adhoc-recording')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    const { samplePointName, groupName: samplePointType, minEntry } = records.data || '';
    let paramLen = 10;
    let formBottomCol = 20;
    if (parameters && parameters.length > 1) paramLen = parameters.length;
    if (paramLen <= 4) formBottomCol = 12;

    const takeSampleData = (recod) => {
      const safeRecod = {
        entryDate: new Date(),
      };

      parameters.map((prmta) => {
        safeRecod[prmta.unit] = recod[prmta.unit] || 0;
        sampleDataAddFormRef.current.setFieldsValue({ [prmta.unit]: '' });
      });

      const newSptRecData = [...samplePointRecordData];
      newSptRecData.push(safeRecod);

      setSamplePointRecordData(newSptRecData);
    };

    return records && records.data ? (
      <>
        <div className="facility-reporting__body onrecording-modal">
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }} type="flex" justify="start" align="middle">
            <Col span={24} offset={1}>
              <Row
                type="flex"
                justify="start"
                className="text-left"
                style={{ marginBottom: '10px' }}
              >
                <Col
                  span={5}
                  className="recording-form__heading recording-form__heading"
                  style={{
                    overflowX: 'unset',
                    wordBreak: 'break-word',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Text type="strong" className="samplePointName">
                    {samplePointName}
                  </Text>
                </Col>
                <Col
                  span={9}
                  className="reporting-form__subheading recording-form__subheading"
                  style={{ overflowX: 'unset', textOverflow: 'ellipsis' }}
                >
                  <Text
                    type="primary"
                    style={{
                      overflowX: 'unset',
                      wordBreak: 'break-word',
                      textOverflow: 'ellipsis',
                      color: '#0cb394 !important',
                    }}
                  >
                    {samplePointType ? `(${samplePointType})` : '(Not Available)'}
                  </Text>
                </Col>
                <Col
                  span={9}
                  className="reporting-form__subheading"
                  style={{ alignSelf: 'end', textAlign: 'right' }}
                >
                  {/* {type !== 'adhoc' && */}
                  {renderSampleStatusProgress(`${samplePointRecordData.length}/${minEntry}`, type)}
                  {/* {type === 'adhoc' && publishedX ? 'Published' : ''} */}
                </Col>
              </Row>
              {/* {type !== 'adhoc' && ( */}
              <Row
                type="flex"
                justify="start"
                className="text-left"
                style={{ marginBottom: '10px' }}
              >
                <Col span={3} className="card-lock-col">
                  <div className="card-lock" style={{ display: 'block' }}>
                    <p>{`Min Runs: ${minEntry}`}</p>
                  </div>
                </Col>
              </Row>
              {/* )} */}

              {!publishedX && canMakeFacilityReport && (
                <Form
                  {...formItemLayout}
                  form={form}
                  ref={sampleDataAddFormRef}
                  layout="horizontal"
                  scrollToFirstError
                  onFinish={takeSampleData}
                  onFinishFailed={onFinishFailed}
                  className="facility-reporting__form"
                >
                  <Row
                    gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
                    style={{ marginTop: '0.3rem', textAlign: 'left' }}
                    type="flex"
                    justify="start"
                    align="middle"
                  >
                    <Col
                      span={formBottomCol}
                      style={{
                        overflowX: `${paramLen <= 12 ? 'hidden' : 'scroll'}`,
                        width: '90%',
                        paddingLeft: '0px',
                      }}
                    >
                      <Row
                        type="inline-flex"
                        style={{
                          // overflowX: `${paramLen <= 12 ? 'hidden' : 'scroll'}`,
                          overflowX: 'auto',
                          // width: `${paramLen > 12 ? `${(paramLen - 12) * 15 + 100}%` : '100%'}`,
                          flexWrap: 'nowrap',
                        }}
                      >
                        {parameters &&
                          parameters.map((pramtr) => generateCol(pramtr, parameters.length))}
                      </Row>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        {...tailLayout}
                        style={{ marginTop: '1em', marginBottom: '1em' }}
                        shouldUpdate={(_, currentValues) =>
                          parameters &&
                          parameters.every(
                            (pt) => !!currentValues[pt.unit] || Number(currentValues[pt.unit]) === 0
                          )
                        }
                        // shouldUpdate={(prevValues, currentValues) =>
                        //   parameters &&
                        //   !parameters.every((pt) => prevValues[pt.unit] === currentValues[pt.unit])
                        // }
                      >
                        {({ getFieldValue }) => {
                          const noParamterValue = () => {
                            if (!parameters) return true;
                            const someNull = parameters.some(
                              (pt) =>
                                !getFieldValue(pt.unit) && parseInt(getFieldValue(pt.unit)) !== 0
                              // isNaN(getFieldValue(pt.unit))
                            );
                            return someNull;
                          };
                          return (
                            <Button
                              type="primary"
                              appearance="default"
                              htmlType="submit"
                              disabled={noParamterValue()}
                              className="sample-record-add-btn"
                            >
                              {samplePointRecordData.length === 0 ? 'Add' : 'Add'}
                            </Button>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
              <Row type="flex" justify="start" className="text-left">
                <Col span={22} className="recording-form__heading">
                  {!publishedX && (
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                  )}
                  {publishedX && <p className="header mb-2rem">{''}</p>}
                </Col>
              </Row>
              <Row
                type="flex"
                justify="start"
                className="text-left"
                style={{ marginBottom: '1rem' }}
              >
                <Col
                  span={22}
                  className={`recording-form__heading recording-form__table ${
                    publishedX ? 'published' : ''
                  }`}
                >
                  <FacilityUploadTable
                    userCategory={userCategory}
                    userPermission={userPerm}
                    data={samplePointRecordData}
                    setData={setSamplePointRecordData}
                    parameters={parameters}
                    tableType={publishedX ? 'published-view' : ''}
                    published={publishedX}
                  />
                </Col>
              </Row>

              <div
                className={`submitBtnRecord ${
                  samplePointRecordData.length > 0 ? 'submitBtnRecording' : ''
                }`}
              >
                <Form
                  {...formItemLayout}
                  layout="horizontal"
                  scrollToFirstError
                  onFinish={() => saveSamplePointData(type)}
                  onFinishFailed={onFinishFailed}
                  className="facility-reporting__form"
                >
                  {!publishedX && (
                    <Row type="flex" justify="start" className="text-left">
                      <Col>
                        <Button
                          type="secondary"
                          className="back-button"
                          onClick={() => {
                            if (type === 'adhoc') handleCancel('adhoc-recording');
                            else setModal('reporting');
                          }}
                        >
                          Back
                        </Button>
                      </Col>
                      {canMakeFacilityReport && (
                        <Col>
                          <Button
                            type="primary"
                            appearance="default"
                            htmlType="submit"
                            disabled={!samplePointRecordData && !samplePointRecordData.length > 0}
                          >
                            Save
                          </Button>
                        </Col>
                      )}
                    </Row>
                  )}
                  {publishedX && (
                    <Row type="flex" justify="start" className="text-left">
                      <Col span={2}>
                        <Button
                          type="secondary"
                          className="back-button"
                          onClick={() => {
                            if (type === 'adhoc') handleCancel('adhoc-recording');
                            else setModal('reporting');
                          }}
                        >
                          Back
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </>
    ) : (
      ''
    );
  }

  function renderViewingModal(records) {
    if (!records || !records.data)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('viewing')} /> */}
            <Row gutter={16}>
              <CloseIcon
                className="close-icon facility-viewing"
                onClick={() => handleCancel('viewing')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row>
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    return (
      <>
        <div className="facility-reporting__body onviewing-modal onrecording-modal">
          {/* <div className="arrow-back" onClick={() => handleCancel('viewing')} /> */}
          <CloseIcon
            className="close-icon report-viewing"
            onClick={() => handleCancel('viewing')}
            style={{
              marginBottom: '1rem',
              marginTop: '0.8rem',
            }}
          />
          <FacilityView record={records.data} />
        </div>
      </>
    );
  }

  function renderCertViewingModal(records) {
    let bas64 = records.data.eiaCert;
    const fileType = bas64 ? bas64.substring(5).split(';')[0] : '';
    if (!records || !records.data)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('viewing')} /> */}
            <Row gutter={16}>
              <CloseIcon
                className="close-icon facility-viewing"
                onClick={() => handleCancel('certviewing')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row>
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );
    if (!records.data.eiaCert)
      return (
        <>
          <div className="thereport__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('adhoc')} /> */}
            <Row
              gutter={16}
              style={{
                marginTop: '1.5rem',
              }}
            >
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
                className="sub-heading bold"
              >
                No Certicate Found for this Facility.
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
                className="sub-heading"
              >
                Click the button below to upload EIA certificate.
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                }}
              >
                <Upload {...generateUploadProps('new')} className="cert-upload-btn">
                  <Button appearance="default" type="primary">
                    <UploadOutlined /> Upload Certificate
                  </Button>
                </Upload>
              </Col>
            </Row>
          </div>
        </>
      );
    return (
      <>
        <div className="facility-reporting__body onviewing-modal certview-modal">
          <object data={bas64} width="100%" height="100%" />
        </div>
      </>
    );
  }

  function refreshPage() {
    window.location.reload();
  }

  const filterObjs = [
    {
      label: 'Filter by facility status',
      name: 'status',
      placeholder: 'Filter by status',
      title: 'All Facility Status',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by sector',
      name: 'sectorName',
      placeholder: 'Filter by sectors',
      title: 'All Sectors',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by states',
      name: 'stateName',
      placeholder: 'Filter by state',
      title: 'All States',
      condition: true,
      className: '',
    },
    // {
    //   label: 'Filter by facility',
    //   name: 'facilityName',
    //   placeholder: 'Filter by facility name',
    //   title: 'All Facilities',
    //   condition: true,
    //   className: '',
    // },
    {
      label: 'Filter by consultant',
      name: 'facilityConsultant',
      placeholder: 'Filter by consultant',
      title: 'All Consultants',
      condition: isNotConsultant,
      className: '',
    },
    {
      label: 'Filter by EIA Status',
      name: 'facilityEia',
      placeholder: 'Filter by EIA status',
      title: 'All EIA Status',
      condition: isNotConsultant,
      className: '',
    },
  ];

  const filteringStates = [
    consultantFilter,
    facilityFilter,
    statusFilter,
    sectorFilter,
    stateFilter,
    eiaFilter,
  ];

  const filteringActions = [
    setConsultantFilter,
    setFacilityFilter,
    setStatusFilter,
    setSectorFilter,
    setStateFilter,
    setEiaFilter,
  ];

  const isItFiltering = () => {
    return filteringStates.every((ftState) => {
      return Boolean(ftState) === false;
    });
  };

  const toggleFilter = () => setFiltering(!filtering);

  const clearFilter = () => {
    filterFormRef.current.resetFields();
    return filteringStates.forEach((ftState, index) => ftState && filteringActions[index](''));
  };

  const filterOnChange = (val, which) => {
    switch (which) {
      case 'facilityConsultant':
        setConsultantFilter(String(val).trim());
        break;
      case 'facilityName':
        setFacilityFilter(val);
        break;
      case 'stateName':
        setStateFilter(val);
        break;
      case 'sectorName':
        setSectorFilter(val);
        break;
      case 'status':
        setStatusFilter(val);
        break;
      case 'facilityEia':
        setEiaFilter(val);
        break;

      default:
        break;
    }
  };

  const filterOnSearch = (val, which) => {
    switch (which) {
      case 'facilityConsultant':
        setConsultantFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'facilityName':
        setFacilityFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'stateName':
        setStateFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'sectorName':
        setSectorFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'status':
        setStatusFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'facilityEia':
        setEiaFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;

      default:
        break;
    }
  };

  const displayOptions = (list, which) => {
    let key = list;
    let { [key]: name } = { facilities };
    let uniques;

    if (name && name.length > 0) {
      if (which === 'facilityConsultant') {
        uniques = [
          ...new Set(
            name.map((item) =>
              item[which]
                ? properCase(`${item[which][0].firstName} ${item[which][0].lastName}`)
                : 'Not Available'
            )
          ),
        ];
      } else uniques = [...new Set(name.map((item) => item[which]))];

      uniques.sort((a, b) => {
        if (typeof a !== 'object') return a > b ? 1 : b > a ? -1 : 0;
        return a[which].localeCompare(b[which]);
      });

      return uniques.map((item, index) => {
        if (which === 'status') {
          return (
            <Option value={item} key={index}>
              {String(item) === '1' ||
              String(item)
                .trim()
                .toLowerCase() == 'active'
                ? 'ACTIVE'
                : 'INACTIVE'}
            </Option>
          );
        }
        if (which === 'facilityEia') {
          return (
            <Option value={item} key={index}>
              {String(item)
                .trim()
                .toLowerCase() === 'yes'
                ? 'ACTIVE'
                : 'INACTIVE'}
            </Option>
          );
        }
        return (
          <Option value={item} key={index}>
            {which === 'reportQuarter' ? `${datelize(item)} Quarter` : item}
          </Option>
        );
      });
    }
  };

  const filterContent = (
    <Form
      className={`filtering-search`}
      {...formItemLayout}
      layout="horizontal"
      ref={filterFormRef}
    >
      <Row>
        {filterObjs.map((fltO) => {
          return fltO.condition ? (
            <Col span={10} key={fltO.name}>
              <Form.Item
                label={fltO.label}
                name={fltO.name}
                className={fltO.className}
                style={{ marginBottom: '10px', display: 'inline-block' }}
              >
                <Select
                  showSearch
                  style={{ width: '12.8em' }}
                  placeholder={fltO.placeholder}
                  optionFilterProp="children"
                  onChange={(value) => filterOnChange(value, `${fltO.name}`)}
                  onSearch={(value) => filterOnSearch(value, `${fltO.name}`)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="">{`${fltO.title}`}</Option>
                  {displayOptions('facilities', `${fltO.name}`)}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ''
          );
        })}
      </Row>
    </Form>
  );

  function searchTable(args) {
    const max = 100000;
    if (args) {
      setSearch(true);
      setIsSearching(true);
      getFacilitiesLists(`?search=${args}&start=0&stop=${max}`, 'search');
    } else {
      setSearch(true);
      setIsSearching(true);
      getFacilitiesLists();
      // setSearch(false);
    }
  }

  return (
    <>
      <PageHeader split={true} className="facility-header__main">
        {page !== 'error' && page !== 'loading' && (facilities.length > 0 || search) && (
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
            type="flex"
            align="end"
            justify="end"
            style={{ width: '100%' }}
          >
            <Col style={{ align: 'start', justify: 'start', flex: 'auto' }}>
              {canCreateFacility && (
                <div className="buttons left create-fac-btn">
                  <Button appearance="blue" size="small" onClick={createFacility}>
                    <PlusIcon /> New Facility
                  </Button>
                </div>
              )}
            </Col>
            <Col>
              <Filter
                onClick={toggleFilter}
                content={filterContent}
                isFiltering={!isItFiltering()}
                clearFilter={clearFilter}
              />
              <SearchInput
                placeholder="Search facilities"
                searchTable={searchTable}
                className="ml-1rem"
              />
            </Col>
          </Row>
        )}
      </PageHeader>

      <PageContent>
        {(page !== 'loading' && page !== 'error' && !facilities && !search) ||
          (page !== 'error' && facilities.length < 1 && !search && (
            <>
              <Row type="flex" justify="center" align="middle" className="text-center">
                <Col
                  xs={{
                    span: 16,
                  }}
                  lg={{
                    span: 12,
                  }}
                >
                  <FacilitiesIcon className="icon" />
                </Col>
              </Row>

              <div>
                <Row type="flex" justify="center" align="middle" className="text-center">
                  <Col
                    xs={{
                      span: 12,
                    }}
                    lg={{
                      span: 8,
                    }}
                  >
                    <EmptyContent className="no-content">
                      <h1 className="no-content-header">No facilities created yet</h1>
                      <p className="no-content-text">
                        {`There are currently no facilities on the IEMS platform. ${
                          !canCreateFacility
                            ? ''
                            : 'Click the button below to start creating new facilities.'
                        }`}
                      </p>
                      {canCreateFacility && (
                        <div className="buttons">
                          <Button
                            appearance="blue"
                            size="small"
                            onClick={createFacility}
                            disabled={!canCreateFacility}
                          >
                            <PlusIcon /> New Facility
                          </Button>
                        </div>
                      )}
                    </EmptyContent>
                  </Col>
                </Row>
              </div>
            </>
          ))}

        {page === 'error' && (
          <>
            <Row type="flex" justify="center" align="middle" className="text-center">
              <Col
                xs={{
                  span: 16,
                }}
                lg={{
                  span: 12,
                }}
              >
                <FacilitiesIcon className="icon" />
              </Col>
            </Row>

            <div>
              <Row type="flex" justify="center" align="middle" className="text-center">
                <Col
                  xs={{
                    span: 12,
                  }}
                  lg={{
                    span: 8,
                  }}
                >
                  <EmptyContent className="no-content">
                    <h1 className="no-content-header">Something's wrong!</h1>
                    <p className="no-content-text">We are having issues loading facilities</p>
                    <div className="buttons">
                      <Button type="secondary" onClick={refreshPage}>
                        TRY AGAIN
                      </Button>
                    </div>
                  </EmptyContent>
                </Col>
              </Row>
            </div>
          </>
        )}
        {page === 'loading' && page !== 'error' && <LoadingContent />}
        {page !== 'loading' && page !== 'error' && facilities && (facilities.length > 0 || search) && (
          <div className="facility-table">
            <FacilitiesTable
              userCategory={userCategory}
              userPerm={userPerm}
              data={facilities}
              setRecord={setRecord}
              setModal={setModal}
              loading={isSearching}
              consultantFilter={consultantFilter}
              facilityFilter={facilityFilter}
              statusFilter={statusFilter}
              sectorFilter={sectorFilter}
              stateFilter={stateFilter}
              eiaFilter={eiaFilter}
              setPage={setPage}
            />
          </div>
        )}
        {page !== 'loading' &&
          page !== 'error' &&
          (canViewReport || canCreateFacilityReport) &&
          modal === 'selectqtr' && (
            <Modal
              visible={true || !!record.data}
              title={renderModalHeader(record, 'selecting')}
              footer={null}
              centered={true}
              onOk={handleOk}
              onCancel={() => handleCancel('selecting')}
              className="facility-report__standard"
            >
              {renderSelectModal(facilityRecord)}
            </Modal>
          )}
        {page !== 'loading' && page !== 'error' && canRecordReport && modal === 'recording' && (
          <Modal
            visible={true || !!record.data}
            title={renderModalHeader(samplePointRecord, 'recording', 'reporting')}
            footer={null}
            onOk={handleOk}
            centered={true}
            className="facility-report__standard recording"
          >
            {renderRecordingModal(samplePointRecord, 'standard')}
          </Modal>
        )}
        {page !== 'loading' && page !== 'error' && canRecordReport && modal === 'adhocrecording' && (
          <Modal
            visible={true || !!record.data}
            title={renderModalHeader(samplePointRecord, 'adhocrecording', 'adhoc-recording')}
            footer={null}
            onOk={handleOk}
            centered={true}
            className="facility-report__standard recording"
          >
            {renderRecordingModal(samplePointRecord, 'adhoc')}
          </Modal>
        )}
        {page !== 'loading' && page !== 'error' && canViewReport && modal === 'viewing' && (
          <Modal
            visible={!!record.data}
            title={renderModalHeader(record, 'viewing')}
            footer={null}
            maskClosable={false}
            centered={true}
            keyboard={false}
            onOk={handleOk}
            onCancel={() => handleCancel('viewing')}
            className="facility-report__standard recording fac-viewing"
          >
            {renderViewingModal(record)}
          </Modal>
        )}
        {page !== 'loading' && page !== 'error' && canViewReport && modal === 'viewingcert' && (
          <Modal
            visible={!!record.data}
            title={renderModalHeader(record, 'viewingcert')}
            footer={null}
            maskClosable={false}
            centered={true}
            keyboard={false}
            onOk={handleOk}
            onCancel={() => handleCancel('viewing')}
            className={`facility-report__standard recording fac-cert-viewing ${
              record.data.eiaCert ? '' : 'subY22'
            }`}
          >
            {renderCertViewingModal(record)}
          </Modal>
        )}
        {page !== 'loading' &&
          page !== 'error' &&
          canViewReport &&
          canViewRecordedReport &&
          modal === 'reporting' && (
            <Modal
              visible={true || !!report.data}
              title={renderModalHeader(report, 'reporting')}
              footer={null}
              onOk={handleOk}
              onCancel={() => handleCancel('reporting')}
              className="facility-report__standard reporting"
            >
              {renderReportingModal(report)}
            </Modal>
          )}
        {page !== 'loading' && page !== 'error' && canLockReport && modal === 'locking' && (
          <Modal
            visible={true || !!record.data}
            title={renderModalHeader(record, 'locking')}
            footer={null}
            centered={true}
            onOk={handleOk}
            onCancel={() => handleCancel('locking')}
            className="facility-report__standard"
          >
            {renderLockingModal(facilityRecord)}
          </Modal>
        )}
        {page !== 'loading' && page !== 'error' && canCreateAdhocReport && modal === 'adhoc' && (
          <Modal
            visible={true || !!record.data}
            title={renderModalHeader(record, 'adhoc')}
            footer={null}
            onOk={handleOk}
            centered={true}
            onCancel={() => handleCancel('adhoc')}
            className={`facility-report__standard adhoc ${
              adhocStep === 'createNewAdhocReport'
                ? 'add-x2'
                : adhocStep === 'adhocReporting'
                ? 'add-x5'
                : ''
            }`}
          >
            {renderAdhocModal(adhocReport)}
          </Modal>
        )}
      </PageContent>
    </>
  );
}
