import React, { useState } from 'react';
import { Row, Col, Form, message } from 'antd';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import Select from 'components/common/Select';
import LocationInput from 'components/common/LocationInput';
import { createState } from 'helpers/api';
import { properCase, checkLatitude, checkLongitude } from 'helpers/utils';

const Option = Select;

const AddStateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const handleAddState = (values) => {
    const payload = {
      stateName: properCase(values.stateName),
      stateCapital: properCase(values.stateCapital),
      zoneId: values.zoneId,
      coordinates: {
        latitude: values.latitude ? values.latitude.loc : 0,
        longitude: values.longitude ? values.longitude.loc : 0,
      },
    };
    setLoading(true);
    createState(payload)
      .then((res) => {
        message.success(`${properCase(values.stateName)} State created successfully!`);
        setLoading(false);
        props.reload();
        props.closeModal();
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
        // props.closeModal();
        //   setaddMoreLoading(false);
      });
  };

  return (
    <Form layout="vertical" className="state__lookup__form" onFinish={handleAddState}>
      <Row style={{ marginBottom: '0px' }}>
        <Col span={24}>
          <Form.Item
            label="State Zone"
            name="zoneId"
            rules={[{ required: true, message: 'Please select a zone ' }]}
          >
            <Select placeholder="Select zone" className="form-controlxx">
              {props.zoneList &&
                props.zoneList.length &&
                props.zoneList.map((zone) => {
                  return (
                    <Option key={zone.zoneId} value={zone.zoneId}>
                      {zone.zoneName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '0px' }}>
        <Col span={12}>
          <Form.Item
            name="stateName"
            label="State Name"
            rules={[{ required: true, message: 'Please enter state name' }]}
          >
            <Input placeholder="Enter state name here" className="form-controlx" />
          </Form.Item>
        </Col>
        <Col span={12} className="pl-20">
          <Form.Item
            label="State Capital"
            name="stateCapital"
            rules={[{ required: true, message: "Please enter state's capital" }]}
          >
            <Input placeholder="Enter state's capital here" className="form-controlx" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="stateChord" label="State Co-ordinates">
        <Row style={{ marginBottom: '0px' }}>
          <Col span={12}>
            <Form.Item name="latitude" rules={[{ validator: checkLatitude }]}>
              <LocationInput
                type="latitude"
                placeholder="Enter Latitude"
                location={latitude}
                setLocation={setLatitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
          <span
            style={{
              display: 'inline-block',
              width: '0px',
              lineHeight: '32px',
              textAlign: 'center',
            }}
          />
          <Col span={12} style={{ textAlign: 'right' }}>
            <Form.Item
              name="longitude"
              rules={[{ validator: checkLongitude }]}
              style={{ display: 'inline-block', width: 'calc(100% - 20px)' }}
            >
              <LocationInput
                type="longitude"
                placeholder="Enter Longitude"
                location={longitude}
                setLocation={setLongitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Row>
        <Col span={24}>
          <Form.Item className="mt-20">
            <Button
              htmlType="submit"
              type="primary"
              appearance="default"
              className="mr-10"
              loading={loading}
              disable={loading}
            >
              Save
            </Button>
            <Button type="secondary" onClick={props.closeModal} disable={loading}>
              Cancel
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddStateForm;
