import React from 'react';
import { Modal, message } from 'antd';
import './state.css';
import EditStateForm from './EditStateForm';

const EditState = ({
  open,
  close,
  state,
  capital,
  coordinates,
  zone,
  zoneId,
  stateId,
  reloadState,
  zoneList,
}) => {
  return (
    <Modal
      title="Edit State Lookup"
      className="edit__state"
      footer={false}
      visible={open}
      onCancel={close}
    >
      <EditStateForm
        state={state}
        capital={capital}
        coordinates={coordinates}
        zone={zone}
        stateId={stateId}
        zoneId={zoneId}
        zoneList={zoneList}
        closeModal={close}
        reload={reloadState}
      />
    </Modal>
  );
};

export default EditState;
