import React from 'react';
import PageContainer from 'components/common/PageContainer';
import Layout from '../components/common/Layout';
import SettingsContent from '../components/screens/settings/SettingsContent';

export default (props) => {
  return (
    <Layout headerTitle="Settings">
      <PageContainer>
        <SettingsContent userCategory={props.userCategory} />
      </PageContainer>
    </Layout>
  );
};
