import React, { useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/img/search-left.svg';
import { gray } from 'design-system/colors';

const Search = styled(Input)`
  width: 254px;
  height: 32px;


  .ant-input-prefix {
    left: 26px;
    svg {
      opacity: 0.4;
    }
  }

  .ant-input {
    background-color: transparent;
    padding-left: 54px !important;
    color: #2e384d;
    font-size: 14px;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    font-family: var(--font-family);
  }
  .ant-input::placeholder {
    color: #2e384d !important;
    font-family: var(--font-family);
    opacity: 0.4;
  }
`;

export default ({ className = '', placeholder, searchTable }) => {
  const [searchValue, setSearchValue] = useState('');
  let cb = console.log;
  if (searchTable) cb = searchTable;

  const runSearch = (value) => {
    if (value) setSearchValue(value);
    else {
      setSearchValue(value);
      cb('');
    }
  };
  return (
    <Search
      placeholder={placeholder}
      value={searchValue}
      onChange={({ target: { value } }) => runSearch(value)}
      onPressEnter={() => cb(searchValue)}
      prefix={<SearchOutlined component={SearchIcon} />}
      className={className}
    />
  );
};
