import React, { useState, useEffect } from 'react';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Table from 'components/common/Table';
import { formatDate, parseString, parseString3 } from 'helpers/utils';
import './index.css';
import '../../scrollfix.css';

let deleteSamplePointRecord;
let isPublished;
let extraPadRatio = 1;

const xs = window.matchMedia('(max-width: 1469px)');
// const xs = window.matchMedia('(max-width: 1269px)');
// const xsc = (width) => window.matchMedia(`(max-width: ${width}px)`);

const calcWidth = (which, allCount = 10) => {
  const share = 6;
  let ratio = 0.4;
  const extraPad = isPublished ? (0.05 * (extraPadRatio - 1)) / 0.9 : 0;
  if (which === 'delete') ratio = 0.27;
  else if (which === 'ind') ratio = 0.5;
  else if (which === 'time') ratio = 0.9 + extraPad * 1.5;
  else {
    switch (which.length) {
      case 1:
        ratio = 0.27 + extraPad;
        break;
      case 2:
        ratio = 0.4 + extraPad;
        break;
      case 3:
        ratio = 0.41 + extraPad;
        break;
      case 4:
        ratio = 0.5 + extraPad;
        break;
      case 5:
        ratio = 0.47 + extraPad;
        break;
      case 7:
        ratio = 0.5 + extraPad;
        break;
      case 8:
        ratio = 0.55 + extraPad;
        break;
      default:
        ratio = 0.6 + extraPad;
        break;
    }
  }
  const valu = `${parseFloat((share * ratio) / allCount.toFixed(2))}%`;
  return valu;
};

const averageView = (pageData, paramtrs, tableType) => {
  const len = pageData.length;
  if (len < 1) {
    return null;
  }
  let total = {};
  paramtrs.map((paramtr) => (total[paramtr] = 0));

  pageData.forEach((args) => {
    paramtrs.map((paramtr) => {
      if (args[paramtr]) total[paramtr] += parseFloat(args[paramtr]);
    });
  });

  return (
    <>
      <tr className="report-average">
        <th
          colSpan={tableType === 'report-view' || tableType === 'published-view' ? 1 : 2}
          style={{ textAlign: 'center' }}
        >
          Average
        </th>
        {paramtrs.map((paramtr) => (
          <td key={paramtr}>
            <p className="avg-data-text">{parseFloat(total[paramtr] / len).toFixed(2)}</p>
          </td>
        ))}
      </tr>
    </>
  );
};

const FacilityUploadTable = ({
  userCategory,
  tableType,
  data,
  setData,
  parameters,
  loading,
  published,
  // setRecord,
  // setModal,
  type,
}) => {
  isPublished = published;
  const [isLoading, setIsLoading] = useState(true);

  const userCat = userCategory.trim().toLowerCase();

  const canDeleteRecord = userCat === 'edm';

  useEffect(() => {
    const setLoadingTimer = setTimeout(() => setIsLoading(false), 2000);
    return () => {
      clearTimeout(setLoadingTimer);
    };
  }, [isLoading]);

  const columns = [
    {
      title: 'Runs',
      width: `${calcWidth('ind', parameters.length)}`,
      key: 'ind',
      dataIndex: 'ind',
      render: (ind) => <span className="table-hash-count">{`#${ind}`}</span>,
    },
    // {
    //   title: 'Time',
    //   width: tableType !== 'report-view' ? `${calcWidth('time', parameters.length)}` : 0,
    //   dataIndex: 'entryDate',
    //   render: (time) => (
    //     <span className="entry-date">
    //       {time ? formatDate(new Date(time), 'dd LLL yyyy, h aaaa') : '00:00'}
    //     </span>
    //   ),
    //   key: 'time',
    //   ellipsis: true,
    // },
  ];

  let paramtrs = [];

  if (parameters)
    parameters.map((pmta) => {
      columns.push({
        title: parseString(pmta.unit),
        dataIndex: pmta.unit,
        key: pmta.unit,
        width: `${calcWidth(parseString3(pmta.unit), parameters.length)}`,
        // width: `${calcWidth(parseString3(pmta.unit), parameters.length)}`,
        // render: (unit) => (
        //   <span className="param-unit">
        //     {unit ? unit : ''}
        //     {pmta.siUnit ? <p className="param-si-unit">{pmta.siUnit}</p> : ''}
        //   </span>
        // ),
      });
      if (!paramtrs.includes(pmta.unit)) paramtrs.push(pmta.unit);
    });

  !published &&
    canDeleteRecord &&
    columns.push({
      title: '',
      key: '111',
      fixed: 'right',
      width: `${calcWidth('delete', parameters.length)}`,
      render: (_, record) => {
        return (
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => deleteSamplePointRecord(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <a href="/#">
              <DeleteOutlined className="icon-delete-row" />
            </a>
          </Popconfirm>
        );
      },
    });

  deleteSamplePointRecord = (recordKey) => {
    const newData = data.filter((dt) => dt.key !== recordKey);
    setData(newData);
  };

  const keyedData = data || [];
  keyedData.forEach((dt, index) => {
    dt['key'] = `${index}-${index}`;
    dt['ind'] = index + 1;
  });

  const pLen = parameters.length;
  extraPadRatio = pLen === 0 ? 1 : parseInt(Math.ceil(pLen / 4));
  const xOffset = pLen > 8 ? 100 * pLen : 700;
  let yOffset = isPublished ? 370 : 260;

  if (xs.matches) {
    yOffset = isPublished ? 270 : 150;
  }
  // if (xsc(1569).matches) {
  //   yOffset = isPublished ? 270 : 150;
  // }
  if (type && type === 'reports viewing') {
    yOffset = 200;
  }
  return (
    <Table
      columns={columns}
      dataSource={keyedData}
      scroll={{ x: xOffset, y: yOffset }}
      loading={isLoading || loading}
      bordered={false}
      rowClassName={'facility-upload__rows'}
      // footer={
      //   published
      //     ? (pageData) => {
      //         return <TableFooter dataSource={keyedData} columns={columns} />;
      //       }
      //     : () => false
      // }

      // footer={published ? (pageData) => averageView2(pageData, paramtrs) : () => false}
      // footer={published ? (pageData) => averageView3(pageData, paramtrs) : () => false}
      // components={{ table: __Table(keyedData.totals) }}
      summary={published ? (pageData) => averageView(pageData, paramtrs, tableType) : () => false}
    />
  );
};

export default FacilityUploadTable;
