import React, { useState, useEffect, useContext } from 'react';
import Select from '../../common/Select';
import styled from 'styled-components';
import { UserContext } from '../../../store/UserContext';
import { getStates, getZones, getLga } from '../../../helpers/api';
import { openNotification } from 'helpers/notification';
import { properCase } from 'helpers/utils';
import './options.css';

const { Option } = Select;

const RegulatorInput = ({ className }) => {
  const { updateCategory, Category, updateZone, updateState, updateLGA } = useContext(UserContext);
  const [SelectedArea, setSelectedArea] = useState('');
  const [States, setStates] = useState([]);
  const [Zones, setZones] = useState([]);
  const [LGA, setLGA] = useState([]);
  const [SelectedState, setSelectedState] = useState('');
  const [SelectedZone, setSelectedZone] = useState('');
  const [SelectedLGA, setSelectedLGA] = useState('');

  useEffect(() => {
    getStatesList();
  }, []);

  useEffect(() => {
    getZonesList();
  }, []);

  useEffect(() => {
    getLgaList();
  }, []);

  useEffect(() => {
    updateState(SelectedState);
  }, [SelectedState]);

  useEffect(() => {
    updateZone(SelectedZone);
  }, [SelectedZone]);

  useEffect(() => {
    updateLGA(SelectedLGA);
  }, [SelectedLGA]);

  const getStatesList = () => {
    getStates()
      .then((res) => {
        setStates(res.result.data);
      })
      .catch((error) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: error && error.message ? error.message : 'Error getting states',
        });
      });
  };

  const getZonesList = () => {
    getZones()
      .then((res) => {
        setZones(res.result.data);
      })
      .catch((error) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: error && error.message ? error.message : 'Error getting zones',
        });
      });
  };

  const getLgaList = () => {
    getLga()
      .then((res) => {
        setLGA(res.result.data);
      })
      .catch((error) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: error && error.message ? error.message : 'Error getting LGAs',
        });
      });
  };

  const handleArea = (value) => {
    setSelectedArea(value);
  };

  // SET SELECTED STATE
  const handleState = (value) => {
    setSelectedState(value);
  };

  // SET SELECTED ZONE
  const handleZone = (value) => {
    setSelectedZone(value);
  };

  // SET SELECTED LGA VALUE
  const handleLGA = (value) => {
    setSelectedLGA(value);
  };

  // DISPLAY LIST OF ZONES
  const displayZones = () => {
    return (
      <Select
        mode="multiple"
        placeholder="Select a zone"
        onChange={handleZone}
        className="zone-selector mt-20 regulator__input"
      >
        {Zones.length > 0 &&
          Zones.map((zone) => {
            return (
              <Option value={zone.zoneId} key={zone.zoneId} className="regulator-item">
                {properCase(zone.zoneName)}
              </Option>
            );
          })}
      </Select>
    );
  };

  // DISPLAY LIST OF STATES
  const displayStates = () => {
    return (
      <Select
        mode="multiple"
        placeholder="Select a state"
        onChange={handleState}
        className="states-selector mt-20 regulator__input"
      >
        {States.length > 0 &&
          States.map((state) => {
            return (
              <Option value={state.stateId} key={state.stateId} className="regulator-item">
                {properCase(state.stateName)}
              </Option>
            );
          })}
      </Select>
    );
  };

  // DISPLAY LIST OF LGA'S
  const displayLGA = () => {
    return (
      <Select
        mode="multiple"
        placeholder="Select a local government area"
        onChange={handleLGA}
        className="lga-selector mt-20 regulator__input"
      >
        {LGA.length > 0 &&
          LGA.map((lga) => {
            return (
              <Option value={lga.lgaId ? lga.lgaId : ''} key={lga.lgaId} className="regulator-item">
                {properCase(lga.lgaName)}
              </Option>
            );
          })}
      </Select>
    );
  };

  return (
    <div className={className}>
      <Select placeholder="Select an area" onChange={handleArea} className="mt-20 regulator__input">
        <Option value="state">States</Option>
        <Option value="zone">Zones</Option>
        <Option value="lga">Lga</Option>
      </Select>
      {SelectedArea === 'state' && (
        <div className="state-selector-container">{displayStates()}</div>
      )}
      {SelectedArea === 'zone' && <div className="zone-selector-container">{displayZones()}</div>}
      {SelectedArea === 'lga' && <div className="lga-selector-container">{displayLGA()}</div>}
    </div>
  );
};
export default styled(RegulatorInput)`
  .ant-select-multiple .ant-select-selection-item {
    background: #e7f5ff !important;
    display: flex;
    align-items: center;
    color: #0c5bab !important;
    border-radius: 15px !important;
  }
 
`;
