import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import EditParamterForm from './EditParameterForm';

import '../style.css';

const EditParameter = ({ open, close, detail, reloadMeasurements }) => {
  const [paramId, setParamId] = useState('');
  const [paramName, setParamName] = useState('');
  const [paramFMENV, setParamFMENV] = useState(0);
  const [paramWorldBank, setParamWorldBank] = useState(0);
  const [paramUnit, setParamUnit] = useState('');

  useEffect(() => {
    getDetails();
  });
  const getDetails = () => {
    if (detail) {
      console.log(detail[0].fmEnv);
      setParamId(detail[0].parameterId);
      setParamName(detail[0].unit);
      setParamUnit(detail[0].siUnit);
      setParamWorldBank(detail[0].worldBank);
      setParamFMENV(detail[0].fmEnv);
    }
  };
  return (
    <Modal
      className="edit__parameter__modal"
      visible={open}
      onCancel={close}
      title="Edit Measurement Parameter"
      footer={false}
    >
      <EditParamterForm
        name={paramName}
        unit={paramUnit}
        worldBank={paramWorldBank}
        id={paramId}
        fmLimit={paramFMENV}
        closeModal={close}
        reload={reloadMeasurements}
      />
    </Modal>
  );
};

export default EditParameter;
