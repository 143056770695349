import React, { useContext } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import history from '../history';
import Home from './home';
import Reports from './reports';
import Users from './users';
import Settings from './settings';
import Facilities from './facilities';
import Schedules from './schedules';
import NotFound from './notfound';
import UserRoles from '../components/screens/settings/RolesPermissions';
import ProfileSettings from './Profile';
import AdminReports from './adminreports';
import Lookup from './lookup';
import ZoneLookup from '../components/screens/settings/Lookup/ZoneLookup/Zone';
import StateLookup from '../components/screens/settings/Lookup/StateLookup/State';
import LGALookup from '../components/screens/settings/Lookup/LGALookup/lga';
import SectorLookup from '../components/screens/settings/Lookup/SectorLookup/index';
import Configurations from './configurations';
import CategoryAccess from '../access/CategoryAcess';
import { AppContext } from '../store/AppContext';

export default (props) => {
  const { state } = useContext(AppContext);
  const userCategory = state.auth.result.data.userCategory;

  return (
    <HashRouter history={history}>
      <Switch>
        <CategoryAccess userCategory={userCategory} exact path="/users" component={Users} />
        <CategoryAccess userCategory={userCategory} path="/facilities" component={Facilities} />
        <CategoryAccess userCategory={userCategory} exact path="/reports" component={Reports} />
        <CategoryAccess userCategory={userCategory} exact path="/logs" component={AdminReports} />
        <CategoryAccess userCategory={userCategory} exact path="/schedules" component={Schedules} />
        <CategoryAccess userCategory={userCategory} exact path="/settings" component={Settings} />

        <CategoryAccess userCategory={userCategory} exact path="/" component={Home} />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/roles"
          component={UserRoles}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/profile"
          component={ProfileSettings}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/lookups"
          component={Lookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/zone"
          component={ZoneLookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/state"
          component={StateLookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/lga"
          component={LGALookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/sector"
          component={SectorLookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/configurations"
          component={Configurations}
        />
        <CategoryAccess userCategory={userCategory} path="/" component={NotFound} />
        {/* <CategoryAccess userCategory={userCategory} path="/*" render={() => <NotFound />} />
        <CategoryAccess userCategory={userCategory} render={() => <NotFound />} /> */}
      </Switch>
    </HashRouter>
  );
};
