// import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';

export default styled(Popover)`
  .ant-popover > .ant-popover-content {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
    max-height: 32rem !important;
    border: 1px solid var(--dark);
    /* overflow-y: unset !important; */
  }

  .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
    background-color: red;
    border-top-color: blue;
    border-right-color: yellow;
    border-bottom-color: red;
    border-left-color: greenyellow;
  }

  .ant-popover-inner-content {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }

  .ant-popover,
  .ant-popover-inner,
  .ant-popover-inner-content {
    font-size: 12px;
  }
`;
