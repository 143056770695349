import React from 'react';
import { Tabs } from 'antd';
// import './style.css';
// import MeasurmentConfig from './Measurement';
import ProfileSettings from './ProfileContent';
import PreferencesConfig from '../Configurations/Preferences/index';

import UserContextProvider from '../../../store/UserContext';

const { TabPane } = Tabs;

const ConsultantSettings = () => {
  return (
    <UserContextProvider>
      <div className="consultant__container">
        <Tabs defaultActiveKey="1" tabPosition="left" className="config__tabs">
          <TabPane tab="Profile" key="1">
            <>
              <ProfileSettings />
            </>
          </TabPane>

          <TabPane tab="Preferences" key="2">
            <PreferencesConfig />
          </TabPane>
        </Tabs>
      </div>
    </UserContextProvider>
  );
};

export default ConsultantSettings;
