import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Row, Col, message, Spin } from 'antd';
import { getStates, getZones, getLga, getUser } from '../../../../helpers/api';
import { ReactComponent as UserIcon } from '../../../../assets/img/users.svg';
import { ArrowLeftOutlined, TagFilled } from '@ant-design/icons';
import ModalHeader from './ModalHeader';
import UpdateUserForm from './UpdateUserForm';
import './UpdateUser.css';

const { TabPane } = Tabs;

const UpdateUser = ({ open, close, name, userCategory, email, areaPermit, userId }) => {
  const [zones, setZones] = useState([]);
  const [states, setStates] = useState([]);
  const [lga, setLga] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [tabPosition, setTabPosition] = useState('left');

  const canUpdateConsultant = userCategory === 'CONSULTANT';
  const canUpdateEdm = userCategory === 'EDM';
  const canUpdateRegulator = userCategory === 'REGULATOR';

  useEffect(() => {
    refreshZones();
    refreshStates();
    refreshLGA();
    refreshUser();
  }, []);

  useEffect(() => {
    refreshUser();
  }, [reload]);

  const refreshZones = () => {
    getZones()
      .then((res) => {
        setZones(res.result.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };

  const refreshStates = () => {
    getStates()
      .then((res) => {
        setStates(res.result.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };
  const refreshLGA = () => {
    getLga()
      .then((res) => {
        setLga(res.result.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };
  const refreshUser = () => {
    getUser(userId)
      .then((res) => {
        if (res === null) {
          message.error('User Not Found');
        } else {
          setData(res.result.data);
          setReload(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
        setLoading(false);
      });
  };

  const reloadUser = (value) => {
    console.log('received action', value);
    setReload(value);
  };
  const displayUserData = () => {
    if (data === null) {
      // console.log(data, 'dataaaa')
      return (
        <div className="loader">
          <Spin />
        </div>
      );
    } else {
      if (data) {
        const { zonePermission, statePermission, lgaPermission } = data.userAreaPermissions;

        return (
          <Row key={data.userId}>
            <Col lg={12}>
              <p className=" small-text bold">First Name</p>
              <p className="text-gray small-text">{data.firstname}</p>
            </Col>
            <Col lg={12}>
              <p className="profile__heading">Last Name</p>
              <p className="profile__text">{data.lastname}</p>
            </Col>
            <Col lg={12}>
              <p className="profile__heading">Username </p>
              <p className="profile__text">{data.username}</p>
            </Col>
            <Col lg={12}>
              <p className="profile__heading">Email Address</p>
              <p className="profile__text">{data.email}</p>
            </Col>

            <Col lg={12}>
              <p className="profile__heading">Phone Number </p>
              <p className="profile__text">{data.phone}</p>
            </Col>
            <Col lg={12}>
              <p className="profile__heading">Status</p>
              <p className="profile__text">{data.status}</p>
            </Col>
            <Col lg={12}>
              <p className="profile__heading">Category</p>
              <p className="profile__text">{data.userCategory}</p>
            </Col>
            <Col lg={12}>
              <p className="profile__heading">Role</p>
              <p className="profile__text">{data.role}</p>
            </Col>
            <Col lg={20}>
              {/* {zonePermission.length === 0 &&
              lgaPermission.length === 0 &&
              statePermission.length === 0 ? null : (
                <Row className="area__summary__list">
                  <Col lg={24}>
                    <h3 className="profile__heading">Assigned Areas</h3>
                  </Col>

                  {zonePermission &&
                    zonePermission.map((zones) => {
                      return (
                        <Col lg={7}>
                          <TagFilled style={{ color: '#0c5bab', marginRight: '5px' }} />
                          {zones.zoneName}
                        </Col>
                      );
                    })}
                  {statePermission &&
                    statePermission.map((states) => {
                      return (
                        <Col lg={7}>
                          <TagFilled style={{ color: '#0c5bab', marginRight: '5px' }} />
                          {states.stateName}
                        </Col>
                      );
                    })}
                  {lgaPermission &&
                    lgaPermission.map((lga) => {
                      return (
                        <Col lg={7}>
                          <TagFilled style={{ color: '#0c5bab', marginRight: '5px' }} />
                          {lga.lgaName}
                        </Col>
                      );
                    })}
                </Row>
              )} */}
            </Col>
          </Row>
        );
      } else {
        return <h3>No user data found</h3>;
      }
    }
  };
  return (
    <Modal
      className="update__user__modal"
      title={<ModalHeader closeModal={close} />}
      visible={open}
      onCancel={close}
      footer={false}
      closable
    >
      {canUpdateConsultant && (
        <Tabs className="update__user__tabs" tabPosition={tabPosition}>
          <TabPane tab="Basic Info" key="1">
            {displayUserData()}
          </TabPane>
        </Tabs>
      )}
      {canUpdateEdm && (
        <Tabs className="update__user__tabs" tabPosition={tabPosition}>
          <TabPane tab="Basic Info" key="1">
            {displayUserData()}
          </TabPane>
        </Tabs>
      )}
      {canUpdateRegulator && (
        <Tabs className="update__user__tabs" tabPosition={tabPosition}>
          <TabPane tab="Basic Info" key="1">
            {displayUserData()}
          </TabPane>
          <TabPane tab="Additional Info" key="2">
            <div>
              <UpdateUserForm
                name={name}
                email={email}
                category={userCategory}
                permit={areaPermit}
                zoneList={zones}
                stateList={states}
                userId={userId}
                lgaList={lga}
                userData={data}
                closeUpdateModal={close}
                reload={reloadUser}
              />
            </div>
          </TabPane>
        </Tabs>
      )}
      <Row justify="start">
        <Col lg={3}>
          <button className="close__btn" onClick={close}>
            <ArrowLeftOutlined />
          </button>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateUser;
