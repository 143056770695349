import React from 'react';
import PageContainer from 'components/common/PageContainer';
import Layout from 'components/common/Layout';
import Home from 'components/screens/home';

export default (props) => {
  return (
    <Layout headerTitle="Dashboard">
      <PageContainer>
        <Home props={props} id="dashboard" />
      </PageContainer>
    </Layout>
  );
};
