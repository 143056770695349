import React from 'react';
import Layout from 'components/common/Layout';
import ConfigContent from '../components/screens/Configurations';
import UserContextProvider from '../store/UserContext';

const Configurations = (props) => {
  return (
    <Layout headerTitle="Settings">
      <UserContextProvider>
        <ConfigContent userCategory={props.userCategory} />
      </UserContextProvider>
    </Layout>
  );
};

export default Configurations;
