import React, { useState, useEffect, useRef } from 'react';
import {
  select,
  axis,
  area,
  stack,
  scaleOrdinal,
  schemeCategory10,
  selectAll,
  scaleLinear,
  scaleBand,
  axisLeft,
  axisBottom,
  dispatch,
  min,
  max,
  nest,
  sum,
  line,
} from 'd3';
import { Tooltip, Radio, Checkbox } from 'antd';
import { SyncOutlined } from '@ant-design/icons/lib/icons';
import styled from 'styled-components';
import LoadingContent from 'components/common/LoadingContent';
import Filter from 'components/common/Filter';

const CheckboxGroup = Checkbox.Group;

const avgOptions = ['fmEnv', 'worldBank'];

const avgRadioOptions = [
  { label: 'Fed. Ministry', value: avgOptions[0], name: 'envs2', className: 'envinput' },
  { label: 'World Bank', value: avgOptions[1], name: 'envs2', className: 'envinput' },
];

const names = {
  fmEnv: 'fmeViolations',
  worldBank: 'worldBankViolations',
};

const messenger = dispatch('changeEnv', 'changeSector');

function getArrayIndexes(arrayOptions, array) {
  const aChecked = [];
  array && array.map((dfc) => aChecked.push(arrayOptions.indexOf(dfc)));
  return aChecked;
}

const LimitViolators = (props) => {
  const {
    loading: initialLoading,
    className,
    error,
    data: initialData,
    title,
    width,
    height,
    fetch,
    url,
    margin,
  } = props;
  const SVGCanvas = useRef(null);
  const [data, setData] = useState();
  const [localError, setLocalError] = useState(error);
  const [loading, setLoading] = useState(initialLoading);
  const [mounted, setMounted] = useState(false);
  const [checkListOptions, setCheckListOptions] = useState([]);

  const defaultCheckedList = checkListOptions.slice(0, 3);

  const initialCheckListState = {
    checkedList: defaultCheckedList,
    checkedListIndexes: getArrayIndexes(checkListOptions, defaultCheckedList),
    avgEnv: names[avgOptions[0]],
    indeterminate:
      !!defaultCheckedList.length && defaultCheckedList.length < checkListOptions.length,
    checkAll: defaultCheckedList.length === checkListOptions.length,
  };

  const [checkListState, setCheckListState] = useState(initialCheckListState);
  const [avgEnv, setAvgEnv] = useState(avgOptions[0]);
  const [currUrl, setCurrUrl] = useState();

  let { [avgEnv]: value } = names;

  const plotAreaChart = (xdata, svg, checkListOptions, checkListState) => {
    const mapXData = (checkListState) => {
      const type = checkListState.avgEnv;
      const whichX = getArrayIndexes(checkListOptions, checkListState.checkedList);
      const allSectors = xdata.map((dt) => dt['sectorName']);
      const newMapData = whichX.map((dt) => xdata[dt]);
      const allKeys = [...new Set(...xdata.map((xd) => xd.values))].map((x) => x.longName);
      const newStruct = [];
      newMapData.map((mpDt) =>
        allKeys.map((mpDt2) => {
          let filterD = null;
          if (mpDt.values && mpDt.values.length > 0) {
            const filterD2 = mpDt.values.filter((x) => x.longName === mpDt2);
            if (filterD2 && filterD2[0]) filterD = filterD2[0];
          }
          newStruct.push({
            sectorName: mpDt.sectorName,
            name: mpDt2,
            value: filterD ? filterD[type] : 0,
          });
        })
      );
      return {
        data: newMapData,
        keys: allKeys,
        selectedData: newStruct, // use this for plotting
        type,
        allSector: allSectors,
      };
    };

    let workingData = mapXData(checkListState);
    let actualWorkingData = workingData.selectedData;

    const theKey = 'name';
    const bounds = svg.node().getBoundingClientRect();
    const w = bounds.width;
    const h = bounds.height;

    const innerWidth = w - (margin.left + 20) - margin.right;
    const innerHeight = h - margin.top - margin.bottom - 20;

    // selectAll('.pollution-violations input').on('change', changeEnv);

    messenger.on('changeSector', change);
    messenger.on('changeEnv', changeEnv);

    // const xScale = scaleOrdinal([0, innerWidth]);
    const xScale = scaleBand().range([0, innerWidth]);

    const yScale = scaleLinear().range([innerHeight, 0]);
    // const c = scaleOrdinal(schemeCategory10);
    // let c;

    let legend;

    createAreaChart(actualWorkingData);

    function createAreaChart(newData, speed = 0) {
      // newData = newData.sort((a, b) => a.value - b.value);
      // newData = newData.reverse();
      let keys = [...new Set(newData.map((nd) => nd[theKey]))];
      xScale.domain(keys);

      let c = scaleOrdinal()
        .domain([...new Set(newData.map((nd) => nd['sectorName']))])
        .range(schemeCategory10);

      // let values = nest()
      //   .key((d) => d['sectorName'])
      //   .rollup((d) => sum(d, (g) => g.value))
      //   .entries(newData);

      let values = nest()
        .key((d) => d['sectorName'])
        .rollup((d) => {
          let theSum = sum(d, (g) => g.value);
          let sortedValues = d.map((d) => d.value);
          return d.map((row, i) => {
            let floor = i == 0 ? 0 : sortedValues.slice(0, i).reduce((a, b) => a + b);
            let ceiling = sortedValues.slice(0, i + 1).reduce((a, b) => a + b);
            return {
              sectorName: row['sectorName'],
              name: row['name'],
              floor: floor,
              ceiling: ceiling,
              sum: theSum,
            };
          });
        })
        .entries(newData);

      // const lineD = line()
      //   .curve(curveBasis)
      //   .x((d) => xScale(d[theKey]))
      //   .y((d) => yScale(d['value']));

      var areaD = area()
        // .curve(curveBasis)
        .x((d) => xScale(d.data[theKey]))
        .y0((d) => (isNaN(d[0]) ? yScale(0) : yScale(d[0])))
        .y1((d) => (isNaN(d[1]) ? yScale(0) : yScale(d[1])));

      // Nest stock values by sector.
      const sectors = nest()
        .key((d) => d['sectorName'])
        .entries(newData);

      const mNames = nest()
        .key((d) => d[theKey])
        .entries(newData);
      // .sort(function(x, y) {
      //   return totalSum(x) - totalSum(y);

      //   function totalSum(h) {
      //     let totalsum = 0;
      //     h.values.forEach((hd) => {
      //       if (hd.value) totalsum += hd.value;
      //     });
      //     return totalsum;
      //   }
      // });

      const keysD = [];

      let table = [];
      // Parse and calculate some values for each sectors
      let count = 0;
      sectors.forEach(function(s) {
        s.values.forEach(function(d) {
          d[theKey] = d[theKey];
          d[value] = +d.value || 0;
          if (count == 0) {
            var valeur = {};
            valeur['name'] = d.name;
            valeur['sectorName'] = d.sectorName;
            valeur[s.key] = +d.value || 0;
            table.push(valeur);
          }
        });
        keysD.push(s.key);
        s.maxValue = max(s.values, function(d) {
          return d['value'];
        });
        s.sumValue = sum(s.values, function(d) {
          return d['value'];
        });
      });

      table = [];
      mNames.forEach(function(d) {
        var valeur = {};
        var c = 0;
        valeur[theKey] = d.key;
        d.values.forEach(function(v) {
          valeur[keysD[c]] = +v['value'] || 0;
          c++;
        });
        table.push(valeur);
      });

      var stackD = stack().keys(keysD);
      var series = stackD(table);
      yScale.domain([
        0,
        sum(
          sectors.map(function(d) {
            return d.maxValue;
          })
        ),
      ]);

      if (!newData || (newData && newData.length < 1)) {
        svg
          .selectAll('.area')
          .data(newData)
          .exit()
          .remove()
          .transition()
          .duration(speed);

        svg
          .selectAll('.tick')
          .data(newData)
          .exit()
          .remove();
      } else {
        // debugger;
        const svgGroup = svg
          .append('g')
          .selectAll('area')
          .data(series);

        // svgGroup.exit().remove();
        svgGroup
          // .selectAll('path')
          // .data(series)
          // .join('path')
          .enter()
          .append('path')
          // .attr('class', 'layer')
          // .append('path')
          .attr('class', 'area')
          .attr('transform', `translate(${margin.left + 5}, 0)`)
          .style('fill', function(d) {
            return c(d.key);
          })
          .attr('d', areaD)
          .append('title')
          .text(({ key }) => key);

        svg
          .append('g')
          // .attr('class', 'axis axis--x')
          .attr('transform', `translate(${margin.left + 5}, ${innerHeight})`)
          .call(axisBottom(xScale))
          // .call((g) => g.selectAll('.domain').remove());
          .selectAll('.tick')
          .attr('class', `tick-xscale`);
        // .selectAll('text')
        // .attr('dx', d => `${-d.length - 1 * 2}` )

        svg
          .append('g')
          .attr('transform', `translate(${margin.left}, 0)`)
          .call(axisLeft(yScale))
          .attr('class', 'axis axis--y');
        // .call((g) => g.selectAll('.domain').remove());
      }
      plotLegend(newData, c);

      function plotLegend(legendData, c) {
        // svg.append('g').selectAll('g').data([]).exit().remove().enter();
        if (!legendData || (legendData && legendData.length === 0)) {
          svg
            .selectAll('.legend')
            .data([])
            .exit()
            .remove();
          svg
            .selectAll('.tick-xscale')
            .data([])
            .exit()
            .remove();
        } else {
          const legendSpacing = 80;
          const legendRectSize = 5;

          // const svgGroup = svg.append('g').selectAll('area').data(series);

          legend = svg
            .append('g')
            // .classed('g-legend', true)
            .attr('transform', `translate(${innerWidth / 6}, ${h})`)
            .selectAll('.legend')
            .data(c.domain())
            .join('g')
            .attr('class', 'legend')
            .attr('transform', function(d, i) {
              const h1 = legendSpacing;
              const offset = h1 / c.domain().length;
              // const vert = -2;
              const horz = i * h1 + d.length + offset;
              return 'translate(' + horz + ',' + 0 + ')';
            });

          legend
            .append('rect')
            .attr('x', legendRectSize - 20)
            .attr('y', legendRectSize - 20)
            .attr('width', 10)
            .attr('height', 10)
            .style('fill', (d) => c(d))
            .style('stroke', (d) => c(d));

          legend
            .append('text')
            .attr('x', legendRectSize)
            .attr('y', legendRectSize - 10)
            .text((d) => d)
            .style('fill', (d) => c(d))
            // .attr('dy', '4')
            // .attr('dx', '-5')
            .style('text-anchor', 'start');
        }
      }
    }

    function changeEnv(newCheckListState) {
      workingData = mapXData(newCheckListState);
      actualWorkingData = workingData.selectedData;
      createAreaChart([], 750);
      createAreaChart(actualWorkingData, 750);
    }

    function change(newCheckListState) {
      workingData = mapXData(newCheckListState);
      actualWorkingData = workingData.selectedData;
      createAreaChart([], 750);
      createAreaChart(actualWorkingData, 750);
      // legend
      //   .selectAll('text')
      //   .join('text')
      //   .text((d) => d);
    }
  };

  const render = (data, checkListOptions = checkListOptions, checkListState = checkListState) => {
    const svg = select(SVGCanvas.current);
    if (svg.node()) {
      plotAreaChart(data, svg, checkListOptions, checkListState);
    }
  };

  const renderChart = (refresh) => {
    if (refresh) setLoading(true);
    if (!url && !refresh) setLoading(true);
    else if (!refresh && data && url === currUrl) render(data, checkListOptions, checkListState);
    else if (!localError)
      fetch(url)
        .then(({ result }) => {
          const res = result.data;
          setCurrUrl(url);
          if (res) {
            const chkOptions = res.map((res1) => res1.sectorName);
            const defaultCheckedList = chkOptions.slice(0, 3);
            const initialCheckListState = {
              checkedList: defaultCheckedList,
              checkedListIndexes: getArrayIndexes(chkOptions, defaultCheckedList),
              avgEnv: names[avgOptions[0]],
              indeterminate:
                !!defaultCheckedList.length && defaultCheckedList.length < chkOptions.length,
              checkAll: defaultCheckedList.length === chkOptions.length,
            };
            setCheckListState(initialCheckListState);
            setCheckListOptions(chkOptions);
            setData(res);
            render(res, chkOptions, initialCheckListState);
          } else setData([]);
          setLoading(false);
        })
        .catch((err) => {
          console.log('error fetching', err);
          if (loading && mounted) setLoading(false);
          if (mounted) setLocalError(true);
        });
  };

  const refresh = () => {
    setLocalError(false);
    renderChart(true);
  };

  const changeAvgEnv = (e) => {
    const newEnv = e.target.value;
    setAvgEnv(newEnv);
    const newCheckListState = { ...checkListState };
    newCheckListState.avgEnv = names[newEnv];
    messenger.call('changeEnv', this, newCheckListState);
  };

  useEffect(() => {
    try {
      // if (!mounted && !data) setLoading(true);
      if (!mounted) setMounted(true);
      if (mounted) renderChart();
      else {
        return;
      }
    } catch (err) {
      console.log('caught: ', err);
      setLocalError(true);
    }
    return () => {
      // setData('');
      const clearPlots = () => {
        const svg = select(SVGCanvas.current);
        if (svg.node()) {
          svg
            .selectAll('g')
            .data([], (d) => d)
            .exit()
            .remove()
            .transition()
            .duration(750);
        }
      };
      clearPlots();

      return setMounted(false);
    };
  }, [mounted, url, localError, loading]);

  const onCheckAllChange = (e) => {
    const formerAvgEnv = checkListState.avgEnv;
    const currentCheck = e.target.checked ? checkListOptions : [checkListOptions[0]];
    const newCheckListState = {
      checkedList: currentCheck,
      checkedListIndexes: getArrayIndexes(checkListOptions, currentCheck),
      avgEnv: formerAvgEnv,
      indeterminate: false,
      checkAll: e.target.checked,
    };
    setCheckListState(newCheckListState);
    messenger.call('changeSector', this, newCheckListState);
  };

  const onCheckChange = (checkedList) => {
    const formerAvgEnv = checkListState.avgEnv;
    if (checkedList && checkedList.length > 0) {
      const newCheckListState = {
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < checkListOptions.length,
        checkedListIndexes: getArrayIndexes(checkListOptions, checkedList),
        avgEnv: formerAvgEnv,
        checkAll: checkedList.length === checkListOptions.length,
      };
      setCheckListState(newCheckListState);
      messenger.call('changeSector', this, newCheckListState);
    }
  };

  const filterContent = (
    <>
      <div className="divide-under">
        <Checkbox
          indeterminate={checkListState.indeterminate}
          onChange={onCheckAllChange}
          checked={checkListState.checkAll}
        >
          Check all
        </Checkbox>
      </div>
      <CheckboxGroup
        options={checkListOptions}
        value={checkListState.checkedList}
        onChange={onCheckChange}
      />
    </>
  );

  return (
    <>
      <div className={`dashboard-content-box ${className}`}>
        <div className="box-item-title">
          <h3 className="subheading bold">
            {title}

            {data && data.length > 0 && (
              <div style={{ float: 'right', marginRight: '20px' }}>
                <Filter
                  enableClear={false}
                  content={filterContent}
                  isFiltering={false}
                  enableHeader={false}
                  customClassName="custom filter-pollution-violations"
                  custom={true}
                />
              </div>
            )}
          </h3>
        </div>
        <div className="box-item-canvas pollution-violations">
          {loading && <LoadingContent modal={true} />}
          {!loading && !localError && data && data.length > 0 && (
            <>
              <Radio.Group
                options={avgRadioOptions}
                onChange={changeAvgEnv}
                defaultValue={avgEnv}
                style={{ float: 'right' }}
              />
              <svg
                className="limit-violations-canvas"
                ref={SVGCanvas}
                style={{ width: '100%', height }}
              />
            </>
          )}
          {!loading && !localError && (!data || (data && data.length === 0)) && (
            <>
              <span>Nothing to show!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
          {!loading && localError && (
            <>
              <span>Oops, we encountered some problems!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const generateSize = ({ width = '400px', height = '350px' }) => {
  return `width: ${width};height: ${height};`;
  // return `height: ${height};`;
};

export default styled(LimitViolators)`
  // ${(props) => generateSize(props)};
  box-shadow: -1px 2px 8px -2px rgba(67, 90, 111, 0.2);
  // box-shadow: 0px 8px 13px 4px rgba(67, 90, 111, 0.2);
  // box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: var(--font-family);
  border-radius: 5px;

  .box-item-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    .filter-popover {
      .ant-dropdown-link {
        font-family: var(--font-family);
        font-weight: normal;
        font-size: 14px
      }
    }

    .subheading {
      padding-bottom: 14px;
      padding-top: 14px;
      padding-left: 14px;
      padding-right: 5px;

      font-size: 18px;
      font-family: var(--font-family-bold);

      .ant-radio-group {
        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #1c81ce;
            background-color: #1c81ce;
            ::after {
              background-color: white;
              width: 5.5px;
              left: 4px;
              top: 3.3px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .box-item-canvas {
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 5px;    
    margin-bottom: 14px;
    height: calc(100% - 30px);

    @media (max-width: 1200px) {
      {
        ${(props) =>
          props.error || props.loading
            ? `height: calc(100% - 30px);`
            : `height: ${props.height}`};      
      }
    }
    width: 100%;
    font-family: var(--font-family);

    svg {
      .g-box {
        transform: translate(9rem, 11rem);
      }
      .g-legend {
        transform: translate(22rem, 15rem);
      }
      .legend {
            font-size: 12px;
            font-family: var(--font-family);
        text {
          font-size: 12px;
        }
        rect {
          stroke-width: 2;
        }
      }
      @media (max-width: 2480px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1655px) {
        {
          .g-legend {
            transform: translate(65%, 15rem);
          }
        }
      }
      @media (max-width: 1400px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }
      @media (max-width: 1240px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1200px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }      
    }
  }
  
`;
