import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { getZones } from '../../../../../helpers/api';
import AddStateForm from './AddStateForm';

const AddStateModal = ({ open, close, reloadState }) => {
  const [zones, setZones] = useState(null);

  useEffect(() => {
    getZonesList();
  }, []);

  const getZonesList = () => {
    getZones()
      .then((res) => {
        setZones(res.result.data);
      })
      .catch((err) => {
        message.error(err);
      });
  };
  return (
    <Modal
      visible={open}
      onCancel={close}
      className="add__state__modal"
      footer={false}
      title="Create New State"
    >
      <AddStateForm zoneList={zones} reload={reloadState} closeModal={close} />
    </Modal>
  );
};

export default AddStateModal;
