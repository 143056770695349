import React from 'react';
import styled from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons/lib/icons';
import logo from 'assets/img/logo.png';
import config from '../../../package.json';

let buildVersion = '0.1';
if (config && config.version) buildVersion = config.version;

const NavBrand = ({ className, onClick, isOpen }) => {
  return (
    <div className={`NavBrand ${className}`}>
      <span className="nav-logo">
        <img src={logo} alt="iems logo" />
        {isOpen && (
          <span className="iems-logo-text">
            iems<sup>v{buildVersion}</sup>
          </span>
        )}
      </span>
      <span className="menu-toggle">
        {isOpen && <MenuFoldOutlined onClick={onClick} />}
        {!isOpen && <MenuUnfoldOutlined onClick={onClick} />}
      </span>
    </div>
  );
};
export default styled(NavBrand)`
  background: transparent;
  display: flex;
  align-items: center;
  padding: var(--sidebar-min-padding);
  .nav-logo {
    padding: var(--sidebar-min-padding);
  }
  .iems-logo-text {
    margin-left: 13.6px;
    sup {
      /* color: var(--dark); */
      margin-left: 4px;
      font-size: 8px;
    }
  }
  .menu-toggle {
    position: absolute;
    right: 0;
    top: 50px;
    transform: translateX(50%) scale(0.6);
    svg {
      fill: var(--menu-toggle-color);
    }
  }
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    display: flex;
    padding-left: 1rem;
    flex-wrap: wrap;
  }

  img {
    height: 24px;
    width: 22px;
  }

  span {
    font-weight: 500;
    font-size: 19px;
    color: #fff;
  }
  button {
    display: none;
    @media screen and (max-width: 769px) {
      display: block;
      margin-bottom: 0;
    }
  }
`;
