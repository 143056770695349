import React, { useState, useEffect } from 'react';
import { message, Popover } from 'antd';
import Table from '../../common/Table';
import Button from '../../common/Button';
import { LockFilled } from '@ant-design/icons';
import LoadingContent from '../../common/LoadingContent';
import { getScheduleFacility } from '../../../helpers/api';
import ScheduleFacilityModal from './ScheduleFacilityModal';

const UnscheduledQuarter = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
  const [unscheduledFacilities, setunscheduledFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [reload, setReload] = useState(false);
  const [locking, setLocking] = useState(false);
  const [stateFacilities, setStateFacilities] = useState(null);

  useEffect(() => {
    getUnscheduledFacilities();
  }, []);
  useEffect(() => {
    if (reload) {
      getUnscheduledFacilities();
    }
  }, [reload]);

  useEffect(() => {
    if (props.stateFilter.key !== undefined && props.stateFilter.key !== 'all') {
      getUnscheduledFacilities();
    }
  }, [props.stateFilter]);

  // get unscheduled facilities
  const getUnscheduledFacilities = () => {
    setLoading(true);
    if (props.stateFilter.key !== undefined && props.stateFilter.value !== 'all') {
      getScheduleFacility(0, props.stateFilter.value)
        .then((res) => {
          setStateFacilities(res.result.data);
          setLoading(false);
        })
        .catch((err) => {
          message.error(err);
          setLoading(false);
        });
    } else {
      getScheduleFacility()
        .then((res) => {
          setunscheduledFacilities(res.result.data);
          setLoading(false);
          setReload(false);
        })
        .catch((err) => {
          message.error(err);
          setLoading(false);
        });
    }
  };

  const closeScheduleModal = () => {
    setScheduleModalVisible(false);
  };

  const openScheduleModal = () => {
    setScheduleModalVisible(true);
  };

  const popMe = (address) => {
    return <p>{address}</p>;
  };

  const columns = [
    {
      title: 'Facility Name',
      dataIndex: 'facilityName',
      key: 'facilityName',
    },
    {
      title: 'State',
      dataIndex: 'stateName',
      key: 'stateName',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <Popover
          placement="topLeft"
          title={'Address'}
          content={() => popMe(address)}
          trigger="hover"
          className="facility-table__popover address__popover"
        >
          <span>{address}</span>
        </Popover>
      ),
      ellipsis: true,
    },
    {
      title: 'Sample Points',
      dataIndex: 'samplePoints',
      render: (samplePoints) => <>{samplePoints.length} sample points</>,
    },
    {
      title: '',
      dataIndex: 'facilityId',
      render: (facilityId) => (
        <>
          <Button className="lock__btn" onClick={(e) => lockFacility(facilityId)}>
            <LockFilled style={{ marginRight: '5px', color: '#2e5bff' }} />
            Lock Facility
          </Button>
        </>
      ),
    },
  ];

  const lockFacility = (id) => {
    const facility = [];
    facility.push(id);
    setSelectedRowKeys(facility);
    setScheduleModalVisible(true);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
  };

  const reloadFacility = (value) => {
    setReload(value);
  };
  

  // key data for facility table
  const keyedData = unscheduledFacilities;
  keyedData.forEach((dt) => (dt['key'] = dt.facilityId));

  const keyedStateData = stateFacilities;
  keyedStateData &&
    keyedStateData !== null &&
    keyedStateData.forEach((dt) => (dt['key'] = dt.facilityId));

  return (
    <div className="unscheduled__container">
      {scheduleModalVisible && (
        <ScheduleFacilityModal
          open={scheduleModalVisible}
          close={closeScheduleModal}
          singleFacility={selectedRowKeys}
          reloadFacility={reloadFacility}
          reloadMetrics={props.reload}
          quarterMetrics={props.metrics}
        />
      )}
      <div style={{ marginBottom: '1rem' }}>
        {selectedRowKeys.length > 0 ? (
          <Button className="lock__btn" size="small" onClick={openScheduleModal} disabled={locking}>
            <LockFilled style={{ marginRight: '5px' }} />
            Lock Facilities
          </Button>
        ) : null}
      </div>
      {loading ? (
        <LoadingContent pageLoading={true} />
      ) : (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          className="quarter__table"
          paddedTable={true}
          dataSource={
            props.stateFilter.key !== undefined && props.stateFilter.value !== 'all'
              ? stateFacilities
              : keyedData
          }
          pagination={{
            showSizeChanger: true,
          }}
        />
      )}
    </div>
  );
};

export default UnscheduledQuarter;
