import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

import { getPrivileges, createRole } from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';
import './EditRole.css';
import EditRoleForm from './EditRoleForm';
import ModalHeader from './ModalHeader';

const EditRole = ({ open, close, roleName, desc, privileges, roleId, category }) => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshPrivileges();
  }, []);

  const refreshPrivileges = () => {
    setLoading(true);
    getPrivileges()
      .then((res) => {
        setPermissions(res.result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Error getting permissions',
          message: error.message,
        });
      });
  };

  return (
    <Modal
      visible={open}
      className="edit__role__modal"
      onCancel={close}
      title={<ModalHeader closeModal={close} />}
      footer={false}
    >
      <div className="edit__role__content">
        <EditRoleForm
          permissions={permissions}
          closeEditModal={close}
          name={roleName}
          desc={desc}
          privileges={privileges}
          id={roleId}
          userCategory={category}
        />
      </div>
    </Modal>
  );
};

export default EditRole;
