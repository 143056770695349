import React from 'react';
import styled from 'styled-components';
import { black } from 'design-system/colors';

const Header = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(Header)`
  display: flex;
  justify-content: ${(props) => (props.split ? 'space-between' : 'flex-end')};
  width: 100%;
  margin-bottom: 14px;
  padding: 24px 30px;
  font-family: var(--font-family);
  * {
    display: flex;
    align-items: center;
  }

  h1 {
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: normal;
    color: ${black};
  }

  button {
    margin-left: 20px;
    font-size: 14px;
    font-family: var(--font-family);
    margin-bottom: 0px !important;
    box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  }

  button + button {
    margin-left: 9px !important;
  }
`;
