import React from 'react';
import { Modal } from 'antd';
import Form from './AddSectorForm';
import './style.css';

const AddSector = ({ open, close, reloadSectors }) => {
  return (
    <Modal visible={open} onCancel={close} title="Create New Sector" footer={false} className="create__sector__modal">
      <Form reloadSector={reloadSectors} closeModal={close} />
    </Modal>
  );
};

export default AddSector;
