import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, message, Form } from 'antd';
import Input from 'components/common/AntInput';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import LocationInput from 'components/common/LocationInput';
import { updateState } from 'helpers/api';
import { properCase, checkLatitude, checkLongitude } from 'helpers/utils';

const { Option } = Select;

const EditStateForm = ({
  state,
  capital,
  coordinates,
  zone,
  zoneId,
  zoneList,
  closeModal,
  stateId,
  reload,
}) => {
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(coordinates.latitude);
  const [longitude, setLongitude] = useState(coordinates.longitude);

  const formRef = useRef();
  useEffect(() => {
    formRef.current.setFieldsValue({ stateName: state });
    formRef.current.setFieldsValue({ stateCapital: capital });
  });

  const handleStateEdit = (values) => {
    setLoading(true);
    if (
      properCase(values.stateName) === properCase(state) &&
      properCase(values.stateCapital) === properCase(capital) &&
      properCase(values.latitude) === properCase(coordinates.latitude) &&
      properCase(values.stateCapital) === properCase(coordinates.longitude) &&
      values.zoneId === zoneId
    ) {
      message.error('No changes were made to state');
    } else {
      const payload = {
        stateName: properCase(values.stateName),
        stateId: stateId,
        stateCapital: properCase(values.stateCapital),
        coordinates: {
          latitude: values.latitude
            ? values.latitude.loc
              ? values.latitude.loc
              : values.latitude
            : 0,
          longitude: values.longitude
            ? values.longitude.loc
              ? values.longitude.loc
              : values.longitude
            : 0,
        },
        zoneId: zoneId,
      };
      console.log(payload, 'edit state payload');
      console.log(values, 'values');
      updateState(payload)
        .then((res) => {
          setLoading(false);
          message.success(`${properCase(values.stateName)} State has been updated successfully!`);
          reload();
          setLoading(false);
          closeModal();
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  const initValues = {
    stateName: state,
    stateCapital: capital,
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
    zoneId: zone,
  };

  return (
    <Form
      layout="vertical"
      className="edit__state-form"
      ref={formRef}
      initialValues={initValues}
      onFinish={handleStateEdit}
    >
      <Row style={{ marginBottom: '0px' }}>
        <Col span={24}>
          <Form.Item
            label="State Zone"
            name="zoneId"
            rules={[{ required: true, message: 'Please select a zone ' }]}
          >
            <Select placeholder="Select a zone" className="form-controlxx">
              {zoneList &&
                zoneList.length &&
                zoneList.map((zone) => {
                  return (
                    <Option key={zone.zoneId} value={zone.zoneId}>
                      {zone.zoneName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '0px' }}>
        <Col span={12}>
          <Form.Item
            name="stateName"
            label="State Name"
            rules={[{ required: true, message: 'Please enter state name' }]}
          >
            <Input placeholder="Enter state name here" className="form-controlx" />
          </Form.Item>
        </Col>
        <Col span={12} className="pl-20">
          <Form.Item
            label="State Capital"
            name="stateCapital"
            rules={[{ required: true, message: "Please enter state's capital" }]}
          >
            <Input placeholder="Enter state's capital here" className="form-controlx" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="stateChord" label="State Co-ordinates">
        <Row style={{ marginBottom: '0px' }}>
          <Col span={12}>
            <Form.Item name="latitude" rules={[{ validator: checkLatitude }]}>
              <LocationInput
                type="latitude"
                placeholder="Enter Latitude"
                location={latitude}
                value={{ latitude }}
                setLocation={setLatitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
          <span
            style={{
              display: 'inline-block',
              width: '0px',
              lineHeight: '32px',
              textAlign: 'center',
            }}
          />
          <Col span={12} style={{ textAlign: 'right' }}>
            <Form.Item
              name="longitude"
              rules={[{ validator: checkLongitude }]}
              style={{ display: 'inline-block', width: 'calc(100% - 20px)' }}
            >
              <LocationInput
                type="longitude"
                placeholder="Enter Longitude"
                location={longitude}
                value={{ longitude }}
                setLocation={setLongitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Row>
        <Col span={24}>
          <Form.Item className="mt-20">
            <Button
              htmlType="submit"
              type="primary"
              appearance="default"
              className="mr-10"
              loading={loading}
              disable={loading}
            >
              Update
            </Button>
            <Button type="secondary" onClick={closeModal} disable={loading}>
              Cancel
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditStateForm;
