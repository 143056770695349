import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { GlobalRoutes } from './routes';

export default () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.paystack.co/v1/inline.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <HashRouter>
      <GlobalRoutes />
    </HashRouter>
  );
};
