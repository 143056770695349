import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col, Form } from 'antd';
import { WithContext as ReactTags } from 'react-tag-input';
import { openNotification } from 'helpers/notification';
import Input from 'components/common/AntInput';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import LocationInput from './LocationInput';
import Map from 'components/Map/fnMap';
import {
  getProducts,
  getRawMaterials,
  getWastes,
  // getSectors,
  getStates,
  // getRegions,
  getLgas,
} from 'helpers/api';
import {
  properCase,
  normalizeObjectCase,
  findItemInListOfObjects,
  isLatitude,
  isLongitude,
  checkLatitude,
  checkLongitude,
} from 'helpers/utils';

const KeyCodes = {
  tab: 9,
  comma: 188,
  space: 32,
  enter: 13,
};

const delimiters = [KeyCodes.tab, KeyCodes.comma, KeyCodes.enter];

const initialRawMaterialLists = [];

const initialProductsLists = [];

const StepOne = ({ className, state: initialFormState = {}, updateMasterForm, mounted }) => {
  const { stepOneState } = initialFormState;
  const {
    facilityName = '',
    address = 'None',
    latitude: lat = '',
    longitude: long = '',
    products: prod = initialProductsLists,
    rawMaterials: rawMat = initialRawMaterialLists,
    facilityWastes: facWaste = [],
    sector,
    region,
    lga,
    state,
    facilityStatus,
    eiaCertStatus,
    sectorLists: sctList = [],
    regionLists: rgLists = [],
    statesLists: stLists = [],
    lgaLists: lgLists = [],
    sectorName,
    regionName,
    stateName,
    lgaName,
  } = stepOneState;

  const [mountedd, setMountedd] = useState(mounted);
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState(lat);
  const [longitude, setLongitude] = useState(long);
  const [products, setProducts] = useState(prod);
  const [facilityWastes, setFacilityWastes] = useState(facWaste);
  const [sectorLists] = useState(sctList);
  const [statesLists, setStatesLists] = useState(stLists);
  const [lgaLists, setLgaLists] = useState(lgLists);
  const [regionLists] = useState(rgLists);
  const [rawMaterials, setRawMaterials] = useState(rawMat);
  const [facilityWastesList, setFacilityWastesList] = useState([]);
  const [productLists, setProductLists] = useState(initialProductsLists);
  const [rawMaterialLists, setRawMaterialLists] = useState(initialRawMaterialLists);
  const [tagError, setTagError] = useState({
    products: '',
    rawMaterials: '',
    facilityWastes: '',
  });

  const [form] = Form.useForm();

  const formRef = useRef();

  const getProductsLists = () => {
    getProducts('/LookUp/Product')
      .then(({ result }) => {
        const pLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach((res) =>
            pLists.push({
              productId: res.productId,
              name: res.productName,
              id: res.productId,
              text: res.productName,
            })
          );
        setProductLists(pLists);
      })
      .catch(() => {
        return openNotification({
          type: 'error',
          title: 'Failed to get products',
          message: `We encountered an error while trying to get list of products`,
        });
      });
  };

  const getRawMaterialsList = () => {
    getRawMaterials('/LookUp/RawMaterial')
      .then(({ result }) => {
        const rLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach((res) =>
            rLists.push({
              rawMaterialId: res.rawMaterialId,
              name: res.rawMaterialName,
              id: res.rawMaterialId,
              text: res.rawMaterialName,
            })
          );
        setRawMaterialLists(rLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get raw materials',
          message: `We encountered an error while trying to get list of raw materials`,
        });
      });
  };

  const getWastesLists = () => {
    getWastes('/LookUp/Waste')
      .then(({ result }) => {
        const wLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach((res) =>
            wLists.push({
              wasteId: res.wasteId,
              name: res.wasteName,
              id: res.wasteId,
              text: res.wasteName,
            })
          );
        setFacilityWastesList(wLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get wastes',
          message: `We encountered an error while trying to get list of wastes`,
        });
      });
  };

  // const getSectorsList = () => {
  //   getSectors('/LookUp/Sector')
  //     .then(({ result }) => {
  //       const sLists = [];
  //       result.data &&
  //         result.data.length > 0 &&
  //         result.data.forEach((res) =>
  //           sLists.push({
  //             sectorId: res.sectorId,
  //             name: res.sectorName,
  //             id: res.sectorId,
  //             text: res.sectorName,
  //           })
  //         );
  //       setSectorLists(sLists);
  //     })
  //     .catch((err) => {
  //       return openNotification({
  //         type: 'error',
  //         title: 'Failed to get sectors',
  //         message: `We encountered an error while trying to get list of sectors`,
  //       });
  //     });
  // };

  // function getRegionsLists() {
  //   getRegions('/LookUp/Zone')
  //     .then(({ result }) => {
  //       const rLists = [];
  //       result.data &&
  //         result.data.length > 0 &&
  //         result.data.forEach((res) =>
  //           rLists.push({
  //             zoneId: res.zoneId,
  //             name: res.zoneName,
  //             zoneName: res.zoneName,
  //             id: res.zoneId,
  //             text: res.zoneName,
  //           })
  //         );
  //       setRegionLists(rLists);
  //     })
  //     .catch((err) => {
  //       return openNotification({
  //         type: 'error',
  //         title: 'Failed to get regions',
  //         message: `We encountered an error while trying to get list of regions`,
  //       });
  //     });
  // }

  const refreshStatesLists = (forThisZone, which, reset = true) => {
    let withWhat = 'zoneName';
    if (which) withWhat = which;
    getStates('/LookUp/State')
      .then(({ result }) => {
        const sLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach(
            (res) =>
              res[withWhat] === forThisZone &&
              sLists.push({
                stateId: res.stateId,
                stateCode: res.stateCode,
                name: res.stateName,
                region: res.zoneName,
                zoneName: res.zoneName,
                lga: res.lga,
                id: res.stateId,
                text: res.stateName,
              })
          );
        if (reset) {
          formRef.current.setFieldsValue({ state: '' });
          formRef.current.setFieldsValue({ lga: '' });
        }
        setStatesLists(sLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get states',
          message: `We encountered an error while trying to get list of states`,
        });
      });
  };

  const refreshLgasLists = (forThisState, which, reset = true) => {
    let withWhat = 'stateName';
    if (which) withWhat = which;

    getLgas('/LookUp/Lga')
      .then(({ result }) => {
        const lgLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach(
            (res) =>
              res[withWhat] === forThisState &&
              lgLists.push({
                lgaId: res.lgaId,
                lgaCode: res.lgaCode,
                name: res.lgaName,
                stateName: res.stateName,
                lga: res.lgaName,
                id: res.lgaId,
                text: res.lgaName,
              })
          );
        if (reset) {
          formRef.current.setFieldsValue({ lga: '' });
        }
        setLgaLists(lgLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get states',
          message: `We encountered an error while trying to get list of states`,
        });
      });
  };

  useEffect(() => {
    if (mountedd && stLists && stLists.length > 0 && lgLists && lgLists.length > 0) {
      setStatesLists(stLists);
      setLgaLists(lgLists);
    }
    return () => setMountedd(false);
  }, [lgLists]);

  useEffect(() => {
    if (mountedd) {
      getProductsLists();
      getRawMaterialsList();
      getWastesLists();
      // getSectorsList();
      // getRegionsLists();
      const fixAutocomplete = () => {
        document.querySelectorAll('.tagsClass input').forEach((e) => {
          e.setAttribute('autocomplete', 'stopDamnAutocomplete');
        });
      };
      fixAutocomplete();
    }
    return () => setMountedd(false);
  }, []);

  const handleDelete = (item, which) => {
    if (which === 'products') {
      const productUpdate = products.filter((_, index) => index !== item);
      setProducts(productUpdate);
      formRef.current.setFieldsValue({ products: productUpdate });
    }
    if (which === 'rawmaterials') {
      const rawMaterialsUpdate = rawMaterials.filter((_, index) => index !== item);
      setRawMaterials(rawMaterialsUpdate);
      formRef.current.setFieldsValue({ rawMaterials: rawMaterialsUpdate });
    }
    if (which === 'facilitywastes') {
      const facilityWastesUpdate = facilityWastes.filter((_, index) => index !== item);
      setFacilityWastes(facilityWastesUpdate);
      formRef.current.setFieldsValue({ facilityWastes: facilityWastesUpdate });
    }
  };

  const handleAddition = (name, which) => {
    try {
      name = normalizeObjectCase(name);
      const tagsClasses = {
        products: 'products',
        facilitywastes: 'facilityWastes',
        rawmaterials: 'rawMaterials',
      };
      const newErrorState = { ...tagError };
      newErrorState[tagsClasses[which]] = '';
      setTagError(newErrorState);

      if (which === 'products') {
        const productUpdate = [...products, name];
        setProducts(productUpdate);
        formRef.current.setFieldsValue({ products: productUpdate });
      }
      if (which === 'rawmaterials') {
        const rawMUpdate = [...rawMaterials, name];
        setRawMaterials(rawMUpdate);
        formRef.current.setFieldsValue({ rawMaterials: rawMUpdate });
      }
      if (which === 'facilitywastes') {
        const facWastes = [...facilityWastes, name];
        setFacilityWastes(facWastes);
        formRef.current.setFieldsValue({ facilityWastes: facWastes });
      }
    } catch (err) {}
  };

  const handleFilterSuggestion = (textInputValue, possibleSuggestionsArray, which) => {
    const lowerCaseQuery = textInputValue.toLowerCase();

    return possibleSuggestionsArray.filter((suggestion) =>
      suggestion.toLowerCase().includes(lowerCaseQuery)
    );
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 14 },
  };

  const { Option } = Select;

  const displayOptions = (list) => {
    let key = list;
    let { [key]: name } = { sectorLists, regionLists, statesLists, lgaLists };

    if (name.length > 0) {
      return name.map((item) => {
        return (
          <Option value={item.id} key={item.id}>
            {item.text}
          </Option>
        );
      });
    }
  };

  const onFinish = (values) => {
    const GOTONEXT = 1;

    let product1 =
      values.products && typeof values.products === 'string'
        ? { id: values.products, text: properCase(values.products) }
        : null;
    let rawM1 =
      values.rawMaterials && typeof values.rawMaterials === 'string'
        ? { id: values.rawMaterials, text: properCase(values.rawMaterials) }
        : null;
    let facW1 =
      values.facilityWastes && typeof values.facilityWastes === 'string'
        ? { id: values.facilityWastes, text: properCase(values.facilityWastes) }
        : null;

    const xProducts = product1 ? [...products, product1] : [...products];
    const xRawMaterials = rawM1 ? [...rawMaterials, rawM1] : [...rawMaterials];
    const xFacilityWastes = facW1 ? [...facilityWastes, facW1] : [...facilityWastes];

    try {
      const stateOnePayload = {
        facilityName: properCase(values.facilityName),
        address: values.address ? properCase(values.address) : '',
        latitude: values.latitude.loc ? values.latitude.loc : latitude,
        longitude: values.longitude.loc ? values.longitude.loc : longitude,
        products: xProducts,
        rawMaterials: xRawMaterials,
        facilityWastes: xFacilityWastes,
        sector: values.sector,
        regionLists,
        statesLists,
        sectorLists,
        lgaLists,
        lga: values.lga,
        state: values.state,
        region: values.region,
        sectorName: findItemInListOfObjects(sectorLists, values.sector, 'sectorId', 'string')[
          'name'
        ],
        regionName: findItemInListOfObjects(regionLists, values.region, 'zoneId', 'string')['name'],
        stateName: findItemInListOfObjects(statesLists, values.state, 'stateId', 'string')['name'],
        lgaName: findItemInListOfObjects(lgaLists, values.lga, 'lgaId', 'string')['name'],
        facilityStatus: values.facilityStatus,
        eiaCertStatus: values.eiaCertStatus,
      };
      updateMasterForm(stateOnePayload, 'stepOneState', GOTONEXT);
    } catch (e) {
      // console.log('Something went wrong', e);
    }
  };

  const onFinishFailed = (errorInfo) => {
    const { values } = errorInfo;
    const tagsClasses = ['products', 'facilityWastes', 'rawMaterials'];
    const newErrorState = { ...tagError };
    tagsClasses.forEach((tgClas) => {
      if (values[tgClas] && values[tgClas].length === 0) {
        newErrorState[tgClas] = 'tag-field-error';
      } else {
        const newErrorState = { ...tagError };
        newErrorState[tgClas] = '';
      }
    });
    setTagError(newErrorState);
  };

  const getFacilityName = () => {
    let facName = formRef.current.getFieldValue('facilityName');
    return facName;
  };

  const getCoordinateForMap = (whichCoordinate) => {
    let theCoordinate = formRef.current.getFieldValue(whichCoordinate);
    let key = whichCoordinate;
    let { [key]: stateCoordinate } = { latitude, longitude };

    return theCoordinate && theCoordinate.loc ? theCoordinate.loc : stateCoordinate;
  };

  const getActualItem = (
    list,
    item,
    property,
    type,
    alternative,
    alternativeProperty,
    alternativeType
  ) => {
    let itemF = '';
    // debugger;
    let foundItem = findItemInListOfObjects(list, item, property, type);
    if (!foundItem) {
      foundItem = findItemInListOfObjects(list, alternative, alternativeProperty, alternativeType);
    }
    if (foundItem && foundItem[property]) itemF = foundItem[property];
    return itemF;
  };

  const initValues = {
    facilityName,
    address,
    latitude,
    longitude,
    products,
    rawMaterials,
    facilityWastes,
    sector: getActualItem(sectorLists, sector, 'sectorId', 'string', sectorName, 'name', 'string'),
    region: getActualItem(regionLists, region, 'zoneId', 'string', regionName, 'name', 'string'),
    state: getActualItem(statesLists, state, 'stateId', 'string', stateName, 'name', 'string'),
    lga: getActualItem(lgaLists, lga, 'lgaId', 'string', lgaName, 'name', 'string'),
    facilityStatus,
    eiaCertStatus,
  };

  return mountedd ? (
    <Form
      className={`${className} step-form`}
      {...formItemLayout}
      layout="horizontal"
      form={form}
      initialValues={initValues}
      onFinish={onFinish}
      ref={formRef}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={10}>
          <Form.Item
            label="Facility Name"
            name="facilityName"
            rules={[{ required: true, message: 'Facility name is required!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Input
              placeholder="Enter facility name"
              className="form-controlx"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={10} className="pl-3">
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Address is required!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Input
              placeholder="Enter facility address"
              className="form-controlx"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Location" style={{ marginBottom: 0, display: 'block' }}>
        <Row>
          <Col span={10}>
            <Form.Item
              name="latitude"
              rules={[{ validator: checkLatitude }]}
              // style={{ display: 'inline-block', width: 'calc(50%)' }}
            >
              <LocationInput
                type="latitude"
                placeholder="Enter Latitude"
                location={latitude}
                setLocation={setLatitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
          <span
            style={{
              display: 'inline-block',
              width: '20px',
              lineHeight: '32px',
              textAlign: 'center',
            }}
          />
          <Col span={10}>
            <Form.Item
              name="longitude"
              rules={[{ validator: checkLongitude }]}
              style={{ display: 'inline-block', width: 'calc(100% - 20px)' }}
            >
              <LocationInput
                type="longitude"
                placeholder="Enter Longitude"
                location={longitude}
                setLocation={setLongitude}
                className="form-controlx"
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            {latitude && longitude && isLatitude(latitude) && isLongitude(longitude) && (
              <p
                style={{
                  marginTop: '0.1rem',
                  transform: 'translateY(-0.5rem)',
                  position: 'relative',
                  color: '#0c5bab',
                  cursor: 'pointer',
                }}
                onClick={() => setShowMap(!showMap)}
              >
                {showMap ? 'Hide map' : 'Show on map'}
              </p>
            )}
          </Col>
        </Row>
      </Form.Item>
      <Row>
        <Col span={20}>
          {showMap && (
            <Map
              latitude={getCoordinateForMap('latitude')}
              longitude={getCoordinateForMap('longitude')}
              title={getFacilityName()}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col span={20}>
          <Form.Item
            name="sector"
            label="Sector"
            rules={[{ required: true, message: 'Sector is required!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Select placeholder="Select Sector" className="form-controlxx">
              {displayOptions('sectorLists')}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Products"
        name="products"
        className="tagged-input"
        rules={[{ required: true, message: 'Please provide product lists of the facility!' }]}
        style={{ marginBottom: 0, display: 'block' }}
      >
        <Row>
          <Col span={20}>
            <ReactTags
              tags={products}
              allowDeleteFromEmptyInput={false}
              maxLength={25}
              placeholder="Enter main products"
              autofocus={false}
              inputFieldPosition="top"
              // autocomplete={false}
              // allowUnique={true}
              name="products"
              suggestions={productLists}
              handleDelete={(name) => handleDelete(name, 'products')}
              handleAddition={(name) => handleAddition(name, 'products')}
              handleFilterSuggestion={(e, v) => handleFilterSuggestion(e, v, 'products')}
              delimiters={delimiters}
              minQueryLength={0}
              classNames={{
                tags: 'tagsClass',
                // tagInput: 'form-controlx',
                tagInputField: `form-controlx no-margin-bottom ${tagError['products']}`,
                selected: 'selectedClass',
                tag: 'tagClass',
                remove: 'removeClass',
                suggestions: 'suggestionsClass',
                activeSuggestion: 'activeSuggestionClass',
              }}
            />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label="Raw Materials"
        name="rawMaterials"
        className="tagged-input"
        rules={[{ required: true, message: 'Raw materials is required!' }]}
        style={{ marginBottom: 0, display: 'block' }}
      >
        <Row>
          <Col span={20}>
            <ReactTags
              tags={rawMaterials}
              allowDeleteFromEmptyInput={false}
              maxLength={25}
              placeholder="Enter basic raw materials"
              autofocus={false}
              inputFieldPosition="top"
              // autocomplete={false}
              // allowUnique={true}
              name="rawmaterials"
              suggestions={rawMaterialLists}
              handleDelete={(name) => handleDelete(name, 'rawmaterials')}
              handleAddition={(name) => handleAddition(name, 'rawmaterials')}
              handleFilterSuggestion={(e, v) => handleFilterSuggestion(e, v, 'rawmaterials')}
              delimiters={delimiters}
              minQueryLength={0}
              classNames={{
                tags: 'tagsClass',
                // tagInput: 'form-controlx',
                tagInputField: `form-controlx no-margin-bottom ${tagError['rawMaterials']}`,
                selected: 'selectedClass',
                tag: 'tagClass',
                remove: 'removeClass',
                suggestions: 'suggestionsClass',
                activeSuggestion: 'activeSuggestionClass',
              }}
            />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label="Facility Wastes"
        name="facilityWastes"
        className="tagged-input"
        rules={[{ required: true, message: 'Enter basic facility' }]}
        style={{ marginBottom: 0, display: 'block' }}
      >
        <Row>
          <Col span={20}>
            <ReactTags
              tags={facilityWastes}
              allowDeleteFromEmptyInput={false}
              maxLength={25}
              placeholder="Enter basic facility waste"
              autofocus={false}
              inputFieldPosition="top"
              // autocomplete={false}
              // allowUnique={true}
              name="facilitywastes"
              suggestions={facilityWastesList}
              handleDelete={(name) => handleDelete(name, 'facilitywastes')}
              handleAddition={(name) => handleAddition(name, 'facilitywastes')}
              handleFilterSuggestion={(e, v) => handleFilterSuggestion(e, v, 'facilitywastes')}
              delimiters={delimiters}
              minQueryLength={0}
              classNames={{
                tags: 'tagsClass',
                // tagInput: 'form-controlx',
                tagInputField: `form-controlx no-margin-bottom ${tagError['facilityWastes']}`,
                selected: 'selectedClass',
                tag: 'tagClass',
                remove: 'removeClass',
                suggestions: 'suggestionsClass',
                activeSuggestion: 'activeSuggestionClass',
              }}
            />
          </Col>
        </Row>
      </Form.Item>

      <Row>
        <Col span={10}>
          <Form.Item
            name="region"
            label="Region"
            rules={[{ required: true, message: 'Region is required!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Select
              placeholder="Select Region"
              className="form-controlxx"
              onChange={(e, record) => refreshStatesLists(record.children)}
            >
              {displayOptions('regionLists')}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10} className="pl-3">
          <Form.Item
            name="state"
            label="State"
            // hasFeedback
            rules={[{ required: true, message: 'Select a State!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Select
              placeholder="Select State"
              className="form-controlxx"
              onChange={(e, record) => refreshLgasLists(record.children)}
              loading={!statesLists}
            >
              {displayOptions('statesLists')}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={10}>
          <Form.Item
            name="lga"
            label="LGA"
            // hasFeedback
            rules={[{ required: true, message: 'Select a LGA!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Select placeholder="Select LGA" className="form-controlxx" loading={!lgaLists}>
              {displayOptions('lgaLists')}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={10}>
          <Form.Item
            name="facilityStatus"
            label="Facility Status"
            rules={[{ required: true, message: 'Select facility status!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Select placeholder="Select facility status" className="form-controlxx">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={10} className="pl-3">
          <Form.Item
            name="eiaCertStatus"
            label="Facility EIA Status"
            rules={[{ required: true, message: 'Select facility EIA status!' }]}
            style={{ marginBottom: 0, display: 'block' }}
          >
            <Select placeholder="Select facility EIA status" className="form-controlxx">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        {...tailLayout}
        style={{ marginTop: '1rem', marginBottom: '2em', display: 'block' }}
      >
        <Button type="primary" appearance="default" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  ) : null;
};

export default styled(StepOne)`
  min-width: 735px !important;
  max-width: 90% !important;
  max-height: max-content;
  overflow-y: hidden;
  border-radius: 5px;

  .ant-modal-header {
    padding: 27px 40px 29px !important;
  }

  .ant-modal-body {
    padding: 27px 40px !important;
    max-height: 700px;
    overflow-y: auto;
  }

  .ant-modal-body,
  .ant-collapse {
    background-color: #fff;
  }
  label {
    font-family: var(--font-family);
    font-size: var(--form-label-fs);
    font-weight: var(--form-label-fw);
    margin-bottom: var(--form-label-mb);
    letter-spacing: normal;
    color: #2e384d;
  }
  .pl-3 {
    padding-left: 19px;
  }
  .ant-select-selection {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ant-select-selection--single {
    height: 41px;
  }
  .ant-select-selection__rendered {
    margin-top: 3px;
  }

  .form-control {
    width: 100%;
    height: 41px;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(245, 247, 255, 0.2);
    &:focus {
      outline: 0;
      border: 0.5px solid #007ace;
    }
  }
`;
