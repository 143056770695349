import React, { useState, createContext } from 'react';

export const LookupContext = createContext();

const LookupContextProvider = (props) => {
  const [selectedState, setselectedState] = useState('');
  const [selectedLg, setselectedLg] = useState('');
  const [selectedZone, setselectedZone] = useState('');
  const [selectedSector, setselectedSector] = useState('');

  const updateState = (value) => {
    setselectedState(value);
  };
  const updateLg = (value) => {
    setselectedLg(value);
  };
  const updateZone = (value) => {
    setselectedZone(value);
  };
  const updateSector = (value) => {
    setselectedSector(value);
  };

  return (
    <LookupContext.Provider
      value={{
        selectedState,
        selectedLg,
        selectedSector,
        selectedZone,
        updateZone,
        updateSector,
        updateState,
        updateLg,
      }}
    >
      {props.children}
    </LookupContext.Provider>
  );
};

export default LookupContextProvider;
