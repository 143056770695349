import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Checkbox } from 'antd';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import LoadingContent from 'components/common/LoadingContent';
import Select from 'components/common/Select';
import { updateRole } from 'helpers/api';
import { openNotification } from 'helpers/notification';
import ConfirmationModal from '../ConfimationModal/ConfirmationModal';
import './EditRole.css';

const { Option } = Select;

const EditRoleForm = ({
  permissions,
  closeEditModal,
  name,
  desc,
  privileges,
  id,
  userCategory,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [loadingPrivileges, setLoadingPrivileges] = useState(true);
  const [selectedPrivileges, setselectedPrivileges] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);

  let pList = [];
  const formRef = useRef();

  useEffect(() => {
    defaultValues();
    compilePrivileges();
    setselectedPrivileges(pList);
    console.log(userCategory);
  }, []);

  useEffect(() => {
    const tempArr = [];
    for (let i = 0; i < selectedPrivileges.length; i++) {
      tempArr.push(selectedPrivileges[i]);
    }
    setPermissionsList(tempArr);
  }, [selectedPrivileges]);

  // CLOSE CONFIRMATION MODAL
  const closeModal = () => {
    setVisible(false);
    window.location.reload();
  };

  const compilePrivileges = () => {
    if (privileges.length > 0) {
      return privileges.map((privilege) => {
        pList.push(privilege.privilegeId);
      });
    }
  };

  // HANDLE SELECTED PERMISSIONA
  const onChange = (e) => {
    let newTempArr = permissionsList;
    if (e.target.checked) {
      newTempArr.push(e.target.id);
    } else {
      const filteredList = newTempArr.filter((permission) => permission !== e.target.id);
      newTempArr = filteredList;
    }
    setPermissionsList(newTempArr);
  };

  // DISPLAY LIST OF PERMISSIONS
  const displayPermissions = () => {
    if (permissions.length > 0) {
      return permissions.map((Permission) => {
        return (
          <div className="permission-box" key={Permission.permission}>
            <h3 className="permission-name">{Permission.permission.toLowerCase()}</h3>

            {Permission.privilege.map((privilege) => {
              if (selectedPrivileges.includes(privilege.privilegeId)) {
                return (
                  <Checkbox
                    key={privilege.privilegeId}
                    id={privilege.privilegeId}
                    onChange={onChange}
                    defaultChecked={true}
                  >
                    {privilege.privilegeName.toLowerCase()}
                  </Checkbox>
                );
              } else {
                return (
                  <Checkbox
                    key={privilege.privilegeId}
                    id={privilege.privilegeId}
                    onChange={onChange}
                  >
                    {privilege.privilegeName.toLowerCase()}
                  </Checkbox>
                );
              }
            })}
          </div>
        );
      });
    } else {
      return (
        <div>
          <LoadingContent modal={true} />
          <p className="loading-permissions" style={{ fontSize: '10px' }}>
            Loading Permissions <span>.</span>
            <span>.</span>
            <span>.</span>
          </p>
        </div>
      );
    }
  };

  // SET INITIAL VALUES OF ROLE NAME AND DESCRIPTION
  const defaultValues = () => {
    formRef.current.setFieldsValue({ roleName: name });
    formRef.current.setFieldsValue({ roleDescription: desc });
    formRef.current.setFieldsValue({ userCategory: userCategory });
  };

  const initialVals = {
    roleName: name,
    roleDescription: desc,
    userCategory: userCategory,
  };

  const handleEditRole = (values) => {
    setLoading(true);
    setFailedMessage('');
    if (permissionsList.length === 0) {
      setErrorMessage('please select at least one permission');
    } else {
      const payload = {
        roleId: id,
        roleName: values.roleName.toLowerCase(),
        roleDescription: values.roleDescription.toLowerCase(),
        privilegeId: permissionsList,
      };
      // console.log(payload, 'edit payload');
      updateRole(payload)
        .then((res) => {
          if (res === null) {
            setFailedMessage('No changes were made to this role');
            setLoading(false);
          } else if (res.result.status.code === '00') {
            setLoading(false);
            setConfirmMessage('Role updated successfully!');
            setVisible(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, 'in error');
          setFailedMessage(error);
          return openNotification({
            type: 'error',
            title: 'Error updating role',
            message: `${error}`,
          });
        });
    }
  };

  return (
    <Form
      layout="vertical"
      className="edit__role-form"
      ref={formRef}
      onFinish={handleEditRole}
      initialValues={initialVals}
    >
      <p className="error-text">{failedMessage}</p>
      <ConfirmationModal open={visible} close={closeModal} message={confirmMessage} />
      <Form.Item
        name="roleName"
        label="Role Name"
        rules={[{ required: true, message: 'please enter role name ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="roleDescription"
        label="Role Description"
        rules={[{ message: 'please enter a brief description for the role ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="userCategory" label="User Category">
        <Select defaultValue={userCategory} disabled>
          <Option>{userCategory}</Option>
        </Select>
      </Form.Item>
      <div className="permissions-box">
        <div className="permissions-container">{displayPermissions()}</div>
      </div>

      <Form.Item>
        <div className="form-group buttons">
          <Row>
            <Col lg={8}>
              <Button
                appearance="default"
                style={{ marginRight: '10px' }}
                type="primary"
                loading={loading}
                disabled={loading}
              >
                Save
              </Button>
              <Button type="secondary" onClick={closeEditModal} disabled={loading}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditRoleForm;
