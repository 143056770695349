import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import ForgotPasswordForm from '../../components/screens/ForgotPassword/Form';
import NavBrandDark from '../../components/common/NavbarBrandDark';
import BackgroundImage from '../../assets/img/signin_sm.jpg';

const StyledForgotPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
  .right-column,
  .left-column {
    width: 50%;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
  .left-column {
    .banner-bg {
      background-image: url(${BackgroundImage});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      @media screen and (max-width: 768px) {
        background-position: center;
        button {
          display: none;
        }
        header {
          background: #transparent;
          padding: 1rem 0;
        }
      }
      @media screen and (max-width: 575px) {
        button {
          display: none;
        }
        background-image: none;
        header {
          background: #0052cc;
          padding: 1rem 0;
        }
      }
    }
  }
  .right-column {
    display: flex;
    justify-content: center;
    align-self: center;
    @media screen and (max-width: 768px) {
      padding: 4rem 1rem;
    }
    @media screen and (max-width: 575px) {
      padding: 4rem 1rem;
    }
  }
  .container {
    width: 400px;
  }
  header {
    display: flex;
    align-items: center;
  }

  button {
    border-radius: 4px;
    width: 100%;
  }
`;

const ForgotPassword = ({ className }) => {
  return (
    <StyledForgotPage>
      <div className="left-column">
        <div className="banner-bg">
          <header>
            <NavBrandDark />
          </header>
        </div>
      </div>
      <div className="right-column">
        <div className="container">
          <ForgotPasswordForm />
        </div>
      </div>
      <main />
    </StyledForgotPage>
  );
};
export default ForgotPassword;
